<template>
  <div>
    <div class="PFFeedNav">
      <div @click="goAll">전체</div>
      <div class="PFFeedNav2">팔로잉</div>
      <select v-model="fieldValue" @change="fieldSelect()">
        <option value="all">전체</option>
        <option value="music">음악</option>
        <option value="video">영상</option>
        <option value="photography">사진</option>
        <option value="design">디자인</option>
        <option value="specialist">스페셜리스트</option>
      </select>
    </div>
    <div
      class="feedcontents"
      v-for="(item, index) in preview_data"
      :key="index"
    >
      <img
        :src="item.PVData.thumbnail"
        class="feedcontents-img"
        @click="goPost(item.PVData.feed_id)"
        style="margin-bottom: 20px"
      />
      <div class="feed_title">{{ item.PVData.feed_title }}</div>
      <div class="writerInfo">
        <div
          class="writer"
          @click="goOtherPF(item.PVData.userID)"
          style="cursor: pointer"
        >
          <img :src="item.PVData.profile_img" class="writerImg" />
          <span>{{ item.PVData.nickName }}</span>
        </div>
        <div>
          <span class="hashTag" v-if="item.PVData.main_field"
            ># {{ item.PVData.main_field }}</span
          >
          <span class="hashTag" v-if="item.PVData.main_role"
            ># {{ item.PVData.main_role }}</span
          >
        </div>
      </div>
    </div>
    <div class="plusBtn" v-if="this.preview_data.length !== 0">
      <span>더보기</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "userfeed",
  computed: {
    preview_data() {
      return this.$store.state.PFDataSet.preview_data_follow;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
    feed_scroll() {
      return this.$store.state.follow_scroll;
    },
  },
  mounted() {
    window.scrollTo(0, this.feed_scroll);

    let token = this.$cookie.get("access_token");
    let userID = this.$cookie.get("userID");
    let sessionToken = JSON.parse(sessionStorage.getItem("token"));

    if (sessionToken !== null || token !== null) {
      this.isLogin = true;
    } else if (sessionToken == null || token == null) {
      this.isLogin = false;
    }
    if (this.preview_data.length !== 0) {
      return;
    } else {
      if (this.isLogin == true) {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          this.$axios
            .post(
              this.API_URL + "/portfolios/following-print-feed",
              {
                userID: sessionToken.userID,
                portfolios_field: this.fieldValue,

                currentFeedID: [],
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionToken.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "currentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );

                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data_follow",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          this.$axios
            .post(
              this.API_URL + "/portfolios/following-print-feed",
              {
                userID: userID,
                portfolios_field: this.fieldValue,

                currentFeedID: [],
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "currentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );

                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data_follow",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  },
  data() {
    return {
      isLogin: false,
      fieldValue: "all",
    };
  },
  methods: {
    fieldSelect() {
      this.$store.commit("del_preview_data_follow");
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));

        this.$axios
          .post(
            this.API_URL + "/portfolios/following-print-feed",
            {
              userID: token.userID,
              portfolios_field: this.fieldValue,
              currentFeedID: [],
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              let currentFeedID = res.data.currentFeedID;
              sessionStorage.setItem(
                "currentFeedID",
                JSON.stringify({
                  currentFeedID,
                })
              );

              for (let i in res.data.preview_data) {
                this.$store.commit(
                  "set_preview_data_follow",
                  res.data.preview_data[i]
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/portfolios/following-print-feed",
            {
              userID: userID,
              portfolios_field: this.fieldValue,

              currentFeedID: [],
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              let currentFeedID = res.data.currentFeedID;
              sessionStorage.setItem(
                "currentFeedID",
                JSON.stringify({
                  currentFeedID,
                })
              );

              for (let i in res.data.preview_data) {
                this.$store.commit(
                  "set_preview_data_follow",
                  res.data.preview_data[i]
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goOtherPF(Id) {
      if (this.isLogin == false) {
        alert("로그인이 필요합니다.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_follow_scroll", window.scrollY);
        this.$router.push({ path: "/otherpfview/" + Id + "/otherWorks" });
      }
    },
    goPost(feed_id) {
      if (this.isLogin == false) {
        alert("로그인이 필요합니다.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_follow_scroll", window.scrollY);
        this.$router.push({ path: "/postview/" + feed_id });
      }
    },
    goAll() {
      this.$store.commit("set_follow_scroll", window.scrollY);
      this.$router.push({ name: "userfeedall" });
    },
    plusFeedBtn() {
      if (this.isLogin == false) {
        alert("로그인이 필요합니다.");
      } else if (this.isLogin == true) {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let sessionToken = JSON.parse(sessionStorage.getItem("token"));
          let currentFeedID = JSON.parse(
            sessionStorage.getItem("currentFeedID")
          );
          this.$axios
            .post(
              this.API_URL + "/portfolios/following-print-feed",
              {
                userID: sessionToken.userID,
                currentFeedID: currentFeedID.currentFeedID,
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionToken.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "currentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );

                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data_follow",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          let currentFeedID = JSON.parse(
            sessionStorage.getItem("currentFeedID")
          );
          this.$axios
            .post(
              this.API_URL + "/portfolios/following-print-feed",
              {
                userID: userID,
                currentFeedID: currentFeedID.currentFeedID,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "currentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );

                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data_follow",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.PFFeedNav {
  width: 690px;
  height: 68px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}
.PFFeedNav div:first-child {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;

  margin-left: 32px;
  cursor: pointer;
}
.PFFeedNav2 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  margin-left: 20px;
  color: #8ca0ef;
  border-bottom: 2px solid #8ca0ef;
}
.feedcontents {
  background: #ffffff;
  margin-bottom: 40px;
}
.feedcontents-img {
  width: 630px;
  cursor: pointer;
  padding: 30px;
  padding-bottom: 0;
}
.feed_title {
  width: 532px;
  margin-left: 30px;
  font: normal normal bold 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.writerInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}
.writer {
  display: flex;
  align-items: center;
}
.writer span {
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
}
.writerImg {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 6px;
}
.hashTag {
  margin-right: 20px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.plusBtn {
  width: 690px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  padding-top: 13px;
  cursor: pointer;
}
.plusBtn span {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
}
select {
  width: 140px;
  height: 36px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
  margin-left: 386px;
}
</style>
