<template>
  <div class="userDelete2">
    <h2>회원 탈퇴</h2>
    <p>탈퇴하기 전에 다음 안내 사항을 확인해주세요.</p>
    <div class="guide">
      <div class="guideBox">
        <div>회원님의 서비스 이용기록이 <span>모두 삭제</span>됩니다.</div>
        <div>탈퇴가 완료되면 데이터를 복구할 수 없습니다.</div>
      </div>
      <div class="deleteCheck">
        <p>정말 탈퇴하시겠습니까?</p>
        <div class="deleteCheckBtn" @click="deleteCheck = !deleteCheck">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            v-if="deleteCheck == false"
          >
            <g
              id="그룹_19660"
              data-name="그룹 19660"
              transform="translate(-778.5 -685)"
            >
              <rect
                id="사각형_24945"
                data-name="사각형 24945"
                width="30"
                height="30"
                transform="translate(778.5 685)"
                fill="none"
              />
              <path
                id="패스_8060"
                data-name="패스 8060"
                d="M799.5,691a2.946,2.946,0,0,1,3,3v12a2.946,2.946,0,0,1-3,3h-12a2.946,2.946,0,0,1-3-3V694a2.946,2.946,0,0,1,3-3h12m0-1h-12a4.012,4.012,0,0,0-4,4v12a4.012,4.012,0,0,0,4,4h12a4.012,4.012,0,0,0,4-4V694a4.012,4.012,0,0,0-4-4Z"
                fill="#707070"
                opacity="0.5"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            v-if="deleteCheck == true"
          >
            <g
              id="그룹_19658"
              data-name="그룹 19658"
              transform="translate(-742 -685)"
            >
              <rect
                id="사각형_24943"
                data-name="사각형 24943"
                width="30"
                height="30"
                transform="translate(742 685)"
                fill="none"
              />
              <path
                id="패스_8058"
                data-name="패스 8058"
                d="M763,691a2.946,2.946,0,0,1,3,3v12a2.946,2.946,0,0,1-3,3H751a2.946,2.946,0,0,1-3-3V694a2.946,2.946,0,0,1,3-3h12m0-1H751a4.012,4.012,0,0,0-4,4v12a4.012,4.012,0,0,0,4,4h12a4.012,4.012,0,0,0,4-4V694a4.012,4.012,0,0,0-4-4Z"
                fill="#707070"
              />
              <path
                id="_패스_7618-2-3"
                d="M750,699.9l4.7,4.1,9.3-8"
                fill="none"
                stroke="#707070"
                stroke-width="2"
              />
            </g>
          </svg>
          <span>위 사실들을 모두 확인했으며, 이에 동의합니다.</span>
        </div>
      </div>
      <div class="doDelete" @click="doDelete">회원 탈퇴</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userDelete2",
  data() {
    return {
      deleteCheck: false,
    };
  },
  methods: {
    doDelete() {
      if (this.deleteCheck == false) {
        alert("위 내용을 동의 후 탈퇴하실수 있습니다.");
      } else if (this.deleteCheck == true) {
        this.$router.push({ name: "userdelete2" });
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.userDelete2 {
  width: 500px;
  height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 96px 68px;
  text-align: center;
  padding: 0 58px;
}
.userDelete2 h2 {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
  margin: 40px 0;
}
.userDelete2 p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  margin-bottom: 30px;
}

.guideBox {
  width: 384px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.guideBox div {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
}
.guideBox div > span {
  color: #cf1f1f;
}

.deleteCheck p {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 32px;
}
.deleteCheck p > span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #cf1f1f;
}
.deleteCheckBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.deleteCheckBtn span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-left: 10px;
}
.doDelete {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin: 40px auto;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding-top: 8px;
  cursor: pointer;
}
</style>
