<template>
  <div id="userinfoSetup">
    <profile-img-change v-if="doChangeImg"></profile-img-change>
    <div class="popup" v-if="popup == true">
      <div class="white-bg">
        <h3>프로필 이미지 변경</h3>
        <div class="popupBtns">
          <input
            type="file"
            id="changeImg"
            @change="filePush()"
            ref="inputImg"
            accept="image/*"
          />
          <div class="popupBtn1">
            <label for="changeImg">이미지 업로드</label>
          </div>
          <div class="popupBtn2" @click="basicProfileImg">기본 이미지 사용</div>
          <div class="popupBtn3" @click="closePop">취소</div>
        </div>
      </div>
    </div>
    <h2>프로필 수정</h2>
    <div class="header">
      <div class="headerImg">
        <img :src="this.userInfo.profileImg" alt="" />
        <div @click="changeProfile">이미지 변경</div>
      </div>
      <div class="headerInfo">
        <div class="headerInfo-head">
          <h3>{{ userInfo.nickName }}</h3>
          <div class="headerInfo-headinput">
            <input type="text" maxlength="10" v-model="userInfo.main_field" />
            <input type="text" maxlength="10" v-model="userInfo.main_role" />
          </div>
        </div>
        <span class="headerInfo_ment"
          >인사말
          <span class="headerInfo_ment2"
            >({{ userInfo.hello.length }}/30)</span
          ></span
        >
        <input
          type="text"
          v-model="userInfo.hello"
          maxlength="30"
          class="mentInput"
          placeholder="프로필에 보여질 30자 이내의 인사말을 적어주세요."
        />
      </div>
    </div>
    <div class="field">
      <div class="fieldInput">
        <div class="fieldInput-header">
          <h4>활동 분야</h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="setField"
          >
            <g
              id="그룹_20410"
              data-name="그룹 20410"
              transform="translate(-295 -685)"
            >
              <rect
                id="사각형_24940"
                data-name="사각형 24940"
                width="30"
                height="30"
                transform="translate(295 685)"
                fill="none"
              />
              <path
                id="패스_8055"
                data-name="패스 8055"
                d="M318.9,698.9h-7.8v-7.8a1.1,1.1,0,0,0-2.2,0v7.8h-7.8a1.1,1.1,0,1,0,0,2.2h7.8v7.8a1.1,1.1,0,0,0,2.2,0v-7.8h7.8a1.1,1.1,0,1,0,0-2.2Z"
                fill="#707070"
              />
            </g>
          </svg>
        </div>

        <!-- <div class="fieldInputBox"> -->
        <div>
          <div class="myfield">
            <div v-if="userInfo.field.music.length !== 0">
              <div class="myfield_name">음악</div>
              <div class="myfield_wrapper">
                <div
                  v-for="(item, index) in userInfo.field.music"
                  :key="index"
                  class="myfield_item"
                >
                  {{ item }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="delMusic(index)"
                  >
                    <g
                      id="그룹_20488"
                      data-name="그룹 20488"
                      transform="translate(-166 -529)"
                    >
                      <rect
                        id="사각형_24934"
                        data-name="사각형 24934"
                        width="20"
                        height="20"
                        transform="translate(166 529)"
                        fill="none"
                      />
                      <g
                        id="그룹_19689"
                        data-name="그룹 19689"
                        transform="translate(-2 -2)"
                      >
                        <line
                          id="_선_81"
                          x1="10"
                          y2="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <line
                          id="_선_82"
                          x1="10"
                          y1="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="userInfo.field.video.length !== 0">
              <div class="myfield_name">영상</div>
              <div class="myfield_wrapper">
                <div
                  v-for="(item, index) in userInfo.field.video"
                  :key="index"
                  class="myfield_item"
                >
                  {{ item }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="delVideo(index)"
                  >
                    <g
                      id="그룹_20488"
                      data-name="그룹 20488"
                      transform="translate(-166 -529)"
                    >
                      <rect
                        id="사각형_24934"
                        data-name="사각형 24934"
                        width="20"
                        height="20"
                        transform="translate(166 529)"
                        fill="none"
                      />
                      <g
                        id="그룹_19689"
                        data-name="그룹 19689"
                        transform="translate(-2 -2)"
                      >
                        <line
                          id="_선_81"
                          x1="10"
                          y2="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <line
                          id="_선_82"
                          x1="10"
                          y1="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="userInfo.field.photography.length !== 0">
              <div class="myfield_name">영상</div>
              <div class="myfield_wrapper">
                <div
                  v-for="(item, index) in userInfo.field.photography"
                  :key="index"
                  class="myfield_item"
                >
                  {{ item }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="delPhotography(index)"
                  >
                    <g
                      id="그룹_20488"
                      data-name="그룹 20488"
                      transform="translate(-166 -529)"
                    >
                      <rect
                        id="사각형_24934"
                        data-name="사각형 24934"
                        width="20"
                        height="20"
                        transform="translate(166 529)"
                        fill="none"
                      />
                      <g
                        id="그룹_19689"
                        data-name="그룹 19689"
                        transform="translate(-2 -2)"
                      >
                        <line
                          id="_선_81"
                          x1="10"
                          y2="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <line
                          id="_선_82"
                          x1="10"
                          y1="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="userInfo.field.design.length !== 0">
              <div class="myfield_name">디자인</div>
              <div class="myfield_wrapper">
                <div
                  v-for="(item, index) in userInfo.field.design"
                  :key="index"
                  class="myfield_item"
                >
                  {{ item }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="delDesign(index)"
                  >
                    <g
                      id="그룹_20488"
                      data-name="그룹 20488"
                      transform="translate(-166 -529)"
                    >
                      <rect
                        id="사각형_24934"
                        data-name="사각형 24934"
                        width="20"
                        height="20"
                        transform="translate(166 529)"
                        fill="none"
                      />
                      <g
                        id="그룹_19689"
                        data-name="그룹 19689"
                        transform="translate(-2 -2)"
                      >
                        <line
                          id="_선_81"
                          x1="10"
                          y2="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <line
                          id="_선_82"
                          x1="10"
                          y1="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="userInfo.field.specialist.length !== 0">
              <div class="myfield_name">스페셜리스트</div>
              <div class="myfield_wrapper">
                <div
                  v-for="(item, index) in userInfo.field.specialist"
                  :key="index"
                  class="myfield_item"
                >
                  {{ item }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="delSpecialist(index)"
                  >
                    <g
                      id="그룹_20488"
                      data-name="그룹 20488"
                      transform="translate(-166 -529)"
                    >
                      <rect
                        id="사각형_24934"
                        data-name="사각형 24934"
                        width="20"
                        height="20"
                        transform="translate(166 529)"
                        fill="none"
                      />
                      <g
                        id="그룹_19689"
                        data-name="그룹 19689"
                        transform="translate(-2 -2)"
                      >
                        <line
                          id="_선_81"
                          x1="10"
                          y2="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <line
                          id="_선_82"
                          x1="10"
                          y1="10"
                          transform="translate(173 536)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="keywordInput">
        <div class="keywordInput-header">
          <h4>키워드</h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="setKeyword"
          >
            <g
              id="그룹_20410"
              data-name="그룹 20410"
              transform="translate(-295 -685)"
            >
              <rect
                id="사각형_24940"
                data-name="사각형 24940"
                width="30"
                height="30"
                transform="translate(295 685)"
                fill="none"
              />
              <path
                id="패스_8055"
                data-name="패스 8055"
                d="M318.9,698.9h-7.8v-7.8a1.1,1.1,0,0,0-2.2,0v7.8h-7.8a1.1,1.1,0,1,0,0,2.2h7.8v7.8a1.1,1.1,0,0,0,2.2,0v-7.8h7.8a1.1,1.1,0,1,0,0-2.2Z"
                fill="#707070"
              />
            </g>
          </svg>
        </div>

        <div class="keyword">
          <div
            class="keyword_wrapper"
            v-if="userInfo.interests.music.length !== 0"
          >
            <div class="myfield_name">음악</div>
            <div class="myfield_wrapper">
              <div
                v-for="(item, index) in userInfo.interests.music"
                :key="index"
                class="myfield_item"
              >
                {{ item }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="roleDelMusic(index)"
                >
                  <g
                    id="그룹_20488"
                    data-name="그룹 20488"
                    transform="translate(-166 -529)"
                  >
                    <rect
                      id="사각형_24934"
                      data-name="사각형 24934"
                      width="20"
                      height="20"
                      transform="translate(166 529)"
                      fill="none"
                    />
                    <g
                      id="그룹_19689"
                      data-name="그룹 19689"
                      transform="translate(-2 -2)"
                    >
                      <line
                        id="_선_81"
                        x1="10"
                        y2="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <line
                        id="_선_82"
                        x1="10"
                        y1="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="userInfo.interests.video.length !== 0">
            <div class="myfield_name">영상</div>
            <div class="myfield_wrapper">
              <div
                v-for="(item, index) in userInfo.interests.video"
                :key="index"
                class="myfield_item"
              >
                {{ item }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="roleDelVideo(index)"
                >
                  <g
                    id="그룹_20488"
                    data-name="그룹 20488"
                    transform="translate(-166 -529)"
                  >
                    <rect
                      id="사각형_24934"
                      data-name="사각형 24934"
                      width="20"
                      height="20"
                      transform="translate(166 529)"
                      fill="none"
                    />
                    <g
                      id="그룹_19689"
                      data-name="그룹 19689"
                      transform="translate(-2 -2)"
                    >
                      <line
                        id="_선_81"
                        x1="10"
                        y2="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <line
                        id="_선_82"
                        x1="10"
                        y1="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="userInfo.interests.photography.length !== 0">
            <div class="myfield_name">사진</div>
            <div class="myfield_wrapper">
              <div
                v-for="(item, index) in userInfo.interests.photography"
                :key="index"
                class="myfield_item"
              >
                {{ item }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="roleDelPhotography(index)"
                >
                  <g
                    id="그룹_20488"
                    data-name="그룹 20488"
                    transform="translate(-166 -529)"
                  >
                    <rect
                      id="사각형_24934"
                      data-name="사각형 24934"
                      width="20"
                      height="20"
                      transform="translate(166 529)"
                      fill="none"
                    />
                    <g
                      id="그룹_19689"
                      data-name="그룹 19689"
                      transform="translate(-2 -2)"
                    >
                      <line
                        id="_선_81"
                        x1="10"
                        y2="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <line
                        id="_선_82"
                        x1="10"
                        y1="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="userInfo.interests.design.length !== 0">
            <div class="myfield_name">디자인</div>
            <div class="myfield_wrapper">
              <div
                v-for="(item, index) in userInfo.interests.design"
                :key="index"
                class="myfield_item"
              >
                {{ item }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="roleDelDesign(index)"
                >
                  <g
                    id="그룹_20488"
                    data-name="그룹 20488"
                    transform="translate(-166 -529)"
                  >
                    <rect
                      id="사각형_24934"
                      data-name="사각형 24934"
                      width="20"
                      height="20"
                      transform="translate(166 529)"
                      fill="none"
                    />
                    <g
                      id="그룹_19689"
                      data-name="그룹 19689"
                      transform="translate(-2 -2)"
                    >
                      <line
                        id="_선_81"
                        x1="10"
                        y2="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <line
                        id="_선_82"
                        x1="10"
                        y1="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="userInfo.interests.specialist.length !== 0">
            <div class="myfield_name">스페셜리스트</div>
            <div class="myfield_wrapper">
              <div
                v-for="(item, index) in userInfo.interests.specialist"
                :key="index"
                class="myfield_item"
              >
                {{ item }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="roleDelSpecialist(index)"
                >
                  <g
                    id="그룹_20488"
                    data-name="그룹 20488"
                    transform="translate(-166 -529)"
                  >
                    <rect
                      id="사각형_24934"
                      data-name="사각형 24934"
                      width="20"
                      height="20"
                      transform="translate(166 529)"
                      fill="none"
                    />
                    <g
                      id="그룹_19689"
                      data-name="그룹 19689"
                      transform="translate(-2 -2)"
                    >
                      <line
                        id="_선_81"
                        x1="10"
                        y2="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <line
                        id="_선_82"
                        x1="10"
                        y1="10"
                        transform="translate(173 536)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="keywordInputBox">
            <div v-for="(item, index) in userInfo.interests.music" :key="index">
              <span>음악/{{ item }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                @click="roleDelMusic(index)">
                <g id="그룹_20488" data-name="그룹 20488" transform="translate(-166 -529)">
                  <rect id="사각형_24934" data-name="사각형 24934" width="20" height="20" transform="translate(166 529)"
                    fill="none" />
                  <g id="그룹_19689" data-name="그룹 19689" transform="translate(-2 -2)">
                    <line id="_선_81" x1="10" y2="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                    <line id="_선_82" x1="10" y1="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
            <div v-for="(item, index) in userInfo.interests.video" :key="`video-${index}`">
              <span>영상/{{ item }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                @click="roleDelVideo(index)">
                <g id="그룹_20488" data-name="그룹 20488" transform="translate(-166 -529)">
                  <rect id="사각형_24934" data-name="사각형 24934" width="20" height="20" transform="translate(166 529)"
                    fill="none" />
                  <g id="그룹_19689" data-name="그룹 19689" transform="translate(-2 -2)">
                    <line id="_선_81" x1="10" y2="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                    <line id="_선_82" x1="10" y1="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
            <div v-for="(item, index) in userInfo.interests.photography" :key="`photography-${index}`">
              <span>사진/{{ item }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                @click="roleDelPhotography(index)">
                <g id="그룹_20488" data-name="그룹 20488" transform="translate(-166 -529)">
                  <rect id="사각형_24934" data-name="사각형 24934" width="20" height="20" transform="translate(166 529)"
                    fill="none" />
                  <g id="그룹_19689" data-name="그룹 19689" transform="translate(-2 -2)">
                    <line id="_선_81" x1="10" y2="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                    <line id="_선_82" x1="10" y1="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
            <div v-for="(item, index) in userInfo.interests.design" :key="`design-${index}`">
              <span>디자인/{{ item }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                @click="roleDelDesign(index)">
                <g id="그룹_20488" data-name="그룹 20488" transform="translate(-166 -529)">
                  <rect id="사각형_24934" data-name="사각형 24934" width="20" height="20" transform="translate(166 529)"
                    fill="none" />
                  <g id="그룹_19689" data-name="그룹 19689" transform="translate(-2 -2)">
                    <line id="_선_81" x1="10" y2="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                    <line id="_선_82" x1="10" y1="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
            <div v-for="(item, index) in userInfo.interests.specialist" :key="`specialist-${index}`">
              <span>기타/{{ item }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                @click="roleDelSpecialist(index)">
                <g id="그룹_20488" data-name="그룹 20488" transform="translate(-166 -529)">
                  <rect id="사각형_24934" data-name="사각형 24934" width="20" height="20" transform="translate(166 529)"
                    fill="none" />
                  <g id="그룹_19689" data-name="그룹 19689" transform="translate(-2 -2)">
                    <line id="_선_81" x1="10" y2="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                    <line id="_선_82" x1="10" y1="10" transform="translate(173 536)" fill="none" stroke="#707070"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
          </div> -->
      </div>
    </div>
    <div class="Introduce">
      <h4>소개</h4>
      <textarea
        name="introduce"
        id="introduce"
        v-model="userInfo.introduction"
        placeholder="자유 형식으로 소개글을 입력해주세요."
        ref="textarea"
        onclick="
          this.style.height = '1px';
          this.style.height = 12 + this.scrollHeight + 'px';
        "
      ></textarea>
    </div>
    <div class="education">
      <div class="education-header">
        <h4>학력</h4>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          @click="goEducationSet"
        >
          <g
            id="그룹_20410"
            data-name="그룹 20410"
            transform="translate(-295 -685)"
          >
            <rect
              id="사각형_24940"
              data-name="사각형 24940"
              width="30"
              height="30"
              transform="translate(295 685)"
              fill="none"
            />
            <path
              id="패스_8055"
              data-name="패스 8055"
              d="M318.9,698.9h-7.8v-7.8a1.1,1.1,0,0,0-2.2,0v7.8h-7.8a1.1,1.1,0,1,0,0,2.2h7.8v7.8a1.1,1.1,0,0,0,2.2,0v-7.8h7.8a1.1,1.1,0,1,0,0-2.2Z"
              fill="#707070"
            />
          </g>
        </svg>
      </div>
      <div class="educationBox">
        <div
          class="educationItem"
          v-for="(item, index) in schools"
          :key="index"
        >
          <div
            class="educationItem2"
            :class="[item.open === false ? 'noOpen' : 'Open']"
          >
            <h5>{{ item.name }}</h5>
            <span>{{ item.class }}</span>
            <span>{{ item.dateS }}~{{ item.dateE }}</span>
            <span>{{ item.status }}</span>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="item.btnBox = !item.btnBox"
          >
            <g
              id="그룹_19648"
              data-name="그룹 19648"
              transform="translate(-151 -685)"
            >
              <rect
                id="사각형_24937"
                data-name="사각형 24937"
                width="30"
                height="30"
                transform="translate(151 685)"
                fill="none"
              />
              <path
                id="패스_8050"
                data-name="패스 8050"
                d="M166,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,166,688Z"
                fill="#fff"
              />
              <circle
                id="_타원_185"
                cx="2"
                cy="2"
                r="2"
                transform="translate(158 698)"
                fill="#707070"
              />
              <circle
                id="_타원_186"
                cx="2"
                cy="2"
                r="2"
                transform="translate(164 698)"
                fill="#707070"
              />
              <circle
                id="_타원_187"
                cx="2"
                cy="2"
                r="2"
                transform="translate(170 698)"
                fill="#707070"
              />
            </g>
          </svg>
          <div class="educationItemBtn" v-if="item.btnBox == true">
            <div @click="item.open = !item.open" v-if="item.open == true">
              비공개
            </div>
            <div @click="item.open = !item.open" v-if="item.open == false">
              공개
            </div>
            <div @click="educationRetouch(item, index)">수정</div>
            <div @click="educationDel(index)">삭제</div>
          </div>
        </div>
      </div>
    </div>
    <div class="workExperience">
      <h4>활동경력</h4>
      <textarea
        name="workExperience"
        id="workExperience"
        v-model="userInfo.career"
        placeholder="자유 형식으로 활동 경력을 입력해주세요."
        ref="textarea2"
        onclick="this.style.height='1px'; this.style.height = 12+ this.scrollHeight + 'px';"
      ></textarea>
    </div>
    <div class="social">
      <div class="social-header">
        <h4>소셜미디어</h4>
        <span
          >(형식에 맞는 소셜미디어의 아이디 혹은 닉네임을 입력해주세요)</span
        >
      </div>
      <div class="socialBox">
        <div class="socialBox-item">
          <div>Instagram</div>
          <span>https://www.instagram.com/</span>
          <input type="text" v-model="userInfo.social_media.instagram" />
        </div>
        <div class="socialBox-item">
          <div>Youtube</div>
          <span>https://www.youtube.com/@</span>

          <input type="text" v-model="userInfo.social_media.youtube" />
        </div>
        <div class="socialBox-item">
          <div>SoundCloud</div>
          <span>https://soundcloud.com/</span>

          <input type="text" v-model="userInfo.social_media.sound_cloud" />
        </div>
        <div class="socialBox-item">
          <div>Vimeo</div>
          <span>https://vimeo.com/</span>

          <input type="text" v-model="userInfo.social_media.vimeo" />
        </div>
      </div>
    </div>
    <div class="endBtn" @click="saveData">저장</div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import educationRetouch from "./educationRetouch.vue";
import profileImgChange from "./profileImgChange.vue";
export default {
  components: { educationRetouch, profileImgChange },
  name: "userinfoSetup",
  beforeRouteLeave(to, from, next) {
    if (to.path !== "/") {
      const confirmLeave = confirm(
        "이 페이지에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다."
      );
      confirmLeave ? next() : next(false);
    } else {
      next();
    }
    // else if (
    //   confirm(
    //     "이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다. 로그아웃하신경우 취소를 누르셔도 로그아웃됩니다."
    //   )
    // ) {
    //   next();
    // }
  },
  mounted() {
    this.resize();
    let loginUg = localStorage.getItem("loginUg");
    if (loginUg == "false") {
      let token = JSON.parse(sessionStorage.getItem("token"));
      this.$axios
        .get(this.API_URL + "/accounts/profiles", {
          params: { userID: token.userID },
          headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((res) => {
          this.userInfo.userID = res.data.profile_information.userID;
          this.userInfo.nickName = res.data.profile_information.nickname;
          this.userInfo.hello = res.data.profile_information.hello;
          this.userInfo.main_field = res.data.profile_information.main_field;
          this.userInfo.main_role = res.data.profile_information.main_role;

          this.userInfo.field.music = res.data.profile_information.field.music;
          this.userInfo.field.video = res.data.profile_information.field.video;
          this.userInfo.field.photography =
            res.data.profile_information.field.photography;
          this.userInfo.field.design =
            res.data.profile_information.field.design;
          this.userInfo.field.specialist =
            res.data.profile_information.field.specialist;

          this.userInfo.interests.music =
            res.data.profile_information.interests.music;
          this.userInfo.interests.video =
            res.data.profile_information.interests.video;
          this.userInfo.interests.photography =
            res.data.profile_information.interests.photography;
          this.userInfo.interests.design =
            res.data.profile_information.interests.design;
          this.userInfo.interests.specialist =
            res.data.profile_information.interests.specialist;

          this.userInfo.introduction =
            res.data.profile_information.introduction;

          this.userInfo.graduated_at.schoolList =
            res.data.profile_information.graduated_at.schoolList;

          this.userInfo.career = res.data.profile_information.career;

          this.userInfo.social_media =
            res.data.profile_information.social_media;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$axios
        .get(this.API_URL + "/accounts/profile-img", {
          headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.userInfo.profileImg = res.data.profile_img;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (loginUg == "true") {
      let token = this.$cookie.get("access_token");
      let userID = this.$cookie.get("userID");
      this.$axios
        .get(this.API_URL + "/accounts/profiles", {
          params: { userID: userID },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          this.userInfo.userID = res.data.profile_information.userID;
          this.userInfo.nickName = res.data.profile_information.nickname;
          this.userInfo.hello = res.data.profile_information.hello;
          this.userInfo.main_field = res.data.profile_information.main_field;
          this.userInfo.main_role = res.data.profile_information.main_role;

          this.userInfo.field.music = res.data.profile_information.field.music;
          this.userInfo.field.video = res.data.profile_information.field.video;
          this.userInfo.field.photography =
            res.data.profile_information.field.photography;
          this.userInfo.field.design =
            res.data.profile_information.field.design;
          this.userInfo.field.specialist =
            res.data.profile_information.field.specialist;

          this.userInfo.interests.music =
            res.data.profile_information.interests.music;
          this.userInfo.interests.video =
            res.data.profile_information.interests.video;
          this.userInfo.interests.photography =
            res.data.profile_information.interests.photography;
          this.userInfo.interests.design =
            res.data.profile_information.interests.design;
          this.userInfo.interests.specialist =
            res.data.profile_information.interests.specialist;

          this.userInfo.introduction =
            res.data.profile_information.introduction;

          // this.userInfo.graduated_at.schoolList =
          //   res.data.profile_information.graduated_at.schoolList;
          this.$store.commit(
            "set_schoolList",
            res.data.profile_information.graduated_at.schoolList
          );
          this.userInfo.career = res.data.profile_information.career;

          this.userInfo.social_media =
            res.data.profile_information.social_media;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$axios
        .get(this.API_URL + "/accounts/profile-img", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.userInfo.profileImg = res.data.profile_img;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  data() {
    return {
      popup: false,
      imgTest: "",
      doChangeImg: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userSetup.userInfoSetup;
    },
    PFChangeImg() {
      return this.$store.state.userSetup.PFChangeImg;
    },
    schools() {
      return this.$store.state.userSetup.userInfoSetup.graduated_at.schoolList;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    resize() {
      const textarea = this.$refs.textarea;
      const textarea2 = this.$refs.textarea2;
      textarea.click();
      textarea2.click();
    },
    filePush() {
      this.$store.commit("set_changeImg", this.$refs.inputImg.files[0]);
      this.doChangeImg = true;
    },
    changeProfile() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.popup = true;
    },
    closePop() {
      this.popup = false;
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
    },
    basicProfileImg() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(this.API_URL + "/accounts/profile-img", {
            params: { userID: token.userID },
            headers: { Authorization: `Bearer ${token.access_token}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.userInfo.profileImg = res.data.profile_img;
              this.$router.go();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .delete(this.API_URL + "/accounts/profile-img", {
            params: { userID: userID },
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.userInfo.profileImg = res.data.profile_img;
              this.$router.go();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    goEducationSet() {
      this.$router.push({ name: "educationset" });
    },
    educationDel(index) {
      this.$store.commit("REMOVE_EDUCATION", index);
    },

    educationRetouch(Obj, index) {
      this.$store.commit("SET_EDURETOUCH", Obj, index);
      Obj.btnBox = false;
      this.$router.push({ name: "educationretouch" });
    },
    setField() {
      this.$router.push({ name: "setfield" });
    },
    setKeyword() {
      this.$router.push({ name: "setkeyword" });
    },
    saveData() {
      if (this.userInfo.main_field == "") {
        alert("대표분야를 적어주세요.");
      } else if (this.userInfo.main_role == "") {
        alert("대표분야를 적어주세요.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .patch(
              this.API_URL + "/accounts/profiles",
              {
                userID: token.userID,
                main_field: this.userInfo.main_field,
                main_role: this.userInfo.main_role,
                hello: this.userInfo.hello,
                field: this.userInfo.field,
                interests: this.userInfo.interests,
                introduction: this.userInfo.introduction,
                graduated_at: this.userInfo.graduated_at,
                career: this.userInfo.career,
                social_media: {
                  instagram:
                    "https://www.instagram.com/" +
                    this.userInfo.social_media.instagram,
                  sound_cloud:
                    "https://soundcloud.com/" +
                    this.userInfo.social_media.sound_cloud,
                  vimeo:
                    "https://vimeo.com/" + this.userInfo.social_media.vimeo,
                  youtube:
                    "https://www.youtube.com/@" +
                    this.userInfo.social_media.youtube,
                },
              },
              { headers: { Authorization: `Bearer ${token.access_token}` } }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$router.push({ name: "savefinish" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .patch(
              this.API_URL + "/accounts/profiles",
              {
                userID: userID,
                main_field: this.userInfo.main_field,
                main_role: this.userInfo.main_role,
                hello: this.userInfo.hello,
                field: this.userInfo.field,
                interests: this.userInfo.interests,
                introduction: this.userInfo.introduction,
                graduated_at: this.userInfo.graduated_at,
                career: this.userInfo.career,
                social_media: {
                  instagram:
                    "https://www.instagram.com/" +
                    this.userInfo.social_media.instagram,
                  sound_cloud:
                    "https://soundcloud.com/" +
                    this.userInfo.social_media.sound_cloud,
                  vimeo:
                    "https://vimeo.com/" + this.userInfo.social_media.vimeo,
                  youtube:
                    "https://www.youtube.com/@" +
                    this.userInfo.social_media.youtube,
                },
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$router.push({ name: "savefinish" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    delMusic(index) {
      this.$store.commit("REMOVE_FIELD_MUSIC", index);
    },
    delVideo(index) {
      this.$store.commit("REMOVE_FIELD_VIDEO", index);
    },
    delPhotography(index) {
      this.$store.commit("REMOVE_FIELD_PHOTOGRAPHY", index);
    },
    delDesign(index) {
      this.$store.commit("REMOVE_FIELD_DESIGN", index);
    },
    delSpecialist(index) {
      this.$store.commit("REMOVE_FIELD_SPECIALIST", index);
    },

    roleDelMusic(index) {
      this.$store.commit("REMOVE_ROLE_MUSIC", index);
    },
    roleDelVideo(index) {
      this.$store.commit("REMOVE_ROLE_VIDEO", index);
    },
    roleDelPhotography(index) {
      this.$store.commit("REMOVE_ROLE_PHOTOGRAPHY", index);
    },
    roleDelDesign(index) {
      this.$store.commit("REMOVE_ROLE_DESIGN", index);
    },
    roleDelSpecialist(index) {
      this.$store.commit("REMOVE_ROLE_SPECIALIST", index);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
}

.white-bg {
  width: 300px;
  height: 194px;
  margin: 303px auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 30px;
}

.white-bg h3 {
  text-align: center;
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
  padding: 20px 0;
}

.popupBtns div {
  width: 240px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 2px;
}

.popupBtns input {
  display: none;
}

.popupBtn1 {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;

  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}

.popupBtn1 label {
  cursor: pointer;
}

.popupBtn2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;

  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #8ca0ef;
  margin-bottom: 10px;
}

.popupBtn3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 15px;

  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 10px;
}

.noBox {
  display: none;
}

.show {
  display: show;
}

textarea {
  border: none;
  resize: none;
  outline: none;
  min-width: 732px;
  max-width: 732px;
  line-height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 20px;
  min-height: 160px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  overflow: visible;
  box-sizing: border-box;
}

textarea.autosize {
  min-height: 160px;
}

#userinfoSetup {
  min-width: 792px;
  min-height: 1480px;
  max-width: 792px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 96px 0px;
  padding: 0 30px;
}

#userinfoSetup h2 {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
  padding: 40px 0;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.headerImg img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 30px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.headerImg div {
  width: 150px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;

  text-align: center;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 6px;
  cursor: pointer;
}

.headerInfo {
  display: flex;
  flex-direction: column;
}

.headerInfo-headinput input {
  outline: none;
  border: none;
  width: 120px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-right: 20px;
  box-sizing: border-box;
  padding-left: 10px;
  margin-bottom: 10px;
}

.headerInfo-head h3 {
  font: normal normal bold 24px/35px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #000000;
  margin-right: 95px;
  margin-bottom: 10px;
}

.headerInfo_ment {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
  margin-bottom: 10px;
}

.headerInfo_ment2 {
  font: normal normal normal 14px/24px Noto Sans CJK KR;
  letter-spacing: -0.35px;
}

.mentInput {
  outline: none;
  border: none;
  max-width: 552px;
  max-height: 64px;
  min-width: 552px;
  min-height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #000000;
  overflow: hidden;
  box-sizing: border-box;
}

.fieldInput,
.keywordInput {
  margin-bottom: 30px;
}

.fieldInput-header,
.keywordInput-header,
.education-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fieldInput-header h4,
.keywordInput-header h4,
.education-header h4 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
  margin-bottom: 10px;
}

.social-header h4 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
}

.fieldInput-header svg,
.keywordInput-header svg,
.education-header svg {
  margin-right: 10px;
  cursor: pointer;
}

.fieldInputBox,
.keywordInputBox {
  width: 732px;
  min-height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;

  display: flex;
  flex-flow: wrap;

  align-items: center;
  padding: 20px;
  padding-top: 10px;
}

.fieldInputBox div,
.keywordInputBox div {
  margin-right: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.fieldInputBox div > svg,
.keywordInputBox div > svg {
  margin-top: 3px;
  margin-left: 6px;
  cursor: pointer;
}

.Introduce,
.workExperience {
  margin-bottom: 30px;
}

.Introduce h4,
.workExperience h4 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
  margin-bottom: 10px;
}

.educationBox {
  width: 732px;
  min-height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;
}

.educationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}

.educationItem2 {
  display: flex;
  align-items: center;
}

.educationItem:last-child {
  margin-bottom: 0;
}

.educationItem2 h5 {
  font: normal normal normal 18px/24px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-right: 10px;
}

.educationItem2 span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  margin-right: 10px;
}

.educationItem svg {
  cursor: pointer;
}

.educationItemBtn {
  position: absolute;
  top: 30px;
  right: 0;
  width: 60px;
  height: 86px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  z-index: 1;
  text-align: center;
  padding-top: 10px;
}

.educationItemBtn div {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #707070;
  margin-bottom: 6px;
  cursor: pointer;
}

.educationItemBtn div:last-child {
  color: #cf1f1f;
}

.social {
  margin-bottom: 40px;
}

.social-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.social-header span {
  font: normal normal normal 14px/24px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  margin-left: 4px;
  padding-bottom: 2px;
}

.socialBox {
  width: 732px;
  height: 190px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.socialBox-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.socialBox-item:last-child {
  margin-bottom: 0;
}

.socialBox-item div {
  min-width: 100px;
  height: 22px;
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
  text-align: center;
}

.socialBox-item span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #dbdbdb;
  margin-left: 40px;
  margin-right: 10px;
}

.socialBox-item input {
  width: 100%;
  height: 30px;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding-left: 20px;
}

.endBtn {
  width: 186px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 42px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 40px;
  cursor: pointer;
}

.noOpen h5,
.noOpen span {
  /* color: rgba(0, 0, 0, 0.9); */
  color: rgba(0, 0, 0, 0.3);
}

.introduce,
/* .workExperience, */
.myfield,
.keyword {
  width: 1200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 20px;
  box-sizing: border-box;
}

.myfield,
.keyword {
  min-height: 40px;
  margin-bottom: 30px;
  flex-flow: wrap;
  padding: 20px 20px;
  width: auto;
}

.myfield > div,
.keyword > div {
  margin-bottom: 20px;
}

.myfield > div:last-child,
.keyword > div:last-child {
  margin-bottom: 0;
}

.myfield_name {
  color: var(--c-main, #8ca0ef);
  font-family: Noto Sans CJK KR;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.myfield_item {
  color: var(--c-191919, #191919);
  font-family: Noto Sans CJK KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
  padding-bottom: 10px;
  white-space: nowrap;
  word-break: break-all;
  display: flex;
  align-items: flex-end;
}

.myfield_wrapper,
.keyword_wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
