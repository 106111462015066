<template>
  <div class="main">
    <div class="nav">
      <div class="item1">번호</div>
      <div class="item2">제목</div>
      <div class="item3">작성자</div>
      <div class="item4">작성일</div>
    </div>
    <div id="contentsBox">
      <div
        class="contents notice"
        v-for="(item, index) in freeNTData"
        :key="`freeNT-${index}`"
      >
        <div class="num">공지</div>
        <div class="title" @click="goFreeDetail(item.id)">
          <p>
            {{ item.title }}
          </p>
        </div>
        <div class="writer">{{ item.nickname }}</div>
        <div class="created_at">{{ item.created_date }}</div>
      </div>
      <div
        class="contents"
        v-for="(item, index) in freeCMData"
        :key="`freeCMData-${index}`"
      >
        <div class="num">{{ item.id }}</div>
        <div class="title" @click="goFreeDetail(item.id)">
          <p>
            {{ item.title }}
          </p>
        </div>
        <div class="writer">{{ item.nickname }}</div>
        <div class="created_at">{{ item.created_date }}</div>
      </div>
    </div>
    <div class="pagingBox">
      <div @click="lastGoPage">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style="display: flex; align-item: center; cursor: pointer"
        >
          <g
            id="그룹_20908"
            data-name="그룹 20908"
            transform="translate(-825 -1333)"
          >
            <rect
              id="사각형_24385"
              data-name="사각형 24385"
              width="20"
              height="20"
              transform="translate(825 1333)"
              fill="none"
              opacity="0.5"
            />
            <g
              id="그룹_19026"
              data-name="그룹 19026"
              transform="translate(-150 157)"
            >
              <path
                id="패스_7802"
                data-name="패스 7802"
                d="M4,0,0,5l4,5"
                transform="translate(981 1181)"
                fill="none"
                stroke="#767676"
                stroke-width="2"
              />
              <path
                id="패스_7803"
                data-name="패스 7803"
                d="M4,0,0,5l4,5"
                transform="translate(986 1181)"
                fill="none"
                stroke="#767676"
                stroke-width="2"
              />
            </g>
          </g>
        </svg>
      </div>
      <div @click="goPageBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style="display: flex; align-item: center; cursor: pointer"
        >
          <g
            id="그룹_20907"
            data-name="그룹 20907"
            transform="translate(-845 -1333)"
          >
            <rect
              id="사각형_192"
              data-name="사각형 192"
              width="20"
              height="20"
              transform="translate(845 1333)"
              fill="none"
              opacity="0.5"
            />
            <path
              id="패스_82"
              data-name="패스 82"
              d="M4,0,0,5l4,5"
              transform="translate(853 1338.5)"
              fill="none"
              stroke="#767676"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
      <div
        v-for="(item, index) in noticeCMPaging.last_pageData[
          noticeCMPaging.pageCount
        ]"
        :key="index"
        @click="goPage(item)"
        class="pageNum"
        :class="{ nowPage_color: noticeCMPaging.nowPage == item + 1 }"
      >
        <span id="">
          {{ item + 1 }}
        </span>
      </div>
      <div @click="backPageBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style="display: flex; align-item: center; cursor: pointer"
        >
          <g
            id="그룹_20906"
            data-name="그룹 20906"
            transform="translate(-1105 -1333)"
          >
            <rect
              id="사각형_192"
              data-name="사각형 192"
              width="20"
              height="20"
              transform="translate(1125 1353) rotate(180)"
              fill="none"
              opacity="0.5"
            />
            <path
              id="패스_82"
              data-name="패스 82"
              d="M4,0,0,5l4,5"
              transform="translate(1117 1348.5) rotate(180)"
              fill="none"
              stroke="#767676"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
      <div @click="lastBackPage">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style="display: flex; align-item: center; cursor: pointer"
        >
          <g
            id="그룹_20905"
            data-name="그룹 20905"
            transform="translate(-1125 -1333)"
          >
            <rect
              id="사각형_24384"
              data-name="사각형 24384"
              width="20"
              height="20"
              transform="translate(1145 1353) rotate(180)"
              fill="none"
              opacity="0.5"
            />
            <g
              id="그룹_19025"
              data-name="그룹 19025"
              transform="translate(-101 158)"
            >
              <path
                id="패스_7804"
                data-name="패스 7804"
                d="M0,0,4,5,0,10"
                transform="translate(1237 1180)"
                fill="none"
                stroke="#767676"
                stroke-width="2"
              />
              <path
                id="패스_7805"
                data-name="패스 7805"
                d="M0,0,4,5,0,10"
                transform="translate(1232 1180)"
                fill="none"
                stroke="#767676"
                stroke-width="2"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="searchBox">
      <select v-model="noticeCMPaging.searchValue">
        <option value="0">제목</option>
        <option value="1">글쓴이</option>
        <option value="2">제목 + 글쓴이</option>
      </select>
      <input type="text" v-model="noticeCMPaging.searchText" />
      <div @click="searchBtn">검색</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$axios
      .get(this.API_URL + "/community/announce-board-page", {
        params: { page: this.noticeCMPaging.nowPage },
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.$store.commit("set_noticeCM", res.data.page_information);
          this.$store.commit("set_noticeNT", res.data.top_fixed_information);
          this.noticeCMPaging.lastBackPageNum = res.data.last_page;
          let i;
          for (i = 0; i < res.data.last_page; i++) {
            this.noticeCMPaging.last_page.push(i);
          }
          for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
            this.noticeCMPaging.last_pageData.push(
              this.noticeCMPaging.last_page.slice(i, i + 10)
            );
          }
          for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
            if (
              this.noticeCMPaging.last_pageData[i].includes(
                this.noticeCMPaging.nowPage - 1
              ) == true
            ) {
              this.noticeCMPaging.pageCount = i;
            } else {
              true;
            }
          }
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      // last_page: [],
      // last_pageData: [],
      // pageCount: 0,
      // nowPage: "",
      // lastBackPageNum: "",
      // searchValue: "0",
      // searchText: "",
    };
  },

  computed: {
    freeCMData() {
      return this.$store.state.community.noticeCMData;
    },
    freeNTData() {
      return this.$store.state.community.noticeNTData;
    },

    popularPost() {
      return this.$store.state.community.popularPost;
    },
    noticeCMPaging() {
      return this.$store.state.community.noticeCMPaging;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    goFreeDetail(postNum) {
      this.$router.push({
        path: "/community/noticeDetail/" + postNum,
      });
      this.$router.go();
    },
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
    goPage(idx) {
      let num = idx + 1;
      this.$store.commit("set_noticeDetailCMDel");
      this.$axios
        .get(this.API_URL + "/community/announce-board-page", {
          params: { page: num },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit("set_noticeCM", res.data.page_information);
            this.$store.commit("set_noticeNT", res.data.top_fixed_information);
            this.noticeCMPaging.lastBackPageNum = res.data.last_page;
            let i;
            for (i = 0; i < res.data.last_page; i++) {
              this.noticeCMPaging.last_page.push(i);
            }
            for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
              this.noticeCMPaging.last_pageData.push(
                this.noticeCMPaging.last_page.slice(i, i + 10)
              );
            }
            for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
              if (
                this.noticeCMPaging.last_pageData[i].includes(num - 1) == true
              ) {
                this.noticeCMPaging.pageCount = i;
              } else {
                true;
              }
            }
            this.$store.state.community.noticeCMPaging.nowPage = num;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    lastGoPage() {
      this.$store.commit("set_noticeDetailCMDel");
      this.$axios
        .get(this.API_URL + "/community/announce-board-page", {
          params: { page: 1 },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit("set_noticeCM", res.data.page_information);
            this.$store.commit("set_noticeNT", res.data.top_fixed_information);
            this.noticeCMPaging.lastBackPageNum = res.data.last_page;
            let i;
            for (i = 0; i < res.data.last_page; i++) {
              this.noticeCMPaging.last_page.push(i);
            }
            for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
              this.noticeCMPaging.last_pageData.push(
                this.noticeCMPaging.last_page.slice(i, i + 10)
              );
            }
            for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
              if (this.noticeCMPaging.last_pageData[i].includes(1) == true) {
                this.noticeCMPaging.pageCount = i;
              } else {
                true;
              }
            }
            this.$store.state.community.noticeCMPaging.nowPage = 1;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    lastBackPage() {
      this.$store.commit("set_noticeDetailCMDel");
      this.$axios
        .get(this.API_URL + "/community/announce-board-page", {
          params: { page: this.noticeCMPaging.lastBackPageNum },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit("set_noticeCM", res.data.page_information);
            this.$store.commit("set_noticeNT", res.data.top_fixed_information);
            this.noticeCMPaging.lastBackPageNum = res.data.last_page;
            let i;
            for (i = 0; i < res.data.last_page; i++) {
              this.noticeCMPaging.last_page.push(i);
            }
            for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
              this.noticeCMPaging.last_pageData.push(
                this.noticeCMPaging.last_page.slice(i, i + 10)
              );
            }
            for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
              if (
                this.noticeCMPaging.last_pageData[i].includes(
                  this.noticeCMPaging.lastBackPageNum - 1
                ) == true
              ) {
                this.noticeCMPaging.pageCount = i;
              } else {
                true;
              }
            }
            this.$store.state.community.noticeCMPaging.nowPage =
              this.noticeCMPaging.lastBackPageNum;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goPageBtn() {
      let num = this.noticeCMPaging.nowPage - 1;
      if (num == 0) {
        true;
      } else {
        this.$axios
          .get(this.API_URL + "/community/announce-board-page", {
            params: { page: num },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_noticeDetailCMDel");
              this.$store.commit("set_noticeCM", res.data.page_information);
              this.$store.commit(
                "set_noticeNT",
                res.data.top_fixed_information
              );
              this.noticeCMPaging.lastBackPageNum = res.data.last_page;
              let i;
              for (i = 0; i < res.data.last_page; i++) {
                this.noticeCMPaging.last_page.push(i);
              }
              for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
                this.noticeCMPaging.last_pageData.push(
                  this.noticeCMPaging.last_page.slice(i, i + 10)
                );
              }
              for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
                if (
                  this.noticeCMPaging.last_pageData[i].includes(num - 1) == true
                ) {
                  this.noticeCMPaging.pageCount = i;
                } else {
                  true;
                }
              }
              this.$store.state.community.noticeCMPaging.nowPage = num;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    backPageBtn() {
      let num = this.noticeCMPaging.nowPage + 1;
      if (num == this.noticeCMPaging.lastBackPageNum + 1) {
        true;
      } else {
        this.$axios
          .get(this.API_URL + "/community/announce-board-page", {
            params: { page: num },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_noticeDetailCMDel");
              this.$store.commit("set_noticeCM", res.data.page_information);
              this.$store.commit(
                "set_noticeNT",
                res.data.top_fixed_information
              );
              this.noticeCMPaging.lastBackPageNum = res.data.last_page;
              let i;
              for (i = 0; i < res.data.last_page; i++) {
                this.noticeCMPaging.last_page.push(i);
              }
              for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
                this.noticeCMPaging.last_pageData.push(
                  this.noticeCMPaging.last_page.slice(i, i + 10)
                );
              }
              for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
                if (
                  this.noticeCMPaging.last_pageData[i].includes(num - 1) == true
                ) {
                  this.noticeCMPaging.pageCount = i;
                } else {
                  true;
                }
              }
              this.$store.state.community.noticeCMPaging.nowPage = num;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    searchBtn() {
      this.$store.commit("set_noticeDel");
      this.noticeCMPaging.last_page = [];
      this.noticeCMPaging.last_pageData = [];
      this.noticeCMPaging.lastBackPageNum = "";
      this.$axios
        .get(this.API_URL + "/community/announce-search", {
          params: {
            keyword: this.noticeCMPaging.searchText,
            type: this.noticeCMPaging.searchValue,
          },
        })
        .then((res) => {
          this.$store.commit("set_noticeCM", res.data.page_information);
          this.$store.commit("set_noticeNT", res.data.top_fixed_information);
          this.noticeCMPaging.lastBackPageNum = res.data.last_page;
          let i;
          for (i = 0; i < res.data.last_page; i++) {
            this.noticeCMPaging.last_page.push(i);
          }
          for (i = 0; i < this.noticeCMPaging.last_page.length; i += 10) {
            this.noticeCMPaging.last_pageData.push(
              this.noticeCMPaging.last_page.slice(i, i + 10)
            );
          }
          for (i = 0; i < this.noticeCMPaging.last_pageData.length; i++) {
            if (
              this.noticeCMPaging.last_pageData[i].includes(
                this.noticeCMPaging.nowPage - 1
              ) == true
            ) {
              this.noticeCMPaging.pageCount = i;
            } else {
              true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
select {
  width: 90px;
  height: 30px;
  padding-top: 1px;
  padding-left: 19px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #505050;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
}
.main {
  min-width: 690px;
  min-height: 878px;
  max-width: 690px;
  max-height: 878px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 10px 30px;
  position: relative;
}
.nav {
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px 0;
}
#contentsBox {
  min-height: 680px;
}
.notice {
  background: #f0f5fe 0% 0% no-repeat padding-box;
}
.contents {
  display: flex;
  padding: 7px 0;
  border-bottom: 0.5px solid #d2d2d2;
}

.item1,
.item2,
.item3,
.item4,
.num,
.title,
.writer,
.created_at {
  text-align: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.item1,
.num {
  width: 80px;
  margin-right: 20px;
}
.item2,
.title {
  width: 340px;
  margin-right: 20px;
}
.item3,
.writer {
  width: 80px;
  margin-right: 10px;
}
.item4,
.created_at {
  width: 80px;
}
.num,
.title p,
.writer,
.created_at {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.title p {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title span {
  font: normal normal normal 10px/15px Noto Sans CJK KR;
  letter-spacing: -0.25px;
  color: #484848;
}
.writer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pagingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.pageNum {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nowPage_color {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: #ffffff;
}
.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.searchBox input {
  outline: none;
  border: none;
  width: 230px;
  height: 30px;
  border: 1px solid #dbdbdb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding-left: 10px;
}
.searchBox div {
  width: 78px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
}
</style>
