var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PFImgChange"},[(_vm.waitPopup == true)?_c('div',{staticClass:"wait-bg"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"white-bg"},[_c('h2',[_vm._v("프로필 사진 설정")]),_c('div',{staticClass:"imgBox2"},[_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",attrs:{"src":_vm.image.src,"auto-zoom":true,"stencil-props":{
          handlers: {},
          movable: true,
          scalable: false,
        },"stencil-size":{
          width: 282,
          height: 282,
        },"stencil-component":"circle-stencil","image-restriction":"stencil"},on:{"error":_vm.thumbnailErr}})],1),_c('div',{staticClass:"btns"},[_c('div',{on:{"click":_vm.backBtn}},[_vm._v("이전")]),_c('div',{staticClass:"btn2",on:{"click":_vm.save}},[_vm._v("적용")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wait-wg"},[_vm._v("업로드 중입니다."),_c('br'),_vm._v("잠시만 기다려주세요.")])
}]

export { render, staticRenderFns }