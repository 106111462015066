<template>
  <div id="otherProfile">
    <div>
      <h2>활동 분야</h2>
      <div class="myfield">
        <div v-for="(item, index) in field.music" :key="index">
          <span>음악/{{ item }}</span>
        </div>
        <div v-for="(item, index) in field.video" :key="`video-${index}`">
          <span>영상/{{ item }}</span>
        </div>
        <div
          v-for="(item, index) in field.photography"
          :key="`photography-${index}`"
        >
          <span>사진/{{ item }}</span>
        </div>
        <div v-for="(item, index) in field.design" :key="`design-${index}`">
          <span>디자인/{{ item }}</span>
        </div>
        <div
          v-for="(item, index) in field.specialist"
          :key="`specialist-${index}`"
        >
          <span>기타/{{ item }}</span>
        </div>
      </div>
    </div>
    <div>
      <h2>키워드</h2>
      <div class="keyword">
        <div v-for="(item, index) in interests.music" :key="index">
          <span>음악/{{ item }}</span>
        </div>

        <div v-for="(item, index) in interests.video" :key="`video-${index}`">
          <span>영상/{{ item }}</span>
        </div>
        <div
          v-for="(item, index) in interests.photography"
          :key="`photography-${index}`"
        >
          <span>사진/{{ item }}</span>
        </div>
        <div v-for="(item, index) in interests.design" :key="`design-${index}`">
          <span>디자인/{{ item }}</span>
        </div>
        <div
          v-for="(item, index) in interests.specialist"
          :key="`specialist-${index}`"
        >
          <span>기타/{{ item }}</span>
        </div>
      </div>
    </div>
    <div>
      <h2>소개</h2>
      <p class="introduce" v-html="handleNewLine(introduction)"></p>
    </div>

    <div class="EducationBox">
      <h2>학력</h2>
      <div class="Education">
        <div
          v-for="(item, index) in schoolList"
          :key="index"
          class="EducationItem"
        >
          <div class="Education-info" v-show="item.open == true">
            <h4>{{ item.name }}</h4>
            <span>{{ item.class }}</span>
            <span>{{ item.dateS }}~{{ item.dateE }}</span>
            <span>{{ item.status }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="workExperienceBox">
      <h2>활동경력</h2>
      <div class="workExperience">
        <p v-html="handleNewLine(career)"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "otherProfile",

  mounted() {
    let loginUg = localStorage.getItem("loginUg");
    if (loginUg == "false") {
      let token = JSON.parse(sessionStorage.getItem("token"));
      this.$axios
        .get(this.API_URL + "/accounts/profiles", {
          params: { userID: this.$route.params.id },
          headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((res) => {
          this.field.music = res.data.profile_information.field.music;
          this.field.video = res.data.profile_information.field.video;
          this.field.photography =
            res.data.profile_information.field.photography;
          this.field.design = res.data.profile_information.field.design;
          this.field.specialist = res.data.profile_information.field.specialist;

          this.interests.music = res.data.profile_information.interests.music;
          this.interests.video = res.data.profile_information.interests.video;
          this.interests.photography =
            res.data.profile_information.interests.photography;
          this.interests.design = res.data.profile_information.interests.design;
          this.interests.specialist =
            res.data.profile_information.interests.specialist;

          this.introduction = res.data.profile_information.introduction;

          this.schoolList =
            res.data.profile_information.graduated_at.schoolList;

          this.career = res.data.profile_information.career;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (loginUg == "true") {
      let token = this.$cookie.get("access_token");
      this.$axios
        .get(this.API_URL + "/accounts/profiles", {
          params: { userID: this.$route.params.id },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          this.field.music = res.data.profile_information.field.music;
          this.field.video = res.data.profile_information.field.video;
          this.field.photography =
            res.data.profile_information.field.photography;
          this.field.design = res.data.profile_information.field.design;
          this.field.specialist = res.data.profile_information.field.specialist;

          this.interests.music = res.data.profile_information.interests.music;
          this.interests.video = res.data.profile_information.interests.video;
          this.interests.photography =
            res.data.profile_information.interests.photography;
          this.interests.design = res.data.profile_information.interests.design;
          this.interests.specialist =
            res.data.profile_information.interests.specialist;

          this.introduction = res.data.profile_information.introduction;

          this.schoolList =
            res.data.profile_information.graduated_at.schoolList;

          this.career = res.data.profile_information.career;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  data() {
    return {
      field: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
      interests: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
      introduction: "",

      schoolList: [],

      career: "",
    };
  },
  computed: {
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#otherProfile {
  margin-top: 30px;
  padding-bottom: 294px;
}
#otherProfile h2 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 10px;
}

.introduce,
.workExperience,
.myfield,
.keyword {
  width: 1200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 20px;
  box-sizing: border-box;
}
.myfield,
.keyword {
  min-height: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding: 10px 20px;
}
.myfield span,
.keyword span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  margin-right: 20px;
}

.introduce {
  min-height: 136px;
  padding: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}

.EducationBox {
  margin-top: 30px;
}
.Education {
  width: 1200px;
  min-height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px 20px;
}
.EducationItem {
  margin-bottom: 10px;
}
.EducationItem:last-child {
  margin-bottom: 0px;
}

.Education h4 {
  font: normal normal normal 18px/24px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-right: 10px;
}
.Education-info {
  display: flex;
  align-items: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.Education-info span {
  margin-right: 10px;
}

.workExperienceBox {
  margin-top: 30px;
}
.workExperience {
  min-height: 144px;
  padding: 20px;
}
</style>
