<template>
  <div id="freePage">
    <div class="main-navbox">
      <h2>자유게시판</h2>
      <div class="main-navboxItem">
        <span @click="noticePage">공지사항</span>
        <span id="main-navboxItem_check" @click="freePage">자유게시판</span>
        <span @click="InquirePage">고객문의</span>
      </div>
      <div class="mycontentsBtn" @click="goMyContents">내 게시글 관리</div>
      <div class="goPageBtn" @click="goWritePage">게시글 작성</div>
    </div>
    <div class="wrap">
      <div class="detailBox">
        <!-- <free-detail v-if="freeDetailID !== ''"></free-detail> -->
        <!-- <notice-detail v-if="noticeDetailID !== ''"></notice-detail> -->
        <div class="main">
          <div class="nav">
            <div class="item1">번호</div>
            <div class="item2">제목</div>
            <div class="item3">작성자</div>
            <div class="item4">작성일</div>
          </div>
          <div id="contentsBox">
            <div
              class="contents notice"
              v-for="(item, index) in freeNTData"
              :key="`freeNT-${index}`"
            >
              <div class="num">공지</div>
              <div class="title" @click="goNoticeDetail(item.id)">
                <p>
                  {{ item.title }}
                </p>
                <!-- <span>{{ item.comment }}</span> -->
              </div>
              <div class="writer">{{ item.nickname }}</div>
              <div class="created_at">{{ item.created_date }}</div>
            </div>
            <div
              class="contents"
              v-for="(item, index) in freeCMData"
              :key="`freeCMData-${index}`"
            >
              <div class="num">{{ item.id }}</div>
              <div class="title" @click="goFreeDetail(item.id)">
                <p>
                  {{ item.title }}
                </p>
                <!-- <span>{{ item.comment }}</span> -->
              </div>
              <div class="writer">{{ item.nickname }}</div>
              <div class="created_at">{{ item.created_date }}</div>
            </div>
          </div>
          <div class="pagingBox">
            <div @click="lastGoPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style="display: flex; align-item: center; cursor: pointer"
              >
                <g
                  id="그룹_20908"
                  data-name="그룹 20908"
                  transform="translate(-825 -1333)"
                >
                  <rect
                    id="사각형_24385"
                    data-name="사각형 24385"
                    width="20"
                    height="20"
                    transform="translate(825 1333)"
                    fill="none"
                    opacity="0.5"
                  />
                  <g
                    id="그룹_19026"
                    data-name="그룹 19026"
                    transform="translate(-150 157)"
                  >
                    <path
                      id="패스_7802"
                      data-name="패스 7802"
                      d="M4,0,0,5l4,5"
                      transform="translate(981 1181)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                    <path
                      id="패스_7803"
                      data-name="패스 7803"
                      d="M4,0,0,5l4,5"
                      transform="translate(986 1181)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div @click="goPageBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style="display: flex; align-item: center; cursor: pointer"
              >
                <g
                  id="그룹_20907"
                  data-name="그룹 20907"
                  transform="translate(-845 -1333)"
                >
                  <rect
                    id="사각형_192"
                    data-name="사각형 192"
                    width="20"
                    height="20"
                    transform="translate(845 1333)"
                    fill="none"
                    opacity="0.5"
                  />
                  <path
                    id="패스_82"
                    data-name="패스 82"
                    d="M4,0,0,5l4,5"
                    transform="translate(853 1338.5)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </div>
            <div
              v-for="(item, index) in last_pageData[pageCount]"
              :key="index"
              @click="goPage(item)"
              class="pageNum"
              :class="{ nowPage_color: nowPage == item + 1 }"
            >
              <span id="">
                {{ item + 1 }}
              </span>
            </div>
            <div @click="backPageBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style="display: flex; align-item: center; cursor: pointer"
              >
                <g
                  id="그룹_20906"
                  data-name="그룹 20906"
                  transform="translate(-1105 -1333)"
                >
                  <rect
                    id="사각형_192"
                    data-name="사각형 192"
                    width="20"
                    height="20"
                    transform="translate(1125 1353) rotate(180)"
                    fill="none"
                    opacity="0.5"
                  />
                  <path
                    id="패스_82"
                    data-name="패스 82"
                    d="M4,0,0,5l4,5"
                    transform="translate(1117 1348.5) rotate(180)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </div>
            <div @click="lastBackPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style="display: flex; align-item: center; cursor: pointer"
              >
                <g
                  id="그룹_20905"
                  data-name="그룹 20905"
                  transform="translate(-1125 -1333)"
                >
                  <rect
                    id="사각형_24384"
                    data-name="사각형 24384"
                    width="20"
                    height="20"
                    transform="translate(1145 1353) rotate(180)"
                    fill="none"
                    opacity="0.5"
                  />
                  <g
                    id="그룹_19025"
                    data-name="그룹 19025"
                    transform="translate(-101 158)"
                  >
                    <path
                      id="패스_7804"
                      data-name="패스 7804"
                      d="M0,0,4,5,0,10"
                      transform="translate(1237 1180)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                    <path
                      id="패스_7805"
                      data-name="패스 7805"
                      d="M0,0,4,5,0,10"
                      transform="translate(1232 1180)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="searchBox">
            <select v-model="searchValue">
              <option value="0">제목</option>
              <option value="1">작성자</option>
              <option value="2">제목 + 작성자</option>
            </select>
            <input type="text" v-model="searchText" />
            <div @click="searchBtn">검색</div>
          </div>
        </div>
      </div>
      <div class="firePostBox">
        <h2>인기 게시글</h2>
        <div class="firePost" v-for="item in popularPost" :key="item.id">
          <p @click="goFreeDetail(item.id)">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import freeDetail from "./freeDetail.vue";
import NoticeDetail from "./noticeDetail.vue";
export default {
  components: { freeDetail, NoticeDetail },

  mounted() {
    let token = this.$cookie.get("access_token");
    let sessionToken = JSON.parse(sessionStorage.getItem("token"));
    if (sessionToken !== null || token !== null) {
      this.isLogin = true;
    } else if (sessionToken == null || token == null) {
      this.isLogin = false;
    }

    this.$axios
      .get(this.API_URL + "/community/free-board-page", {
        params: { page: this.$route.params.id },
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.$store.commit("set_freeCM", res.data.page_information);
          this.$store.commit("set_freeNT", res.data.top_fixed_information);
          this.lastBackPageNum = res.data.last_page;
          let i;
          for (i = 0; i < res.data.last_page; i++) {
            this.last_page.push(i);
          }
          for (i = 0; i < this.last_page.length; i += 10) {
            this.last_pageData.push(this.last_page.slice(i, i + 10));
          }
          for (i = 0; i < this.last_pageData.length; i++) {
            if (this.last_pageData[i].includes(this.nowPage - 1) == true) {
              this.pageCount = i;
            } else {
              true;
            }
          }
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.nowPage = this.$route.params.id;
    // this.$store.state.community.freeCMPaging.nowPage = this.$route.params.id;
  },
  data() {
    return {
      isLogin: false,
      last_page: [],
      last_pageData: [],
      pageCount: 0,
      nowPage: "",
      lastBackPageNum: "",
      searchValue: "0",
      searchText: "",
    };
  },
  computed: {
    freeCMData() {
      return this.$store.state.community.freeCMData;
    },
    freeNTData() {
      return this.$store.state.community.freeNTData;
    },
    freeDetailID() {
      return this.$store.state.community.freeDetail.postID;
    },
    noticeDetailID() {
      return this.$store.state.community.noticeDetail.postID;
    },
    popularPost() {
      return this.$store.state.community.popularPost;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    goFreeDetail(postNum) {
      this.$store.commit("set_noticeDel");
      this.$store.commit("set_freeDetailCMDel");
      this.$router.push({
        path: "/community/freeDetail/" + postNum,
      });
    },
    goNoticeDetail(postNum) {
      this.$store.commit("set_freeDel");
      this.$store.commit("set_noticeDetailCMDel");
      this.$router.push({
        path: "/community/noticeDetail/" + postNum,
      });
    },
    noticePage() {
      this.$store.commit("set_freeDel");
      this.$store.commit("set_noticeDel");
      this.$router.push({ path: "/community/mainpage/1" });
    },
    freePage() {
      this.$router.go();
    },
    InquirePage() {
      if (this.isLogin == false) {
        alert("로그인후 이용해주세요.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_freeDel");
        this.$store.commit("set_noticeDel");
        this.$router.push({ path: "/community/Inquirepage/1" });
      }
    },
    goWritePage() {
      if (this.isLogin == false) {
        alert("로그인후 이용해주세요.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_freeDel");
        this.$store.commit("set_noticeDel");
        this.$router.push({ path: "/community/writepage" });
      }
    },
    goMyContents() {
      if (this.isLogin == false) {
        alert("로그인후 이용해주세요.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_freeDel");
        this.$store.commit("set_noticeDel");
        this.$router.push({ path: "/community/myCMPage/1" });
      }
    },
    goPage(idx) {
      let num = idx + 1;
      this.$router.push({ path: "/community/freepage/" + num });
      this.$router.go();
    },
    lastGoPage() {
      this.$router.push({ path: "/community/freepage/1" });
      this.$router.go();
    },
    lastBackPage() {
      this.nowPage = this.lastBackPageNum;
      this.$router.push({ path: "/community/freepage/" + this.nowPage });
      this.$router.go();
    },
    goPageBtn() {
      let num = this.$route.params.id - 1;
      if (num == 0) {
        true;
      } else {
        this.$router.push({ path: "/community/freepage/" + num });
        this.$router.go();
      }
    },
    backPageBtn() {
      let postID = this.$route.params.id;
      let Num = Number(postID) + 1;
      let strPostID = String(Num);
      if (strPostID == this.lastBackPageNum + 1) {
        true;
      } else {
        this.$router.push({ path: "/community/freepage/" + strPostID });
        this.$router.go();
      }
    },
    searchBtn() {
      this.$store.commit("set_freeDel");
      this.$store.commit("set_noticeDel");
      this.last_page = [];
      this.last_pageData = [];
      this.lastBackPageNum = "";
      this.$axios
        .get(this.API_URL + "/community/free-search", {
          params: { keyword: this.searchText, type: this.searchValue },
        })
        .then((res) => {
          this.$store.commit("set_freeCM", res.data.page_information);
          this.$store.commit("set_freeNT", res.data.top_fixed_information);
          this.lastBackPageNum = res.data.last_page;
          let i;
          for (i = 0; i < res.data.last_page; i++) {
            this.last_page.push(i);
          }
          for (i = 0; i < this.last_page.length; i += 10) {
            this.last_pageData.push(this.last_page.slice(i, i + 10));
          }
          for (i = 0; i < this.last_pageData.length; i++) {
            if (this.last_pageData[i].includes(this.nowPage - 1) == true) {
              this.pageCount = i;
            } else {
              true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
select {
  width: 120px;
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
}
#freePage {
  margin-bottom: 100px;
  width: 1200px;
  display: flex;
  margin: 0 auto;
}
.main-navbox {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.main-navboxItem {
  width: 180px;
  height: 156px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.main-navboxItem span {
  margin-bottom: 12px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  cursor: pointer;
}
.main-navboxItem span:last-child {
  margin-bottom: 0;
}
#main-navboxItem_check {
  color: #8ca0ef;
}
.main-navbox h2 {
  font: normal normal bold 24px/35px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #191919;
  padding: 40px 0;
  padding-left: 30px;
}

.wrap {
  margin-top: 116px;
  display: flex;
  align-items: flex-start;
}

.main {
  min-width: 690px;
  min-height: 878px;
  max-width: 690px;
  max-height: 878px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 10px 30px;
  position: relative;
}
.nav {
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px 0;
}
#contentsBox {
  min-height: 680px;
}
.notice {
  background: #f0f5fe 0% 0% no-repeat padding-box;
}
.contents {
  display: flex;
  padding: 7px 0;
  border-bottom: 0.5px solid #d2d2d2;
}

.item1,
.item2,
.item3,
.item4,
.num,
.title,
.writer,
.created_at {
  text-align: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.item1,
.num {
  width: 80px;
  margin-right: 20px;
}
.item2,
.title {
  width: 340px;
  margin-right: 20px;
}
.item3,
.writer {
  width: 80px;
  margin-right: 10px;
}
.item4,
.created_at {
  width: 80px;
}
.num,
.title p,
.writer,
.created_at {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.title p {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title span {
  font: normal normal normal 10px/15px Noto Sans CJK KR;
  letter-spacing: -0.25px;
  color: #484848;
}
.writer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pagingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.pageNum {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nowPage_color {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: #ffffff;
}
.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.searchBox input {
  outline: none;
  border: none;
  width: 230px;
  height: 30px;
  border: 1px solid #dbdbdb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding-left: 10px;
}
.searchBox div {
  width: 78px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
}
.firePostBox {
  width: 282px;
  height: 346px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-left: 24px;
  padding: 20px;
}
.firePostBox h2 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost {
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 0;
  cursor: pointer;
}
.mycontentsBtn {
  width: 180px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;

  text-align: center;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
.goPageBtn {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;

  text-align: center;
  padding: 8px;
  cursor: pointer;
}
</style>
