//아가들
import pushArtist from "@/components/matching/pushArtist.vue";
import recommendView from "@/views/matching/recommendView.vue";

export default [
  {
    path: "/atmatchingview",
    name: "atmatchingview",
    component: () => import("../../views/matching/AtMatchingView.vue"),
    children: [
      {
        path: "pushartist",
        name: "pushartist",
        component: pushArtist,
      },
    ],
  },

  {
    path: "/recommendview/:id",
    name: "recommendview",
    component: recommendView,
  },
];
