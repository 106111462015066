<template>
  <div id="profileSetup">
    <div class="popup" v-if="popup">
      <div class="white-bg">
        <p>수정이 완료되었습니다.</p>
        <div class="popupBtn" @click="goHome">확인</div>
      </div>
    </div>
    <h2>회원정보 수정</h2>
    <div class="setupView">
      <h4>아이디</h4>
      <div class="setupViewBox">{{ userInfo.userID }}</div>
      <h4>
        비밀번호 <span>(영소 대문자와 숫자,특수문자 포함 8-12자 이내)</span>
      </h4>
      <input
        type="password"
        placeholder="비밀번호를 입력하세요."
        v-model="password"
      />
      <span class="errMessage" v-if="passWordErr"
        >비밀번호 형식이 맞지 않습니다.</span
      >
      <h4>
        비밀번호 확인
        <span>(영소 대문자와 숫자,특수문자 포함 8-12자 이내)</span>
      </h4>
      <input
        type="password"
        placeholder="비밀번호를 입력하세요."
        v-model="password2"
      />
      <span class="errMessage" v-if="passWord2Err"
        >입력한 비밀번호와 일치하지 않습니다.</span
      >
      <h4>닉네임</h4>
      <div class="setupViewBox">{{ userInfo.nickname }}</div>
      <h4>이름</h4>
      <div class="setupViewBox">{{ userInfo.name }}</div>
      <h4>생년월일</h4>
      <div class="setupViewBox">{{ userInfo.birth }}</div>
      <h4>이메일</h4>
      <input
        type="text"
        placeholder="asdfe@naver.com"
        v-model="userInfo.email"
      />
      <span class="errMessage" v-if="emailErr"
        >이메일 주소를 입력해주세요.</span
      >
      <h4>연락처</h4>
      <div class="setupViewBox">{{ userInfo.phone }}</div>
      <div class="gender">
        <div class="genderBox">
          <h4>성별</h4>
          <span>{{ userInfo.gender }}</span>
        </div>
        <div
          class="genderBox2"
          @click="userInfo.gender_disclose = !userInfo.gender_disclose"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            v-if="userInfo.gender_disclose == true"
          >
            <g
              id="그룹_20375"
              data-name="그룹 20375"
              transform="translate(-1079 -1114)"
            >
              <g
                id="타원_124"
                data-name="타원 124"
                transform="translate(1079 1114)"
                fill="#fff"
                stroke="#8ca0ef"
                stroke-width="1"
              >
                <circle cx="12" cy="12" r="12" stroke="none" />
                <circle cx="12" cy="12" r="11.5" fill="none" />
              </g>
              <circle
                id="타원_123"
                data-name="타원 123"
                cx="6"
                cy="6"
                r="6"
                transform="translate(1085 1120)"
                fill="none"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            v-if="userInfo.gender_disclose == false"
          >
            <g
              id="그룹_20374"
              data-name="그룹 20374"
              transform="translate(-1079 -1114)"
            >
              <g
                id="타원_124"
                data-name="타원 124"
                transform="translate(1079 1114)"
                fill="#fff"
                stroke="#8ca0ef"
                stroke-width="1"
              >
                <circle cx="12" cy="12" r="12" stroke="none" />
                <circle cx="12" cy="12" r="11.5" fill="none" />
              </g>
              <circle
                id="타원_123"
                data-name="타원 123"
                cx="6"
                cy="6"
                r="6"
                transform="translate(1085 1120)"
                fill="#8ca0ef"
              />
            </g>
          </svg>
          <span>비공개</span>
        </div>
      </div>
      <div class="endBtn" @click="setClear">완료</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profileSetup",
  data() {
    return {
      password: "",
      password2: "",
      email: "",
      privateBtn: false,
      popup: false,

      passWordErr: false,
      passWord2Err: false,
      emailErr: false,
    };
  },

  computed: {
    userInfo() {
      return this.$store.state.userSetup.userInfo;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    validationCheck() {
      this.passWordErr = false;
      this.passWord2Err = false;
      this.emailErr = false;
      // 비밀번호 유효성
      const regExpArr =
        /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
      // 이메일 유효성
      const regExp_email =
        /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
      if (!this.password) {
        this.passWordErr = true;
      } else if (!regExpArr.test(this.password)) {
        this.passWordErr = true;
      } else if (!this.password2) {
        this.passWord2Err = true;
      } else if (this.password2 !== this.password) {
        this.passWord2Err = true;
      } else if (!this.userInfo.email) {
        this.emailErr = true;
      } else if (!regExp_email.test(this.userInfo.email)) {
        this.emailErr = true;
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .patch(
              this.API_URL + "/accounts/user",
              {
                userID: token.userID,
                userPW: this.password,
                email: this.userInfo.email,
                gender_disclose: this.userInfo.gender_disclose,
              },
              {
                headers: { Authorization: `Bearer ${token.access_token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                document.getElementsByTagName("body")[0].style.overflow =
                  "hidden";
                this.popup = true;
              } else if (res.data.status == 400) {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .patch(
              this.API_URL + "/accounts/user",
              {
                userID: userID,
                userPW: this.password,
                email: this.userInfo.email,
                gender_disclose: this.userInfo.gender_disclose,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                document.getElementsByTagName("body")[0].style.overflow =
                  "hidden";
                this.popup = true;
              } else if (res.data.status == 400) {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    setClear() {
      this.validationCheck();
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#profileSetup {
  width: 500px;
  min-height: 1042px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 96px 44px;
  text-align: center;
  padding: 0 58px;
}
#profileSetup h2 {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
  padding: 40px 0;
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 10;
}
.white-bg {
  width: 500px;
  height: 224px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin: 400px auto;
  text-align: center;
}

.white-bg p {
  padding: 30px 0;
  padding-top: 70px;
  font: normal normal normal 18px/30px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
}
.popupBtn {
  width: 100px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;
  font: normal normal normal 16px/30px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
}
.setupView {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.setupView h4 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.setupView h4 > span {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #707070;
}
.setupView input {
  outline: none;
  border: none;
  border-bottom: 1px solid #707070;
  width: 384px;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 20px;
}
.setupView input::placeholder {
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #dbdbdb;
}
.setupViewBox {
  width: 384px;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #707070;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
  padding-top: 12px;
  margin-bottom: 20px;
}
.gender {
  display: flex;
  justify-content: space-between;
}
.genderBox,
.genderBox2 {
  display: flex;
  align-items: center;
}
.genderBox h4 {
  margin-right: 40px;
}
.genderBox span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
.genderBox2 span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  margin-left: 6px;
}
.endBtn {
  width: 384px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #ffffff;
  margin: 60px 0;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
}
.errMessage {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #cf1f1f;
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
