<template>
  <div>
    <nav-bar></nav-bar>
    <scrapLoad v-if="scrap_loadview"></scrapLoad>
    <scrapSave v-if="scrap_saveview"></scrapSave>
    <div id="recommendView">
      <h2 class="title">SEARCHING</h2>
      <div class="wrap">
        <div class="contentsBox">
          <div class="searchBox">
            <div class="searchBox-header">
              <h2>검색 필터</h2>
              <div @click="resetBtn" style="cursor: pointer">초기화</div>
            </div>
            <div class="fieldSelect">
              <h5>분야</h5>
              <select
                v-model="search_data.key"
                @change="fieldSelect()"
                class="fieldBox"
              >
                <option value="music" selected>음악</option>
                <option value="video">영상</option>
                <option value="photography">사진</option>
                <option value="design">디자인</option>
                <option value="specialist">스페셜리스트</option>
              </select>
            </div>
            <div class="roleSelect">
              <h5>역할</h5>
              <select v-model="search_data.values" class="roleBox">
                <option
                  v-for="(role, value) in roleList"
                  :value="role.value"
                  v-bind:key="value"
                >
                  {{ role.text }}
                </option>
              </select>
            </div>

            <div class="gender">
              <h5>성별</h5>
              <div class="genderBox" @click="genderM">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_M == false"
                >
                  <g
                    id="그룹_19660"
                    data-name="그룹 19660"
                    transform="translate(-778.5 -685)"
                  >
                    <rect
                      id="사각형_24945"
                      data-name="사각형 24945"
                      width="30"
                      height="30"
                      transform="translate(778.5 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8060"
                      data-name="패스 8060"
                      d="M799.5,691a2.946,2.946,0,0,1,3,3v12a2.946,2.946,0,0,1-3,3h-12a2.946,2.946,0,0,1-3-3V694a2.946,2.946,0,0,1,3-3h12m0-1h-12a4.012,4.012,0,0,0-4,4v12a4.012,4.012,0,0,0,4,4h12a4.012,4.012,0,0,0,4-4V694a4.012,4.012,0,0,0-4-4Z"
                      fill="#707070"
                      opacity="0.5"
                    />
                    <path
                      id="_패스_7618-2-5"
                      d="M786.5,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#707070"
                      stroke-width="2"
                      opacity="0.5"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_M == true"
                >
                  <g
                    id="그룹_19659"
                    data-name="그룹 19659"
                    transform="translate(-820.4 -685)"
                  >
                    <rect
                      id="사각형_24944"
                      data-name="사각형 24944"
                      width="30"
                      height="30"
                      transform="translate(820.4 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8059"
                      data-name="패스 8059"
                      d="M829.4,690h12a4.012,4.012,0,0,1,4,4v12a4.012,4.012,0,0,1-4,4h-12a4.012,4.012,0,0,1-4-4V694A4.012,4.012,0,0,1,829.4,690Z"
                      fill="#8ca0ef"
                    />
                    <path
                      id="_패스_7618-2-4"
                      d="M828.4,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                남자
              </div>
              <div class="genderBox" @click="genderW">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_W == false"
                >
                  <g
                    id="그룹_19660"
                    data-name="그룹 19660"
                    transform="translate(-778.5 -685)"
                  >
                    <rect
                      id="사각형_24945"
                      data-name="사각형 24945"
                      width="30"
                      height="30"
                      transform="translate(778.5 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8060"
                      data-name="패스 8060"
                      d="M799.5,691a2.946,2.946,0,0,1,3,3v12a2.946,2.946,0,0,1-3,3h-12a2.946,2.946,0,0,1-3-3V694a2.946,2.946,0,0,1,3-3h12m0-1h-12a4.012,4.012,0,0,0-4,4v12a4.012,4.012,0,0,0,4,4h12a4.012,4.012,0,0,0,4-4V694a4.012,4.012,0,0,0-4-4Z"
                      fill="#707070"
                      opacity="0.5"
                    />
                    <path
                      id="_패스_7618-2-5"
                      d="M786.5,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#707070"
                      stroke-width="2"
                      opacity="0.5"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_W == true"
                >
                  <g
                    id="그룹_19659"
                    data-name="그룹 19659"
                    transform="translate(-820.4 -685)"
                  >
                    <rect
                      id="사각형_24944"
                      data-name="사각형 24944"
                      width="30"
                      height="30"
                      transform="translate(820.4 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8059"
                      data-name="패스 8059"
                      d="M829.4,690h12a4.012,4.012,0,0,1,4,4v12a4.012,4.012,0,0,1-4,4h-12a4.012,4.012,0,0,1-4-4V694A4.012,4.012,0,0,1,829.4,690Z"
                      fill="#8ca0ef"
                    />
                    <path
                      id="_패스_7618-2-4"
                      d="M828.4,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                여자
              </div>
              <div class="genderBox" @click="genderNone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_none == false"
                >
                  <g
                    id="그룹_19660"
                    data-name="그룹 19660"
                    transform="translate(-778.5 -685)"
                  >
                    <rect
                      id="사각형_24945"
                      data-name="사각형 24945"
                      width="30"
                      height="30"
                      transform="translate(778.5 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8060"
                      data-name="패스 8060"
                      d="M799.5,691a2.946,2.946,0,0,1,3,3v12a2.946,2.946,0,0,1-3,3h-12a2.946,2.946,0,0,1-3-3V694a2.946,2.946,0,0,1,3-3h12m0-1h-12a4.012,4.012,0,0,0-4,4v12a4.012,4.012,0,0,0,4,4h12a4.012,4.012,0,0,0,4-4V694a4.012,4.012,0,0,0-4-4Z"
                      fill="#707070"
                      opacity="0.5"
                    />
                    <path
                      id="_패스_7618-2-5"
                      d="M786.5,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#707070"
                      stroke-width="2"
                      opacity="0.5"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="gender_none == true"
                >
                  <g
                    id="그룹_19659"
                    data-name="그룹 19659"
                    transform="translate(-820.4 -685)"
                  >
                    <rect
                      id="사각형_24944"
                      data-name="사각형 24944"
                      width="30"
                      height="30"
                      transform="translate(820.4 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8059"
                      data-name="패스 8059"
                      d="M829.4,690h12a4.012,4.012,0,0,1,4,4v12a4.012,4.012,0,0,1-4,4h-12a4.012,4.012,0,0,1-4-4V694A4.012,4.012,0,0,1,829.4,690Z"
                      fill="#8ca0ef"
                    />
                    <path
                      id="_패스_7618-2-4"
                      d="M828.4,699.9l4.7,4.1,9.3-8"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                상관없음
              </div>
            </div>
            <div class="keyword">
              <h5>키워드</h5>
              <div>
                <span v-if="search_data.keywords.length == 0"></span>
                <span v-if="search_data.keywords.length == 1"
                  >#{{ search_data.keywords[0] }}</span
                >
                <span v-if="search_data.keywords.length == 2"
                  >#{{ search_data.keywords[0] }},#{{
                    search_data.keywords[1]
                  }}</span
                ><span v-if="search_data.keywords.length == 3"
                  >#{{ search_data.keywords[0] }},#{{
                    search_data.keywords[1]
                  }},#{{ search_data.keywords[2] }}</span
                >
                <span v-if="search_data.keywords.length > 3"
                  >#{{ search_data.keywords[0] }},#{{
                    search_data.keywords[1]
                  }},#{{ search_data.keywords[2] }}...</span
                >
                <svg
                  id="그룹_21275"
                  data-name="그룹 21275"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  @click="keywordBox_onoffBtn"
                >
                  <rect
                    id="사각형_192"
                    data-name="사각형 192"
                    width="20"
                    height="20"
                    transform="translate(0 20) rotate(-90)"
                    fill="none"
                    opacity="0.5"
                  />
                  <path
                    id="패스_82"
                    data-name="패스 82"
                    d="M7.452,0,0,7.452,7.452,14.9"
                    transform="translate(2.862 14.004) rotate(-90)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <div class="keywordInput">
              <div
                class="keywordInput-music"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.search_data.key == 'music'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21128"
                    data-name="그룹 21128"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26095"
                          data-name="사각형 26095"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21127"
                      data-name="그룹 21127"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8308"
                        data-name="패스 8308"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8309"
                        data-name="패스 8309"
                        d="M6,20.5A2.5,2.5,0,1,1,8.5,18,2.5,2.5,0,0,1,6,20.5"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8310"
                        data-name="패스 8310"
                        d="M6,16a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8311"
                        data-name="패스 8311"
                        d="M16,18.5A2.5,2.5,0,1,1,18.5,16,2.5,2.5,0,0,1,16,18.5"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8312"
                        data-name="패스 8312"
                        d="M16,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8313"
                        data-name="패스 8313"
                        d="M8,18V7.231L18,4V15.846"
                        fill="none"
                        stroke="#8ca0ef"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                  <span>음악</span>
                </div>
                <div class="music_box">
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-발라드"
                    value="발라드"
                  />
                  <label for="음악-발라드"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-알앤비"
                    value="알앤비"
                  />
                  <label for="음악-알앤비"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-팝"
                    value="팝"
                  />
                  <label for="음악-팝"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-어쿠스틱"
                    value="어쿠스틱"
                  />
                  <label for="음악-어쿠스틱"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-힙합"
                    value="힙합"
                  />
                  <label for="음악-힙합"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-재즈"
                    value="재즈"
                  />
                  <label for="음악-재즈"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-모던락"
                    value="모던락"
                  />
                  <label for="음악-모던락"></label>

                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-k-pop"
                    value="k-pop"
                  />
                  <label for="음악-k-pop"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-댄스"
                    value="댄스"
                  />
                  <label for="음악-댄스"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="음악-디스코"
                    value="디스코"
                  />
                  <label for="음악-디스코"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-video"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.search_data.key == 'video'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21130"
                    data-name="그룹 21130"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26097"
                          data-name="사각형 26097"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21129"
                      data-name="그룹 21129"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8314"
                        data-name="패스 8314"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <rect
                        id="사각형_26096"
                        data-name="사각형 26096"
                        width="12"
                        height="7"
                        rx="0.5"
                        transform="translate(4.5 8.5)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8315"
                        data-name="패스 8315"
                        d="M16,15H5V9H16Zm0-7H5A1,1,0,0,0,4,9v6a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8316"
                        data-name="패스 8316"
                        d="M20,9l-3,3,3,3Z"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8317"
                        data-name="패스 8317"
                        d="M20,9l-3,3,3,3Z"
                        fill="none"
                        stroke="#8ca0ef"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                  <span>영상</span>
                </div>
                <div class="video_box">
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-액션"
                    value="액션"
                  />
                  <label for="영상-액션"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-코미디"
                    value="코미디"
                  />
                  <label for="영상-코미디"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-범죄"
                    value="범죄"
                  />
                  <label for="영상-범죄"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-공포"
                    value="공포"
                  />
                  <label for="영상-공포"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-로맨스"
                    value="로맨스"
                  />
                  <label for="영상-로맨스"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-모험"
                    value="모험"
                  />
                  <label for="영상-모험"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-스릴러"
                    value="스릴러"
                  />
                  <label for="영상-스릴러"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-다큐"
                    value="다큐"
                  />
                  <label for="영상-다큐"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-SF"
                    value="SF"
                  />
                  <label for="영상-SF"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-드라마"
                    value="드라마"
                  />
                  <label for="영상-드라마"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-가족"
                    value="가족"
                  />
                  <label for="영상-가족"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-판타지"
                    value="판타지"
                  />
                  <label for="영상-판타지"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-서스펜스"
                    value="서스펜스"
                  />
                  <label for="영상-서스펜스"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-웹드라마"
                    value="웹드라마"
                  />
                  <label for="영상-웹드라마"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-필름룩스"
                    value="필름룩스"
                  />
                  <label for="영상-필름룩스"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-컴퓨터그래픽"
                    value="컴퓨터그래픽"
                  />
                  <label for="영상-컴퓨터그래픽"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-애니메이션"
                    value="애니메이션"
                  />
                  <label for="영상-애니메이션"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-독립영화"
                    value="독립영화"
                  />
                  <label for="영상-독립영화"></label>

                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-유튜브"
                    value="유튜브"
                  />
                  <label for="영상-유튜브"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="영상-코레오"
                    value="코레오"
                  />
                  <label for="영상-코레오"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-photo"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.search_data.key == 'photography'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21132"
                    data-name="그룹 21132"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26098"
                          data-name="사각형 26098"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21131"
                      data-name="그룹 21131"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8318"
                        data-name="패스 8318"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8319"
                        data-name="패스 8319"
                        d="M19.559,19h-14V5h14Zm0-15h-14a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h14a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8320"
                        data-name="패스 8320"
                        d="M13.559,9.5a1.5,1.5,0,1,1-1.5-1.5,1.5,1.5,0,0,1,1.5,1.5"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8321"
                        data-name="패스 8321"
                        d="M19.559,14v5h-14V17L7.9,13.1a1,1,0,0,1,1.565-.192L13.059,16.5l2.831-4.448a1,1,0,0,1,1.55-.171Z"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                    </g>
                  </svg>
                  <span>사진</span>
                </div>
                <div class="photo_box">
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-차가운"
                    value="차가운"
                  />
                  <label for="사진-차가운"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-따뜻한"
                    value="따뜻한"
                  />
                  <label for="사진-따뜻한"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-필름"
                    value="필름"
                  />
                  <label for="사진-필름"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-건물"
                    value="건물"
                  />
                  <label for="사진-건물"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-앨범"
                    value="앨범"
                  />
                  <label for="사진-앨범"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-아트워크"
                    value="아트워크"
                  />
                  <label for="사진-아트워크"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-예술"
                    value="예술"
                  />
                  <label for="사진-예술"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-누드"
                    value="누드"
                  />
                  <label for="사진-누드"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-모던"
                    value="모던"
                  />
                  <label for="사진-모던"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-자연"
                    value="자연"
                  />
                  <label for="사진-자연"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="사진-코스프레"
                    value="코스프레"
                  />
                  <label for="사진-코스프레"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-design"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.search_data.key == 'design'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21138"
                    data-name="그룹 21138"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26101"
                          data-name="사각형 26101"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect
                          id="사각형_26100"
                          data-name="사각형 26100"
                          width="11.484"
                          height="15.504"
                          transform="translate(10.115 5.557)"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21137"
                      data-name="그룹 21137"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8322"
                        data-name="패스 8322"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8323"
                        data-name="패스 8323"
                        d="M7.864,4.575C4.454,5.968,3.116,10.588,4.509,14a6.633,6.633,0,0,0,.733,1.337.771.771,0,0,0,1.349-.182,2.352,2.352,0,0,1,1.944-1.422,2.3,2.3,0,0,1,2.042.857,2.351,2.351,0,0,1,.346,2.384.771.771,0,0,0,.814,1.091,6.735,6.735,0,0,0,1.469-.413c3.41-1.393,5.771-5.584,4.378-8.994s-6.31-5.474-9.72-4.081"
                        fill="#8ca0ef"
                      />
                      <g id="그룹_21136" data-name="그룹 21136">
                        <g
                          id="그룹_21135"
                          data-name="그룹 21135"
                          clip-path="url(#clip-path-2)"
                        >
                          <g id="그룹_21134" data-name="그룹 21134">
                            <g
                              id="그룹_21133"
                              data-name="그룹 21133"
                              clip-path="url(#clip-path-2)"
                            >
                              <path
                                id="패스_8324"
                                data-name="패스 8324"
                                d="M21.592,6.182c.186,1.5-3.182,6.606-5.512,9.351a.288.288,0,0,1,.039.352l-1.108,1.576a.268.268,0,0,1-.283.084A4.461,4.461,0,0,1,13.681,19.5,7.683,7.683,0,0,1,10.5,21.049a.319.319,0,0,1-.262-.037l-.047-.048a.379.379,0,0,1-.064-.338l.436-1.933a4.087,4.087,0,0,1,1.894-2.7.31.31,0,0,1-.07-.383L13.5,14.029a.28.28,0,0,1,.272-.086c1.789-3.109,5.466-8.037,6.96-8.362a.7.7,0,0,1,.582.093l.006.012a.7.7,0,0,1,.275.5M15.5,15.093c2.907-3.415,5.575-7.988,5.369-8.809-.835.1-4.3,4.218-6.477,8.03l0,.009L15.5,15.1Zm-.913,1.717.782-1.117-1.441-1.009L13.137,15.8Zm-.6.537a.555.555,0,0,1,.08-.2l-.963-.677a.661.661,0,0,1-.232.185,2.777,2.777,0,0,0-1.525,2.026l-.3,1.355A5.689,5.689,0,0,0,13.2,18.958a3.524,3.524,0,0,0,.792-1.611"
                                fill="#fff"
                              />
                              <path
                                id="패스_8325"
                                data-name="패스 8325"
                                d="M20.871,6.284c.2.82-2.462,5.394-5.369,8.809l0,.009-1.107-.779,0-.009c2.175-3.812,5.641-7.934,6.477-8.03"
                                fill="#8ca0ef"
                              />
                              <path
                                id="패스_8326"
                                data-name="패스 8326"
                                d="M15.371,15.693l-.782,1.117L13.138,15.8l.791-1.114Z"
                                fill="#8ca0ef"
                              />
                              <path
                                id="패스_8327"
                                data-name="패스 8327"
                                d="M14.074,17.142a.539.539,0,0,0-.08.2,3.548,3.548,0,0,1-.792,1.611,5.7,5.7,0,0,1-2.152,1.073l.3-1.355a2.78,2.78,0,0,1,1.526-2.026.67.67,0,0,0,.232-.185Z"
                                fill="#8ca0ef"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <path
                        id="패스_8328"
                        data-name="패스 8328"
                        d="M6,10.193a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8329"
                        data-name="패스 8329"
                        d="M6,8.693a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8330"
                        data-name="패스 8330"
                        d="M8.646,7.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8331"
                        data-name="패스 8331"
                        d="M8.647,6.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8332"
                        data-name="패스 8332"
                        d="M12,7.054a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8333"
                        data-name="패스 8333"
                        d="M12,5.554a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8334"
                        data-name="패스 8334"
                        d="M14.389,9.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8335"
                        data-name="패스 8335"
                        d="M14.389,8.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8336"
                        data-name="패스 8336"
                        d="M10.239,12.4a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8337"
                        data-name="패스 8337"
                        d="M10.239,10.9a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <span>디자인</span>
                </div>
                <div class="design_box">
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-드로잉"
                    value="드로잉"
                  />
                  <label for="디자인-드로잉"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-회화"
                    value="회화"
                  />
                  <label for="디자인-회화"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-콜라주"
                    value="콜라주"
                  />
                  <label for="디자인-콜라주"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-손그림"
                    value="손그림"
                  />
                  <label for="디자인-손그림"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-3D"
                    value="3D"
                  />
                  <label for="디자인-3D"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-목업디자인"
                    value="목업디자인"
                  />
                  <label for="디자인-목업디자인"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-모델링"
                    value="모델링"
                  />
                  <label for="디자인-모델링"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-브랜딩"
                    value="브랜딩"
                  />
                  <label for="디자인-브랜딩"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-키치"
                    value="키치"
                  />
                  <label for="디자인-키치"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-포토그래피"
                    value="포토그래피"
                  />
                  <label for="디자인-포토그래피"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="디자인-패키지"
                    value="패키지"
                  />
                  <label for="디자인-패키지"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-specialist"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.search_data.key == 'specialist'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="그룹_21244"
                      data-name="그룹 21244"
                      transform="translate(-740 -1401)"
                    >
                      <g
                        id="사각형_26093"
                        data-name="사각형 26093"
                        transform="translate(740 1401)"
                        fill="#fff"
                        stroke="#dbdbdb"
                        stroke-width="1"
                      >
                        <rect width="24" height="24" rx="4" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="3.5"
                          fill="none"
                        />
                      </g>
                      <rect
                        id="사각형_26094"
                        data-name="사각형 26094"
                        width="18"
                        height="18"
                        transform="translate(743.001 1404)"
                        fill="#fff"
                      />
                      <path
                        id="패스_8307"
                        data-name="패스 8307"
                        d="M336.394-159.375a4.363,4.363,0,0,0,4.364-4.364,4.363,4.363,0,0,0-4.364-4.364,4.363,4.363,0,0,0-4.364,4.364A4.363,4.363,0,0,0,336.394-159.375Zm0,2.182c-2.913,0-8.727,1.462-8.727,4.364v2.182h17.455v-2.182C345.122-155.731,339.307-157.193,336.394-157.193Z"
                        transform="translate(415.605 1572.375)"
                        fill="#8ca0ef"
                      />
                    </g>
                  </svg>

                  <span>스페셜리스트</span>
                </div>
                <div class="specialist_box">
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-드라마"
                    value="드라마"
                  />
                  <label for="스페셜리스트-드라마"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-영화"
                    value="영화"
                  />
                  <label for="스페셜리스트-영화"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-연극"
                    value="연극"
                  />
                  <label for="스페셜리스트-연극"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-뷰티"
                    value="뷰티"
                  />
                  <label for="스페셜리스트-뷰티"></label>

                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-패션"
                    value="패션"
                  />
                  <label for="스페셜리스트-패션"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-안무"
                    value="안무"
                  />
                  <label for="스페셜리스트-안무"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-브이로그"
                    value="브이로그"
                  />
                  <label for="스페셜리스트-브이로그"></label>
                  <input
                    type="checkbox"
                    v-model="search_data.keywords"
                    id="스페셜리스트-코스프레"
                    value="코스프레"
                  />
                  <label for="스페셜리스트-코스프레"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
            </div>
            <div class="searchBtn" @click="dosearch">검색</div>
          </div>
          <div id="scrap">
            <div class="scrap-header">
              <h2>스크랩</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                @mouseover="scrap_askBox = true"
                @mouseleave="scrap_askBox = false"
              >
                <g
                  id="그룹_19687"
                  data-name="그룹 19687"
                  transform="translate(-498 -683)"
                >
                  <circle
                    id="타원_194"
                    data-name="타원 194"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(498 683)"
                    fill="#dbdbdb"
                  />
                  <text
                    id="_"
                    data-name="?"
                    transform="translate(503.923 697)"
                    fill="#fff"
                    font-size="12"
                    font-family="NotoSansCJKkr-Regular, Noto Sans CJK KR"
                    letter-spacing="-0.025em"
                  >
                    <tspan x="0" y="0">?</tspan>
                  </text>
                </g>
              </svg>
              <div class="scrap_askBox" v-show="scrap_askBox">
                스크랩한 아티스트들의 목록을 저장할 수 있습니다.
                <span
                  >※ 이 페이지를 벗어나면<br />해당 스크랩 내용은 사라집니다.<br />목록을
                  보관하고 싶다면 저장 버튼으로<br />아티스트 목록을
                  저장하세요.</span
                >
              </div>
              <div class="load_name" v-show="scrap_loadName !== ''">
                {{ scrap_loadName }}
              </div>
            </div>
            <div class="noscrap" v-if="scrapAT == ''">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="324"
                height="340"
                viewBox="0 0 324 340"
              >
                <g
                  id="사각형_25419"
                  data-name="사각형 25419"
                  fill="#fff"
                  stroke="#707070"
                  stroke-width="1"
                  stroke-dasharray="10 10"
                >
                  <rect width="324" height="340" stroke="none" />
                  <rect x="0.5" y="0.5" width="323" height="339" fill="none" />
                </g>
              </svg>
              <p>
                기존에 스크랩한 아티스트를 불러오거나<br />
                관심있는 아티스트를 스크랩하세요.
              </p>
              <div @click="scrap_loadBtn">불러오기</div>
            </div>
            <div>
              <div class="scrap-wrap" v-if="scrapAT !== ''">
                <div
                  v-for="(item, index) in scrapAT"
                  :key="index"
                  class="scrapUser"
                >
                  <div class="scrap-userInfo">
                    <img
                      :src="item.profile_image"
                      alt=""
                      @click="goOtherPF(item.userID)"
                      style="cursor: pointer"
                    />
                    <div class="scrap-userName">
                      <h4
                        @click="goOtherPF(item.userID)"
                        style="cursor: pointer"
                      >
                        {{ item.nickname }}
                      </h4>
                      <span>{{ item.main_field }} / {{ item.main_role }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        @click="deleteScrap(item, index)"
                      >
                        <g
                          id="그룹_19692"
                          data-name="그룹 19692"
                          transform="translate(-166 -529)"
                        >
                          <rect
                            id="사각형_24934"
                            data-name="사각형 24934"
                            width="20"
                            height="20"
                            transform="translate(166 529)"
                            fill="none"
                          />
                          <g
                            id="그룹_19689"
                            data-name="그룹 19689"
                            transform="translate(-2 -2)"
                          >
                            <line
                              id="_선_81"
                              x1="10"
                              y2="10"
                              transform="translate(173 536)"
                              fill="none"
                              stroke="#707070"
                              stroke-width="2"
                            />
                            <line
                              id="_선_82"
                              x1="10"
                              y1="10"
                              transform="translate(173 536)"
                              fill="none"
                              stroke="#707070"
                              stroke-width="2"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scrap_box-btns" v-if="scrap_Btns == true">
                <div @click="scrap_saveBtn">저장</div>
                <div @click="scrap_loadBtn">불러오기</div>
              </div>
            </div>
          </div>
        </div>
        <div class="contentsBox">
          <div
            id="vipArtist"
            v-if="Object.keys(this.recommend_information).length == 0"
          >
            <div class="vip-header">
              <h2>RUBATO 추천 아티스트</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                @mouseover="vip_askBox = true"
                @mouseleave="vip_askBox = false"
                style="margin-left: 10px"
              >
                <g
                  id="그룹_19687"
                  data-name="그룹 19687"
                  transform="translate(-498 -683)"
                >
                  <circle
                    id="타원_194"
                    data-name="타원 194"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(498 683)"
                    fill="#dbdbdb"
                  />
                  <text
                    id="_"
                    data-name="?"
                    transform="translate(503.923 697)"
                    fill="#fff"
                    font-size="12"
                    font-family="NotoSansCJKkr-Regular, Noto Sans CJK KR"
                    letter-spacing="-0.025em"
                  >
                    <tspan x="0" y="0">?</tspan>
                  </text>
                </g>
              </svg>
              <div class="vip-askBox" v-show="vip_askBox">
                루바토가 추천 해드릴만한 아티스트들을 보여드려요!
              </div>
            </div>
            <div class="noVipMent">
              찾으시는 키워드를 포함한 아티스트가 아직 없어요 : (
            </div>
          </div>
          <div
            id="vipArtist"
            v-if="Object.keys(this.recommend_information).length !== 0"
          >
            <div class="vip-header">
              <h2 class="title">RUBATO 추천 아티스트</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                @mouseover="vip_askBox = true"
                @mouseleave="vip_askBox = false"
              >
                <g
                  id="그룹_19687"
                  data-name="그룹 19687"
                  transform="translate(-498 -683)"
                >
                  <circle
                    id="타원_194"
                    data-name="타원 194"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(498 683)"
                    fill="#dbdbdb"
                  />
                  <text
                    id="_"
                    data-name="?"
                    transform="translate(503.923 697)"
                    fill="#fff"
                    font-size="12"
                    font-family="NotoSansCJKkr-Regular, Noto Sans CJK KR"
                    letter-spacing="-0.025em"
                  >
                    <tspan x="0" y="0">?</tspan>
                  </text>
                </g>
              </svg>
              <div class="vip-askBox" v-show="vip_askBox">
                루바토가 추천 해드릴만한 아티스트들을 보여드려요!
              </div>
            </div>
            <div class="vip-contentsBox">
              <div
                class="vip-content"
                v-for="(item, index) in recommend_information"
                :key="index"
              >
                <div class="vip-imgBox">
                  <img :src="item.thumbnail[0]" alt="" />
                  <img :src="item.thumbnail[1]" alt="" />
                </div>
                <div class="vip-userInfoBox">
                  <img
                    :src="item.profile_image"
                    alt=""
                    @click="goOtherPF(item.userID)"
                  />
                  <div class="vip-userInfo">
                    <h4 @click="goOtherPF(item.userID)">{{ item.nickname }}</h4>
                    <div>{{ item.main_field }} / {{ item.main_role }}</div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    @click="doscrapBtn(item, index)"
                    v-if="item.my_scrap == false"
                  >
                    <g
                      id="그룹_20144"
                      data-name="그룹 20144"
                      transform="translate(-220 -529)"
                    >
                      <rect
                        id="사각형_24941"
                        data-name="사각형 24941"
                        width="30"
                        height="30"
                        transform="translate(220 529)"
                        fill="none"
                      />
                      <path
                        id="패스_8056"
                        data-name="패스 8056"
                        d="M243.9,542.9h-7.8v-7.8a1.1,1.1,0,0,0-2.2,0v7.8h-7.8a1.1,1.1,0,1,0,0,2.2h7.8v7.8a1.1,1.1,0,0,0,2.2,0v-7.8h7.8a1.1,1.1,0,1,0,0-2.2Z"
                        fill="#707070"
                      />
                    </g>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    @click="dounscrapBtn(item, index)"
                    v-else-if="item.my_scrap == true"
                  >
                    <g
                      id="그룹_21055"
                      data-name="그룹 21055"
                      transform="translate(-1476 -628)"
                    >
                      <rect
                        id="사각형_25507"
                        data-name="사각형 25507"
                        width="30"
                        height="30"
                        transform="translate(1476 628)"
                        fill="none"
                      />
                      <path
                        id="패스_8192"
                        data-name="패스 8192"
                        d="M-12275.829-2122.2l5.854,5.794,14.146-14"
                        transform="translate(13757.329 2766.902)"
                        fill="none"
                        stroke="#8ca0ef"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
                <div class="vip-results">
                  <div
                    v-if="item.result.length == 1"
                    @mouseover="doViphover(index)"
                    @mouseout="doViphover(index)"
                  >
                    #{{ item.result[0] }}
                  </div>
                  <div
                    v-if="item.result.length == 2"
                    @mouseover="doViphover(index)"
                    @mouseout="doViphover(index)"
                  >
                    #{{ item.result[0] }}, #{{ item.result[1] }}
                  </div>
                  <div
                    v-if="item.result.length == 3"
                    @mouseover="doViphover(index)"
                    @mouseout="doViphover(index)"
                  >
                    #{{ item.result[0] }}, #{{ item.result[1] }}, #{{
                      item.result[2]
                    }}
                  </div>
                  <div
                    v-if="item.result.length > 3"
                    @mouseover="doViphover(index)"
                    @mouseout="doViphover(index)"
                  >
                    #{{ item.result[0] }}, #{{ item.result[1] }}, #{{
                      item.result[2]
                    }}...
                  </div>
                  <transition name="fade" mode="out-in">
                    <div
                      id="vip-resultsHover"
                      v-show="vip_hover_index[index].active"
                    >
                      <span v-for="item in item.result" :key="item"
                        >#{{ item }}</span
                      >
                    </div>
                  </transition>
                  <!-- <div v-for="item in item.result" :key="item">#{{ item }}</div> -->
                </div>
              </div>
            </div>
          </div>
          <div
            id="recommendAT"
            v-if="Object.keys(this.search_result).length == 0"
          >
            <h2>검색결과</h2>
            <div class="noVipMent">
              찾으시는 키워드를 포함한 아티스트가 아직 없어요 : (
            </div>
          </div>
          <div
            id="recommendAT"
            v-if="Object.keys(this.search_result).length !== 0"
          >
            <h2>검색결과</h2>
            <div class="nav">
              <div class="item1">아이디</div>
              <div class="item3">분야</div>
              <div class="item4">소개</div>
              <div class="item5">키워드</div>
              <div class="item6">스크랩</div>
            </div>
            <div
              class="RCAT_contents"
              v-for="(item, index) in search_result"
              :key="index"
            >
              <div class="name">
                <img
                  :src="item.profile_image"
                  alt=""
                  @click="goOtherPF(item.userID)"
                />
                <span @click="goOtherPF(item.userID)">{{ item.nickname }}</span>
              </div>
              <div class="role">{{ item.main_role }}</div>
              <div class="ment" v-html="item.hello"></div>
              <div class="similarity">
                <div
                  class="resultBox"
                  v-if="item.result.length == 1"
                  @mouseover="dohover(index)"
                  @mouseout="dohover(index)"
                >
                  #{{ item.result[0] }}
                </div>
                <div
                  class="resultBox"
                  v-else-if="item.result.length == 2"
                  @mouseover="dohover(index)"
                  @mouseout="dohover(index)"
                >
                  #{{ item.result[0] }}&nbsp;&nbsp;#{{ item.result[1] }}
                </div>
                <div
                  class="resultBox"
                  v-else-if="(item.result.length = 3)"
                  @mouseover="dohover(index)"
                  @mouseout="dohover(index)"
                >
                  #{{ item.result[0] }}&nbsp;&nbsp;#{{
                    item.result[1]
                  }}&nbsp;&nbsp;#{{ item.result[2] }}
                </div>
                <div
                  class="resultBox"
                  v-else-if="item.result.length > 3"
                  @mouseover="dohover(index)"
                  @mouseout="dohover(index)"
                >
                  #{{ item.result[0] }}&nbsp;&nbsp;#{{
                    item.result[1]
                  }}&nbsp;&nbsp;#{{ item.result[2] }}...
                </div>
                <transition name="fade" mode="out-in">
                  <div class="moreResultBox" v-show="hover_index[index].active">
                    <span v-for="item in item.result" :key="item"
                      >#{{ item }}</span
                    >
                  </div>
                </transition>
              </div>
              <div class="RCAT_scrapBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  v-if="item.my_scrap == false"
                  @click="doscrapBtn_search(item, index)"
                >
                  <g
                    id="그룹_20144"
                    data-name="그룹 20144"
                    transform="translate(-220 -529)"
                  >
                    <rect
                      id="사각형_24941"
                      data-name="사각형 24941"
                      width="30"
                      height="30"
                      transform="translate(220 529)"
                      fill="none"
                    />
                    <path
                      id="패스_8056"
                      data-name="패스 8056"
                      d="M243.9,542.9h-7.8v-7.8a1.1,1.1,0,0,0-2.2,0v7.8h-7.8a1.1,1.1,0,1,0,0,2.2h7.8v7.8a1.1,1.1,0,0,0,2.2,0v-7.8h7.8a1.1,1.1,0,1,0,0-2.2Z"
                      fill="#707070"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  @click="dounscrapBtn_search(item, index)"
                  v-else-if="item.my_scrap == true"
                >
                  <g
                    id="그룹_21055"
                    data-name="그룹 21055"
                    transform="translate(-1476 -628)"
                  >
                    <rect
                      id="사각형_25507"
                      data-name="사각형 25507"
                      width="30"
                      height="30"
                      transform="translate(1476 628)"
                      fill="none"
                    />
                    <path
                      id="패스_8192"
                      data-name="패스 8192"
                      d="M-12275.829-2122.2l5.854,5.794,14.146-14"
                      transform="translate(13757.329 2766.902)"
                      fill="none"
                      stroke="#8ca0ef"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div class="pagingBox">
              <div @click="lastGoPage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style="display: flex; align-item: center; cursor: pointer"
                >
                  <g
                    id="그룹_20908"
                    data-name="그룹 20908"
                    transform="translate(-825 -1333)"
                  >
                    <rect
                      id="사각형_24385"
                      data-name="사각형 24385"
                      width="20"
                      height="20"
                      transform="translate(825 1333)"
                      fill="none"
                      opacity="0.5"
                    />
                    <g
                      id="그룹_19026"
                      data-name="그룹 19026"
                      transform="translate(-150 157)"
                    >
                      <path
                        id="패스_7802"
                        data-name="패스 7802"
                        d="M4,0,0,5l4,5"
                        transform="translate(981 1181)"
                        fill="none"
                        stroke="#767676"
                        stroke-width="2"
                      />
                      <path
                        id="패스_7803"
                        data-name="패스 7803"
                        d="M4,0,0,5l4,5"
                        transform="translate(986 1181)"
                        fill="none"
                        stroke="#767676"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div @click="goPageBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style="display: flex; align-item: center; cursor: pointer"
                >
                  <g
                    id="그룹_20907"
                    data-name="그룹 20907"
                    transform="translate(-845 -1333)"
                  >
                    <rect
                      id="사각형_192"
                      data-name="사각형 192"
                      width="20"
                      height="20"
                      transform="translate(845 1333)"
                      fill="none"
                      opacity="0.5"
                    />
                    <path
                      id="패스_82"
                      data-name="패스 82"
                      d="M4,0,0,5l4,5"
                      transform="translate(853 1338.5)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
              <div
                v-for="(item, index) in last_pageData[pageCount]"
                :key="index"
                @click="goPage(index)"
                class="pageNum"
                :class="{ nowPage_color: nowPage == item + 1 }"
              >
                <span id="">
                  {{ item + 1 }}
                </span>
              </div>
              <div @click="backPageBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style="display: flex; align-item: center; cursor: pointer"
                >
                  <g
                    id="그룹_20906"
                    data-name="그룹 20906"
                    transform="translate(-1105 -1333)"
                  >
                    <rect
                      id="사각형_192"
                      data-name="사각형 192"
                      width="20"
                      height="20"
                      transform="translate(1125 1353) rotate(180)"
                      fill="none"
                      opacity="0.5"
                    />
                    <path
                      id="패스_82"
                      data-name="패스 82"
                      d="M4,0,0,5l4,5"
                      transform="translate(1117 1348.5) rotate(180)"
                      fill="none"
                      stroke="#767676"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
              <div @click="lastBackPage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style="display: flex; align-item: center; cursor: pointer"
                >
                  <g
                    id="그룹_20905"
                    data-name="그룹 20905"
                    transform="translate(-1125 -1333)"
                  >
                    <rect
                      id="사각형_24384"
                      data-name="사각형 24384"
                      width="20"
                      height="20"
                      transform="translate(1145 1353) rotate(180)"
                      fill="none"
                      opacity="0.5"
                    />
                    <g
                      id="그룹_19025"
                      data-name="그룹 19025"
                      transform="translate(-101 158)"
                    >
                      <path
                        id="패스_7804"
                        data-name="패스 7804"
                        d="M0,0,4,5,0,10"
                        transform="translate(1237 1180)"
                        fill="none"
                        stroke="#767676"
                        stroke-width="2"
                      />
                      <path
                        id="패스_7805"
                        data-name="패스 7805"
                        d="M0,0,4,5,0,10"
                        transform="translate(1232 1180)"
                        fill="none"
                        stroke="#767676"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "../../components/navBar.vue";
import scrapLoad from "@/components/matching/scrap_load.vue";
import scrapSave from "@/components/matching/scrap_save.vue";
import { json } from "body-parser";
export default {
  components: { navBar, scrapLoad, scrapSave },
  name: "recommendView",

  mounted() {
    if (this.scrapAT == "") {
      this.scrap_Btns = false;
    } else if (this.scrapAT !== "") {
      this.scrap_Btns = true;
    }
    if (this.search_data.gender == "M") {
      this.gender_M = true;
    } else if (this.search_data.gender == "W") {
      this.gender_W = true;
    } else if (this.search_data.gender == "") {
      this.gender_none = true;
    }
    this.nowPage = this.$route.params.id;
    if (this.search_data.key == "music") {
      this.roleList = [
        {
          text: "보컬",
          value: "보컬",
        },
        {
          text: "기타",
          value: "기타",
        },
        {
          text: "베이스",
          value: "베이스",
        },
        {
          text: "건반",
          value: "건반",
        },
        {
          text: "드럼",
          value: "드럼",
        },
        {
          text: "랩",
          value: "랩",
        },
        {
          text: "싱어송라이터",
          value: "싱어송라이터",
        },
        {
          text: "작곡",
          value: "작곡",
        },
        {
          text: "편곡",
          value: "편곡",
        },
        {
          text: "작사",
          value: "작사",
        },
        {
          text: "비트메이커",
          value: "비트메이커",
        },
        {
          text: "탑라이너",
          value: "탑라이너",
        },
        {
          text: "클래식",
          value: "클래식",
        },
        {
          text: "녹음",
          value: "녹음",
        },
        {
          text: "믹싱",
          value: "믹싱",
        },
      ];
      this.search_data.values = this.search_data.values;
    } else if (this.search_data.key == "video") {
      this.roleList = [
        {
          text: "촬영",
          value: "촬영",
        },
        {
          text: "조명",
          value: "조명",
        },
        {
          text: "기획",
          value: "기획",
        },
        {
          text: "제작",
          value: "제작",
        },
        {
          text: "미술",
          value: "미술",
        },
        {
          text: "사운드",
          value: "사운드",
        },
        {
          text: "연출",
          value: "연출",
        },
        {
          text: "편집",
          value: "편집",
        },
        {
          text: "뮤직비디오",
          value: "뮤직비디오",
        },
        {
          text: "드라마",
          value: "드라마",
        },
        {
          text: "광고",
          value: "광고",
        },
        {
          text: "영화",
          value: "영화",
        },
      ];
      this.search_data.values = this.search_data.values;
    } else if (this.search_data.key == "photography") {
      this.roleList = [
        {
          text: "풍경",
          value: "풍경",
        },
        {
          text: "인물",
          value: "인물",
        },
        {
          text: "제품",
          value: "제품",
        },
        {
          text: "상업",
          value: "상업",
        },
        {
          text: "뷰티",
          value: "뷰티",
        },
        {
          text: "프로필",
          value: "프로필",
        },
        {
          text: "스냅",
          value: "스냅",
        },
      ];
      this.search_data.values = this.search_data.values;
    } else if (this.search_data.key == "design") {
      this.roleList = [
        {
          text: "디지털 그래픽",
          value: "디지털 그래픽",
        },
        {
          text: "캐릭터",
          value: "캐릭터",
        },
        {
          text: "3D 아트",
          value: "3D 아트",
        },
        {
          text: "일러스트레이션",
          value: "일러스트레이션",
        },
        {
          text: "타이포그래피",
          value: "타이포그래피",
        },
        {
          text: "제품/패키지",
          value: "제품/패키지",
        },
        {
          text: "UI/UX",
          value: "UI/UX",
        },
        {
          text: "파인아트",
          value: "파인아트",
        },
        {
          text: "디지털 아트",
          value: "디지털 아트",
        },
      ];
      this.search_data.values = this.search_data.values;
    } else if (this.search_data.key == "specialist") {
      this.roleList = [
        {
          text: "연기",
          value: "연기",
        },
        {
          text: "모델",
          value: "모델",
        },
        {
          text: "춤",
          value: "춤",
        },
        {
          text: "헤어",
          value: "헤어",
        },
        {
          text: "메이크업",
          value: "메이크업",
        },
        {
          text: "유튜버",
          value: "유튜버",
        },
        {
          text: "스타일리스트",
          value: "스타일리스트",
        },
      ];
      this.search_data.values = this.search_data.values;
    }
    let loginUg = localStorage.getItem("loginUg");
    if (loginUg == "false") {
      let token = JSON.parse(sessionStorage.getItem("token"));
      this.search_data.userID = token.userID;
      this.$axios
        .get(this.API_URL + "/search/artists-search", {
          params: this.search_data,
          headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit("set_recommend_data", res.data);
            this.$store.commit("set_search_data", res.data);
            let data = {
              userID: token.userID,
              key: res.data.key,
              values: res.data.values,
              keywords: res.data.keywords,
              gender: this.search_data.gender,
              page: this.$route.params.id,
            };
            this.$store.commit("set_searchData", data);
            let i;
            this.lastBackPageNum = res.data.max_page;
            for (i = 0; i < res.data.max_page; i++) {
              this.last_page.push(i);
            }
            for (i = 0; i < this.last_page.length; i += 10) {
              this.last_pageData.push(this.last_page.slice(i, i + 10));
            }
            for (i = 0; i < this.last_pageData.length; i++) {
              if (this.last_pageData[i].includes(this.nowPage - 1) == true) {
                this.pageCount = i;
              } else {
                true;
              }
            }
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (loginUg == "true") {
      let token = this.$cookie.get("access_token");
      let userID = this.$cookie.get("userID");
      this.search_data.userID = userID;
      this.$axios
        .get(this.API_URL + "/search/artists-search", {
          params: this.search_data,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit("set_recommend_data", res.data);
            this.$store.commit("set_search_data", res.data);
            let data = {
              userID: userID,
              key: res.data.key,
              values: res.data.values,
              keywords: res.data.keywords,
              gender: res.data.gender,
              page: this.$route.params.id,
            };
            this.$store.commit("set_searchData", data);
            let i;
            this.lastBackPageNum = res.data.max_page;
            for (i = 0; i < res.data.max_page; i++) {
              this.last_page.push(i);
            }
            for (i = 0; i < this.last_page.length; i += 10) {
              this.last_pageData.push(this.last_page.slice(i, i + 10));
            }
            for (i = 0; i < this.last_pageData.length; i++) {
              if (this.last_pageData[i].includes(this.nowPage - 1) == true) {
                this.pageCount = i;
              } else {
                true;
              }
            }
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  data() {
    return {
      roleList: [
        {
          text: "보컬",
          value: "보컬",
        },
        {
          text: "기타",
          value: "기타",
        },
        {
          text: "베이스",
          value: "베이스",
        },
        {
          text: "건반",
          value: "건반",
        },
        {
          text: "드럼",
          value: "드럼",
        },
        {
          text: "랩",
          value: "랩",
        },
        {
          text: "싱어송라이터",
          value: "싱어송라이터",
        },
        {
          text: "작곡",
          value: "작곡",
        },
        {
          text: "편곡",
          value: "편곡",
        },
        {
          text: "작사",
          value: "작사",
        },
        {
          text: "비트메이커",
          value: "비트메이커",
        },
        {
          text: "탑라이너",
          value: "탑라이너",
        },
        {
          text: "클래식",
          value: "클래식",
        },
        {
          text: "녹음",
          value: "녹음",
        },
        {
          text: "믹싱",
          value: "믹싱",
        },
      ],
      keywordBox_onoff: false,
      vip_askBox: false,
      // fieldValue: "",
      // roleValue: "",
      gender_M: false,
      gender_W: false,
      gender_none: false,
      gender: "",
      keyword: [],
      hover_index: [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      vip_hover_index: [
        { active: false },
        { active: false },
        { active: false },
      ],
      last_page: [],
      last_pageData: [],
      pageCount: 0,
      nowPage: "",
      lastBackPageNum: "",
      scrap_askBox: false,
      scrap_Btns: false,
    };
  },
  computed: {
    recommend_information() {
      return this.$store.state.MCrecommend.recommend_information;
    },
    search_result() {
      return this.$store.state.MCrecommend.search_result;
    },
    scrap_loadview() {
      return this.$store.state.MCrecommend.scrap_load;
    },
    scrap_loadName() {
      return this.$store.state.MCrecommend.loadName;
    },
    scrap_saveview() {
      return this.$store.state.MCrecommend.scrap_save;
    },
    scrapAT() {
      return this.$store.state.MCrecommend.scrapArtist;
    },
    search_data() {
      return this.$store.state.MCrecommend.search_data;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    genderM() {
      this.gender_M = !this.gender_M;
      this.gender_W = false;
      this.gender_none = false;
    },
    genderW() {
      this.gender_W = !this.gender_W;
      this.gender_M = false;
      this.gender_none = false;
    },
    genderNone() {
      this.gender_none = !this.gender_none;
      this.gender_W = false;
      this.gender_M = false;
    },
    fieldSelect() {
      this.keywordBox_onoff = false;
      this.search_data.keywords = [];
      if (this.search_data.key == "music") {
        this.roleList = [
          {
            text: "보컬",
            value: "보컬",
          },
          {
            text: "기타",
            value: "기타",
          },
          {
            text: "베이스",
            value: "베이스",
          },
          {
            text: "건반",
            value: "건반",
          },
          {
            text: "드럼",
            value: "드럼",
          },
          {
            text: "랩",
            value: "랩",
          },
          {
            text: "싱어송라이터",
            value: "싱어송라이터",
          },
          {
            text: "작곡",
            value: "작곡",
          },
          {
            text: "편곡",
            value: "편곡",
          },
          {
            text: "작사",
            value: "작사",
          },
          {
            text: "비트메이커",
            value: "비트메이커",
          },
          {
            text: "탑라이너",
            value: "탑라이너",
          },
          {
            text: "클래식",
            value: "클래식",
          },
          {
            text: "녹음",
            value: "녹음",
          },
          {
            text: "믹싱",
            value: "믹싱",
          },
        ];
        this.search_data.values = "보컬";
      }
      if (this.search_data.key == "video") {
        this.roleList = [
          {
            text: "촬영",
            value: "촬영",
          },
          {
            text: "조명",
            value: "조명",
          },
          {
            text: "기획",
            value: "기획",
          },
          {
            text: "제작",
            value: "제작",
          },
          {
            text: "미술",
            value: "미술",
          },
          {
            text: "사운드",
            value: "사운드",
          },
          {
            text: "연출",
            value: "연출",
          },
          {
            text: "편집",
            value: "편집",
          },
          {
            text: "뮤직비디오",
            value: "뮤직비디오",
          },
          {
            text: "드라마",
            value: "드라마",
          },
          {
            text: "광고",
            value: "광고",
          },
          {
            text: "영화",
            value: "영화",
          },
        ];
        this.search_data.values = "촬영";
      } else if (this.search_data.key == "photography") {
        this.roleList = [
          {
            text: "풍경",
            value: "풍경",
          },
          {
            text: "인물",
            value: "인물",
          },
          {
            text: "제품",
            value: "제품",
          },
          {
            text: "상업",
            value: "상업",
          },
          {
            text: "뷰티",
            value: "뷰티",
          },
          {
            text: "프로필",
            value: "프로필",
          },
          {
            text: "스냅",
            value: "스냅",
          },
        ];
        this.search_data.values = "풍경";
      } else if (this.search_data.key == "design") {
        this.roleList = [
          {
            text: "디지털 그래픽",
            value: "디지털 그래픽",
          },
          {
            text: "캐릭터",
            value: "캐릭터",
          },
          {
            text: "3D 아트",
            value: "3D 아트",
          },
          {
            text: "일러스트레이션",
            value: "일러스트레이션",
          },
          {
            text: "타이포그래피",
            value: "타이포그래피",
          },
          {
            text: "제품/패키지",
            value: "제품/패키지",
          },
          {
            text: "UI/UX",
            value: "UI/UX",
          },
          {
            text: "파인아트",
            value: "파인아트",
          },
          {
            text: "디지털 아트",
            value: "디지털 아트",
          },
        ];
        this.search_data.values = "디지털 그래픽";
      } else if (this.search_data.key == "specialist") {
        this.roleList = [
          {
            text: "연기",
            value: "연기",
          },
          {
            text: "모델",
            value: "모델",
          },
          {
            text: "춤",
            value: "춤",
          },
          {
            text: "헤어",
            value: "헤어",
          },
          {
            text: "메이크업",
            value: "메이크업",
          },
          {
            text: "유튜버",
            value: "유튜버",
          },
          {
            text: "스타일리스트",
            value: "스타일리스트",
          },
        ];
        this.search_data.values = "연기";
      }
    },
    resetBtn() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$store.commit("reset_search_data", token.userID);
        this.roleList = [
          {
            text: "보컬",
            value: "보컬",
          },
          {
            text: "기타",
            value: "기타",
          },
          {
            text: "베이스",
            value: "베이스",
          },
          {
            text: "건반",
            value: "건반",
          },
          {
            text: "드럼",
            value: "드럼",
          },
          {
            text: "랩",
            value: "랩",
          },
          {
            text: "싱어송라이터",
            value: "싱어송라이터",
          },
          {
            text: "작곡",
            value: "작곡",
          },
          {
            text: "편곡",
            value: "편곡",
          },
          {
            text: "작사",
            value: "작사",
          },
          {
            text: "비트메이커",
            value: "비트메이커",
          },
          {
            text: "탑라이너",
            value: "탑라이너",
          },
          {
            text: "클래식",
            value: "클래식",
          },
          {
            text: "녹음",
            value: "녹음",
          },
          {
            text: "믹싱",
            value: "믹싱",
          },
        ];
      } else if (loginUg == "true") {
        let userID = this.$cookie.get("userID");
        this.$store.commit("reset_search_data", userID);
        this.roleList = [
          {
            text: "보컬",
            value: "보컬",
          },
          {
            text: "기타",
            value: "기타",
          },
          {
            text: "베이스",
            value: "베이스",
          },
          {
            text: "건반",
            value: "건반",
          },
          {
            text: "드럼",
            value: "드럼",
          },
          {
            text: "랩",
            value: "랩",
          },
          {
            text: "싱어송라이터",
            value: "싱어송라이터",
          },
          {
            text: "작곡",
            value: "작곡",
          },
          {
            text: "편곡",
            value: "편곡",
          },
          {
            text: "작사",
            value: "작사",
          },
          {
            text: "비트메이커",
            value: "비트메이커",
          },
          {
            text: "탑라이너",
            value: "탑라이너",
          },
          {
            text: "클래식",
            value: "클래식",
          },
          {
            text: "녹음",
            value: "녹음",
          },
          {
            text: "믹싱",
            value: "믹싱",
          },
        ];
      }
    },
    dosearch() {
      if (this.gender_M == true && this.gender_W == true) {
        this.gender = "";
      } else if (this.gender_W == true) {
        this.gender = "W";
      } else if (this.gender_M == true) {
        this.gender = "M";
      } else {
        this.gender = "";
      }
      let data = {
        key: this.search_data.key,
        values: this.search_data.values,
        keywords: this.search_data.keywords,
        userID: this.search_data.userID,
        gender: this.gender,
        page: this.search_data.page,
      };
      this.$store.commit("set_searchData", data);
      this.$router.go();
    },
    keywordBox_onoffBtn() {
      this.keyword_value = "";
      this.keywordBox_onoff = !this.keywordBox_onoff;
      if (this.fieldValue == "none") {
        alert("분야를 선택하세요.");
      } else if (this.fieldValue == "music") {
        this.keyword_value = "music";
      } else if (this.fieldValue == "video") {
        this.keyword_value = "video";
      } else if (this.fieldValue == "photography") {
        this.keyword_value = "photography";
      } else if (this.fieldValue == "design") {
        this.keyword_value = "design";
      } else if (this.fieldValue == "specialist") {
        this.keyword_value = "specialist";
      }
    },
    goOtherPF(Id) {
      window.open("/portfoliodetail/" + Id + "/PFWork");
    },
    dohover(idx) {
      this.hover_index[idx].active = !this.hover_index[idx].active;
    },
    doViphover(idx) {
      this.vip_hover_index[idx].active = !this.vip_hover_index[idx].active;
    },

    doscrapBtn(item, index) {
      this.scrap_Btns = true;
      this.$store.commit("set_scrapArtist", item);
      this.$store.commit("set_recommend_scrap", index);
    },
    doscrapBtn_search(item, index) {
      this.scrap_Btns = true;

      this.$store.commit("set_scrapArtist", item);
      this.$store.commit("set_search_scrap", index);
    },
    dounscrapBtn(item, index) {
      let data = {
        item: item,
        index: index,
      };
      this.$store.commit("set_recommend_unscrap", data);
      if (this.scrapAT == "") {
        this.scrap_Btns = false;
      }
    },
    dounscrapBtn_search(item, index) {
      let data = {
        item: item,
        index: index,
      };
      this.$store.commit("set_search_unscrap", data);
      if (this.scrapAT == "") {
        this.scrap_Btns = false;
      }
    },
    deleteScrap(item, index) {
      this.$store.commit("DELETE_SCRAP", { item, index });
      if (this.scrapAT == "") {
        this.scrap_Btns = false;
      } else if (this.scrapAT !== "") {
        this.scrap_Btns = true;
      }
    },
    scrap_saveBtn() {
      this.$store.commit("scrap_saveToggle");
    },
    scrap_loadBtn() {
      this.$store.commit("scrap_loadToggle");
    },
    goPage(idx) {
      let num = idx + 1;
      let data = {
        key: this.search_data.key,
        values: this.search_data.values,
        keywords: this.search_data.keywords,
        userID: this.search_data.userID,
        page: num,
      };
      this.$store.commit("set_searchData", data);
      this.$router.push({ path: "/recommendview/" + num });
      this.$router.go();
    },
    lastGoPage() {
      let data = {
        key: this.search_data.key,
        values: this.search_data.values,
        keywords: this.search_data.keywords,
        userID: this.search_data.userID,
        page: 1,
      };
      this.$store.commit("set_searchData", data);
      this.$router.push({ path: "/recommendview/" + 1 });

      this.$router.go();
    },
    lastBackPage() {
      let data = {
        key: this.search_data.key,
        values: this.search_data.values,
        keywords: this.search_data.keywords,
        userID: this.search_data.userID,
        page: this.lastBackPageNum,
      };
      this.$store.commit("set_searchData", data);
      this.$router.push({ path: "/recommendview/" + this.lastBackPageNum });

      this.$router.go();
    },
    backPageBtn() {
      let num = Number(this.search_data.page) + 1;
      if (num > this.lastBackPageNum) {
        alert("마지막 페이지 입니다.");
      } else {
        let data = {
          key: this.search_data.key,
          values: this.search_data.values,
          keywords: this.search_data.keywords,
          userID: this.search_data.userID,
          page: num,
        };
        this.$store.commit("set_searchData", data);
        this.$router.push({ path: "/recommendview/" + num });
        this.$router.go();
      }
    },
    goPageBtn() {
      let num = Number(this.search_data.page) - 1;
      if (num == 0) {
        alert("첫번째 페이지 입니다.");
      } else {
        let data = {
          key: this.search_data.key,
          values: this.search_data.values,
          keywords: this.search_data.keywords,
          userID: this.search_data.userID,
          page: num,
        };
        this.$store.commit("set_searchData", data);
        this.$router.push({ path: "/recommendview/" + num });
        this.$router.go();
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#recommendView {
  width: 1200px;
  min-width: 1200px;
  margin: 90px auto;
}
h5 {
  font: normal normal normal 16px/20px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  width: 50px;
  margin-right: 5px;
}
select {
  height: 30px;
  padding-left: 10px;
  padding-top: 1px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
select::-ms-expand {
  display: none; /*for IE10,11*/
}

.searchBox {
  width: 384px;
  height: 334px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0 30px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.searchBox-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchBox-header h2 {
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 20px 0;
}
.searchBox-header div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.fieldSelect,
.roleSelect,
.gender {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.gender > h5 {
  margin-right: 2px;
}
.keyword {
  display: flex;
  align-items: center;
}
.keyword > h5 {
  margin-right: 1px;
  width: 60px;
}

.fieldBox {
  width: 120px;
}

.roleBox {
  width: 120px;
}

.genderBox {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #505050;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.keyword div {
  width: 290px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: space-between;
}
.keyword div > span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.keyword div > svg {
  margin-right: 10px;
  cursor: pointer;
}
.searchBtn {
  width: 180px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  text-align: center;
  margin: 30px auto;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding: 3px;
  cursor: pointer;
  position: absolute;
  top: 240px;
  left: 100px;
  z-index: -1;
}
.wrap {
  display: flex;
}
.wrap .contentsBox:first-child {
  margin-right: 24px;
}
.contentsBox {
  display: flex;
  flex-direction: column;
}
.keywordInput {
  margin-left: 52px;
}
.keywordInput input {
  display: none;
}
input + label {
  cursor: pointer;
}
input + label::before {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s;
}
input:checked + label::before {
  color: #8ca0ef;
}
.keywordInput-music,
.keywordInput-video,
.keywordInput-photo,
.keywordInput-design,
.keywordInput-specialist {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 10px;
  width: 272px;
  box-sizing: border-box;
}
.keywordInput-music {
  height: 284px;
}
.keywordInput-video {
  height: 420px;
}
.keywordInput-photo {
  height: 284px;
}
.keywordInput-design {
  height: 284px;
}
.keywordInput-specialist {
  height: 250px;
}
.keywordBox-header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.keywordBox-header span {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-left: 10px;
}
.music_box,
.video_box,
.photo_box,
.design_box,
.specialist_box {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-top: 10px;
  display: flex;
  flex-flow: wrap;
  padding: 0 20px;
  padding-top: 10px;
}
.keywordInput label {
  margin-right: 20px;
}
.music_box {
  width: 254px;
  height: 166px;
}
.video_box {
  width: 254px;
  height: 302px;
}
.photo_box {
  width: 254px;
  height: 166px;
}
.design_box {
  width: 254px;
  height: 166px;
}
.specialist_box {
  width: 254px;
  height: 132px;
}
.keywordChoiceBtn {
  width: 89px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 160px;
  cursor: pointer;
}
#vipArtist {
  width: 792px;
  height: 330px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0 30px;
  position: relative;
  margin-bottom: 20px;
}
.vip-header {
  display: flex;
  align-items: center;
}
.vip-header svg {
  cursor: pointer;
}
.vip-askBox {
  width: 334px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  position: absolute;
  top: 54px;
  left: 240px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 20px 0;
  margin-right: 10px;
}
.vip-contentsBox {
  display: flex;
  justify-content: flex-start;
}
.vip-content {
  width: 230px;
  height: 230px;
  background: #eff4ff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 2px #00000029;
  border-radius: 4px;
  padding: 10px;
  margin-right: 21px;
}
.vip-imgBox {
  display: flex;
}
.vip-imgBox img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 10px;
}
.vip-userInfoBox {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 10px;
}
.vip-userInfoBox img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}
.vip-userInfo {
  max-width: 95px;
  min-width: 95px;
}
.vip-userInfo h4 {
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vip-userInfo div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vip-userInfoBox svg {
  margin-left: 10px;
  cursor: pointer;
}
.vip-results {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: relative;
}
.vip-results div {
  font: normal normal normal 14px/21px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #8ca0ef;
}

#vip-resultsHover {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  z-index: 1;
  padding: 20px;
  color: #707070;
}
#vip-resultsHover span {
  margin-right: 10px;
}
#vip-resultsHover span:last-child {
  margin-right: 0px;
}
#recommendAT {
  width: 792px;
  height: 884px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0 30px;
}
#recommendAT h2,
.vip-header > h2 {
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 20px 0;
}
.nav {
  margin-top: -15px;
}
.nav,
.RCAT_contents {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #d6d6d6;
}
.nav div,
.RCAT_contents div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.RCAT_contents {
  padding: 15px 0;
}
.item1,
.item3,
.item4,
.item5,
.item6,
.name,
.role,
.ment,
.RCAT_scrapBtn {
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item1,
.name {
  min-width: 150px;
}

.item3,
.role {
  min-width: 90px;
  margin-right: 20px;
}
.item4,
.ment {
  min-width: 188px;
  max-width: 188px;
}
.item5,
.similarity {
  max-width: 150px;
  min-width: 150px;

  margin-left: 20px;
  margin-right: 30px;
}
.item6,
.RCAT_scrapBtn {
  width: 50px;
}
.name img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.name span {
  min-width: 80px;
  max-width: 80px;
  cursor: pointer;
  font: normal normal bold 16px/19px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
}
.field {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: red;
  text-align: center;
  margin-left: 0px;
}

.ment {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  display: flex;
}
.similarity {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.RCAT_scrapBtn {
  cursor: pointer;
}
.resultBox {
  width: 150px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.moreResultBox {
  position: absolute;
  top: 40px;
  width: 196px;
  min-height: 66px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  z-index: 1;
  padding: 10px;
}
.moreResultBox span {
  margin-right: 5px;
}
.pagingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.pageNum {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nowPage_color {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: #ffffff;
}
#scrap {
  width: 384px;
  height: 440px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  padding: 0 30px;
}
.scrap-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.scrap-header h2 {
  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #191919;
}

.scrap-header svg {
  cursor: pointer;
  margin-left: 10px;
}
.scrap_askBox {
  width: 250px;
  height: 178px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 20px;
  position: absolute;
  top: 54px;
  left: 104px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.scrap_askBox span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.scrap_askBox span:last-child {
  font: normal normal normal 12px/18px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #707070;
  margin-top: 20px;
}
.noscrap {
  position: relative;
}
.noscrap p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  position: absolute;
  top: 132px;
  left: 45px;
  text-align: center;
}
.noscrap div {
  position: absolute;
  top: 195px;
  left: 87px;
  width: 150px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scrap-wrap {
  height: 300px;
  display: flex;
  justify-content: left;
  /* flex-wrap: wrap; */
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrap-wrap::-webkit-scrollbar {
  display: none;
}
.scrapUser {
  height: 40px;
  margin-bottom: 20px;
}
.scrap-userInfo {
  border-left: 2px solid #dbdbdb;
  display: flex;
  padding-left: 10px;

  align-items: center;
}
.scrap-userInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.scrap-userName {
  display: flex;
  align-items: center;
}
.scrap-userName h4 {
  max-width: 100px;
  min-width: 100px;
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}
.scrap-userName svg {
  cursor: pointer;
  margin-left: 24px;
}

.scrap-userName span {
  max-width: 100px;
  min-width: 100px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.scrap_box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.scrap_box-btns div {
  width: 150px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scrap_box-btns div:last-child {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  color: #8ca0ef;
  margin-left: 24px;
}
.load_name {
  width: 80px;
  margin-left: 140px;
  text-align: right;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input#음악-발라드 + label:before {
  content: "#발라드";
  width: 57px;
  height: 30px;
}
input#음악-알앤비 + label:before {
  content: "#알앤비";
  width: 57px;
  height: 30px;
}
input#음악-모던락 + label:before {
  content: "#모던락";
  width: 57px;
  height: 30px;
}
input#음악-힙합 + label:before {
  content: "#힙합";
  width: 42px;
  height: 30px;
}
input#음악-k-pop + label:before {
  content: "#k-pop";
  width: 53px;
  height: 30px;
}
input#음악-어쿠스틱 + label:before {
  content: "#어쿠스틱";
  width: 75px;
  height: 30px;
}
input#음악-재즈 + label:before {
  content: "#재즈";
  width: 42px;
  height: 30px;
}
input#음악-팝 + label:before {
  content: "#팝";
  width: 27px;
  height: 30px;
}
input#음악-디스코 + label:before {
  content: "#디스코";
  width: 57px;
  height: 30px;
}
input#음악-댄스 + label:before {
  content: "#댄스";
  width: 42px;
  height: 30px;
}
input#영상-액션 + label:before {
  content: "#액션";
  width: 42px;
  height: 30px;
}
input#영상-코미디 + label:before {
  content: "#코미디";
  width: 57px;
  height: 30px;
}
input#영상-공포 + label:before {
  content: "#공포";
  width: 42px;
  height: 30px;
}
input#영상-로맨스 + label:before {
  content: "#로맨스";
  width: 57px;
  height: 30px;
}
input#영상-모험 + label:before {
  content: "#모험";
  width: 42px;
  height: 30px;
}
input#영상-범죄 + label:before {
  content: "#범죄";
  width: 42px;
  height: 30px;
}
input#영상-드라마 + label:before {
  content: "#드라마";
  width: 57px;
  height: 30px;
}
input#영상-스릴러 + label:before {
  content: "#스릴러";
  width: 57px;
  height: 30px;
}
input#영상-다큐 + label:before {
  content: "#다큐";
  width: 42px;
  height: 30px;
}
input#영상-SF + label:before {
  content: "#SF";
  width: 31px;
  height: 30px;
}
input#영상-판타지 + label:before {
  content: "#판타지";
  width: 57px;
  height: 30px;
}
input#영상-가족 + label:before {
  content: "#가족";
  width: 42px;
  height: 30px;
}
input#영상-애니메이션 + label:before {
  content: "#애니메이션";
  width: 89px;
  height: 30px;
}
input#영상-독립영화 + label:before {
  content: "#독립영화";
  width: 73px;
  height: 30px;
}
input#영상-서스펜스 + label:before {
  content: "#서스펜스";
  width: 72px;
  height: 30px;
}
input#영상-유튜브 + label:before {
  content: "#유튜브";
  width: 57px;
  height: 30px;
}
input#영상-코레오 + label:before {
  content: "#코레오";
  width: 57px;
  height: 30px;
}
input#영상-웹드라마 + label:before {
  content: "#웹드라마";
  width: 73px;
  height: 30px;
}
input#영상-필름룩스 + label:before {
  content: "#필름룩스";
  width: 73px;
  height: 30px;
}
input#영상-컴퓨터그래픽 + label:before {
  content: "#컴퓨터그래픽";
  width: 104px;
  height: 30px;
}
input#사진-차가운 + label:before {
  content: "#차가운";
  width: 57px;
  height: 30px;
}
input#사진-따뜻한 + label:before {
  content: "#따뜻한";
  width: 57px;
  height: 30px;
}
input#사진-필름 + label:before {
  content: "#필름";
  width: 42px;
  height: 30px;
}
input#사진-건물 + label:before {
  content: "#건물";
  width: 42px;
  height: 30px;
}
input#사진-앨범 + label:before {
  content: "#앨범";
  width: 42px;
  height: 30px;
}
input#사진-아트워크 + label:before {
  content: "#아트워크";
  width: 72px;
  height: 30px;
}
input#사진-예술 + label:before {
  content: "#예술";
  width: 42px;
  height: 30px;
}
input#사진-누드 + label:before {
  content: "#누드";
  width: 42px;
  height: 30px;
}
input#사진-모던 + label:before {
  content: "#모던";
  width: 42px;
  height: 30px;
}
input#사진-자연 + label:before {
  content: "#자연";
  width: 42px;
  height: 30px;
}
input#사진-코스프레 + label:before {
  content: "#코스프레";
  width: 72px;
  height: 30px;
}
input#디자인-드로잉 + label:before {
  content: "#드로잉";
  width: 57px;
  height: 30px;
}
input#디자인-회화 + label:before {
  content: "#회화";
  width: 42px;
  height: 30px;
}
input#디자인-콜라주 + label:before {
  content: "#콜라주";
  width: 57px;
  height: 30px;
}
input#디자인-손그림 + label:before {
  content: "#손그림";
  width: 57px;
  height: 30px;
}
input#디자인-3D + label:before {
  content: "#3D";
  width: 32px;
  height: 30px;
}
input#디자인-목업디자인 + label:before {
  content: "#목업디자인";
  width: 88px;
  height: 30px;
}
input#디자인-모델링 + label:before {
  content: "#모델링";
  width: 57px;
  height: 30px;
}
input#디자인-브랜딩 + label:before {
  content: "#브랜딩";
  width: 57px;
  height: 30px;
}
input#디자인-포토그래피 + label:before {
  content: "#포토그래피";
  width: 88px;
  height: 30px;
}
input#디자인-패키지 + label:before {
  content: "#패키지";
  width: 57px;
  height: 30px;
}
input#디자인-키치 + label:before {
  content: "#키치";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-드라마 + label:before {
  content: "#드라마";
  width: 57px;
  height: 30px;
}

input#스페셜리스트-영화 + label:before {
  content: "#영화";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-연극 + label:before {
  content: "#연극";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-뷰티 + label:before {
  content: "#뷰티";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-코스프레 + label:before {
  content: "#코스프레";
  width: 73px;
  height: 30px;
}
input#스페셜리스트-패션 + label:before {
  content: "#패션";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-안무 + label:before {
  content: "#안무";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-브이로그 + label:before {
  content: "#브이로그";
  width: 73px;
  height: 30px;
}
.noVipMent {
  text-align: center;
}
</style>
