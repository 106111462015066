const userSetup = {
  state() {
    return {
      userInfoSetup: {
        profileImg: "",
        userID: "",
        nickName: "",
        main_field: "",
        main_role: "",
        hello: "",
        field: {
          music: [],
          video: [],
          photography: [],
          design: [],
          specialist: [],
        },
        interests: {
          music: [],
          video: [],
          photography: [],
          design: [],
          specialist: [],
        },
        introduction: "",
        graduated_at: {
          schoolList: [],
        },
        career: "",
        social_media: {
          instagram: "",
          sound_cloud: "",
          vimeo: "",
          youtube: "",
        },
      },
      setSchool: {
        name: "",
        class: "",
        dateS: "",
        dateE: "",
        status: "재학",
        open: true,
        btnBox: false,
      },
      test: [],
      educationRetouch: {
        name: "",
        class: "",
        dateS: "",
        dateE: "",
        status: "",
        open: true,
        btnBox: false,
      },
      userInfo: {
        userID: "",
        nickname: "",
        name: "",
        birth: "",
        email: "",
        phone: "",
        gender: "",
        gender_disclose: true,
      },
      PFChangeImg: "",
      bannerChangeImg: "",
    };
  },

  getters: {},
  mutations: {
    set_changeImg(state, payload) {
      state.PFChangeImg = payload;
    },
    set_bannerChangeImg(state, payload) {
      state.bannerChangeImg = payload;
    },
    set_fieldMusic(state, payload) {
      state.userInfoSetup.field.music = payload;
    },
    REMOVE_FIELD_MUSIC(state, payload) {
      state.userInfoSetup.field.music.splice(payload, 1);
    },
    REMOVE_FIELD_VIDEO(state, payload) {
      state.userInfoSetup.field.video.splice(payload, 1);
    },
    REMOVE_FIELD_PHOTOGRAPHY(state, payload) {
      state.userInfoSetup.field.photography.splice(payload, 1);
    },
    REMOVE_FIELD_DESIGN(state, payload) {
      state.userInfoSetup.field.design.splice(payload, 1);
    },
    REMOVE_FIELD_SPECIALIST(state, payload) {
      state.userInfoSetup.field.specialist.splice(payload, 1);
    },

    REMOVE_ROLE_MUSIC(state, payload) {
      state.userInfoSetup.interests.music.splice(payload, 1);
    },
    REMOVE_ROLE_VIDEO(state, payload) {
      state.userInfoSetup.interests.video.splice(payload, 1);
    },
    REMOVE_ROLE_PHOTOGRAPHY(state, payload) {
      state.userInfoSetup.interests.photography.splice(payload, 1);
    },
    REMOVE_ROLE_DESIGN(state, payload) {
      state.userInfoSetup.interests.design.splice(payload, 1);
    },
    REMOVE_ROLE_SPECIALIST(state, payload) {
      state.userInfoSetup.interests.specialist.splice(payload, 1);
    },
    REMOVE_EDUCATION(state, payload) {
      state.userInfoSetup.graduated_at.schoolList.splice(payload, 1);
    },
    SET_EDURETOUCH(state, payload, index) {
      state.educationRetouch.name = payload.name;
      state.educationRetouch.class = payload.class;
      state.educationRetouch.dateS = payload.dateS;
      state.educationRetouch.dateE = payload.dateE;
      state.educationRetouch.status = payload.status;

      state.userInfoSetup.graduated_at.schoolList.splice(index, 1);
    },
    set_schoolList(state, payload) {
      let i;
      for (i = 0; i < payload.length; i++) {
        state.userInfoSetup.graduated_at.schoolList.push(payload[i]);
      }
    },
    retouch_schoolList(state) {
      state.userInfoSetup.graduated_at.schoolList.push(state.educationRetouch);
      state.educationRetouch = {
        name: "",
        class: "",
        dateS: "",
        dateE: "",
        status: "재학",
        open: true,
        btnBox: false,
      };
    },
  },
  actions: {},
};

export default userSetup;
