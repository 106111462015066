<template>
  <div id="noticeDetailPage">
    <div class="main-navbox">
      <h2>공지사항</h2>
      <div class="main-navboxItem">
        <span id="main-navboxItem_check" @click="noticePage">공지사항</span>
        <span @click="freePage">자유게시판</span>
        <span @click="InquirePage">고객문의</span>
      </div>
      <div class="mycontentsBtn" @click="goMyContents">내 게시글 관리</div>
      <div class="goPageBtn" @click="goWritePage">게시글 작성</div>
    </div>
    <div class="wrap">
      <div>
        <div class="freeDetail">
          <div class="header">
            <div class="title">{{ noticeDetail.board_information.title }}</div>
            <div @click="delBtn" style="display: none">삭제하기</div>
          </div>
          <div class="board_info">
            <div>{{ noticeDetail.board_information.writer_nickname }}</div>
            <div>
              {{ noticeDetail.board_information.created_date }} | 조회수 :
              {{ noticeDetail.board_information.view_count }} | 댓글 : 0
            </div>
          </div>
          <div
            class="imgBox"
            v-for="(item, index) in noticeDetail.board_information.images"
            :key="index"
          >
            <img :src="item" alt="" />
            <div
              class="specialBtn"
              @click="specialPage"
              v-if="specialBtn == true"
            >
              공모전 신청하기
            </div>
            <div
              class="specialBtn"
              @click="specialPage2"
              v-if="specialBtn2 == true"
            >
              공모전 신청하기
            </div>
          </div>
          <div class="board_contents">
            <p
              v-html="
                handleNewLine(noticeDetail.board_information.board_contents)
              "
            ></p>
          </div>
          <div>
            <div class="download_header" v-if="downFile">
              <span>첨부파일</span>
              <div @click="allDownload" style="cursor: pointer">
                <span>전체받기</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g
                    id="그룹_19662"
                    data-name="그룹 19662"
                    transform="translate(-196 -884)"
                  >
                    <rect
                      id="사각형_24947"
                      data-name="사각형 24947"
                      width="30"
                      height="30"
                      transform="translate(196 884)"
                      fill="none"
                    />
                    <rect
                      id="_사각형_24595"
                      width="24"
                      height="24"
                      transform="translate(199 887)"
                      fill="#fff"
                    />
                    <path
                      id="_패스_7948"
                      d="M202.9,901.4v4.7h16.4v-4.7"
                      fill="none"
                      stroke="#8ca0ef"
                      stroke-width="2"
                    />
                    <path
                      id="패스_8062"
                      data-name="패스 8062"
                      d="M213.4,898.7l-2,2v-7.2h-.8v7.2l-2-2-.6.7,3,3.1,3-3.1Z"
                      fill="#8ca0ef"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div
              v-for="(item, index) in noticeDetail.board_information.files"
              :key="index"
              class="download_files"
            >
              <a :href="item.file" :download="item.filename" ref="downloadFile"
                >{{ item.filename }}&nbsp;&nbsp;<svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g
                    id="그룹_19662"
                    data-name="그룹 19662"
                    transform="translate(-196 -884)"
                  >
                    <rect
                      id="사각형_24947"
                      data-name="사각형 24947"
                      width="30"
                      height="30"
                      transform="translate(196 884)"
                      fill="none"
                    />
                    <rect
                      id="_사각형_24595"
                      width="24"
                      height="24"
                      transform="translate(199 887)"
                      fill="#fff"
                    />
                    <path
                      id="_패스_7948"
                      d="M202.9,901.4v4.7h16.4v-4.7"
                      fill="none"
                      stroke="#8ca0ef"
                      stroke-width="2"
                    />
                    <path
                      id="패스_8062"
                      data-name="패스 8062"
                      d="M213.4,898.7l-2,2v-7.2h-.8v7.2l-2-2-.6.7,3,3.1,3-3.1Z"
                      fill="#8ca0ef"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div class="btnBox">
            <div class="btns">
              <div @click="notice_backPost">이전</div>
              <div @click="notice_goPost">다음</div>
            </div>
            <div class="btns2" @click="notice_goList">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <g
                  id="그룹_19647"
                  data-name="그룹 19647"
                  transform="translate(-240 -884)"
                >
                  <rect
                    id="사각형_24936"
                    data-name="사각형 24936"
                    width="30"
                    height="30"
                    transform="translate(240 884)"
                    fill="none"
                  />
                  <rect
                    id="_사각형_24896"
                    width="24"
                    height="24"
                    transform="translate(243 887)"
                    fill="#8ca0ef"
                  />
                  <rect
                    id="_사각형_24603"
                    width="24"
                    height="24"
                    transform="translate(243 887)"
                    fill="none"
                  />
                  <line
                    id="_선_123"
                    x2="16"
                    transform="translate(247 893)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                  />
                  <line
                    id="_선_124"
                    x2="16"
                    transform="translate(247 905)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                  />
                  <line
                    id="_선_125"
                    x2="16"
                    transform="translate(247 899)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                  />
                </g>
              </svg>
              <span>목록</span>
            </div>
          </div>
        </div>
        <noticeCMList></noticeCMList>
      </div>
      <div class="firePostBox">
        <h2>인기 게시글</h2>
        <div class="firePost" v-for="item in popularPost" :key="item.id">
          <p @click="goFreeDetail(item.id)">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noticeCMList from "./noticeCMList.vue";
export default {
  components: { noticeCMList },
  mounted() {
    if (this.$route.params.id == 3) {
      this.specialBtn = true;
    }
    if (this.$route.params.id == 4) {
      this.specialBtn2 = true;
    }
    let token = this.$cookie.get("access_token");
    let sessionToken = JSON.parse(sessionStorage.getItem("token"));
    if (sessionToken !== null || token !== null) {
      this.isLogin = true;
    } else if (sessionToken == null || token == null) {
      this.isLogin = false;
    }
    this.$axios
      .get(this.API_URL + "/community/announce-board", {
        params: { id: this.$route.params.id },
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.$store.commit("set_noticeDetail", res.data);
          document.documentElement.scrollTop = 0;
          this.$store.state.community.noticeCMPaging.nowPage =
            res.data.current_page - 1;
          if (this.noticeDetail.board_information.files[0]) {
            this.downFile = true;
          } else {
            this.downFile = false;
          }
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    noticeDetail() {
      return this.$store.state.community.noticeDetail;
    },
    userData() {
      return this.$store.state.navStore.navData;
    },
    popularPost() {
      return this.$store.state.community.popularPost;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  data() {
    return {
      isLogin: false,
      downFile: false,
      specialBtn: false,
      specialBtn2: false,
    };
  },
  methods: {
    specialPage() {
      this.$router.push({ name: "event001" });
    },
    specialPage2() {
      if (this.isLogin == false) {
        alert("로그인 후 신청해주세요.");
      } else {
        this.$router.push({ name: "event002" });
      }
    },
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
    goFreeDetail(postNum) {
      this.$store.commit("set_noticeDel");
      this.$router.push({
        path: "/community/freeDetail/" + postNum,
      });
    },
    notice_backPost() {
      let postID = this.noticeDetail.board_information.prev_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/announce-board", {
              params: { userID: token.userID, id: postID },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_noticeDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/announce-board", {
              params: { userID: userID, id: postID },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_noticeDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    notice_goPost() {
      let postID = this.noticeDetail.board_information.next_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/announce-board", {
              params: { userID: token.userID, id: postID },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_noticeDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/announce-board", {
              params: { userID: userID, id: postID },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_noticeDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    notice_goList() {
      this.$store.commit("set_noticeDel");
      this.$router.push({ path: "/community/mainpage/1" });
    },
    allDownload() {
      let i;
      for (i = 0; i < this.$refs.downloadFile.length; i++) {
        this.$refs.downloadFile[i].click();
      }
    },
    noticePage() {
      this.$store.commit("set_noticeDel");
      this.$router.push({ path: "/community/mainpage/1" });
    },
    freePage() {
      this.$store.commit("set_noticeDel");
      this.$router.push({ path: "/community/freePage/1" });
    },
    InquirePage() {
      if (this.isLogin == false) {
        alert("로그인후 이용해주세요.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_noticeDel");
        this.$router.push({ path: "/community/Inquirepage/1" });
      }
    },
    goWritePage() {
      this.$store.commit("set_noticeDel");
      this.$router.push({ path: "/community/writepage" });
    },
    goMyContents() {
      if (this.isLogin == false) {
        alert("로그인후 이용해주세요.");
      } else if (this.isLogin == true) {
        this.$store.commit("set_noticeDel");
        this.$router.push({ path: "/community/myCMPage/1" });
      }
    },
    delBtn() {
      let token = this.$cookie.get("access_token");
      let userID = this.$cookie.get("userID");
      this.$axios
        .delete(
          this.API_URL + "/community/announce-board",

          {
            params: {
              userID: userID,
              id: 3,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == 200) {
            alert(res.data.msg);
            this.$router.push({ path: "/community/freepage/1" });
          } else {
            alert(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
select {
  width: 90px;
  height: 30px;
  padding-top: 1px;
  padding-left: 19px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #505050;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
}
#noticeDetailPage {
  margin-bottom: 100px;
  width: 1200px;
  display: flex;
  margin: 0 auto;
}
.main-navbox {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.main-navboxItem {
  width: 180px;
  height: 156px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.main-navboxItem span {
  margin-bottom: 12px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  cursor: pointer;
}
.main-navboxItem span:last-child {
  margin-bottom: 0;
}
#main-navboxItem_check {
  color: #8ca0ef;
}
.main-navbox h2 {
  font: normal normal bold 24px/35px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #191919;
  padding: 40px 0;
  padding-left: 30px;
}
.freeDetail {
  width: 690px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 30px;
  padding-top: 18px;
}
.header {
  display: flex;
  border-bottom: 1px solid #707070;
}
.title {
  width: 510px;
  height: 40px;
  padding: 7px 10px;
  font-weight: bold;
}

.board_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 6px;
  padding-bottom: 30px;
}
.board_info div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.imgBox {
  max-width: 610px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.imgBox img {
  max-width: 610px;
}
.board_contents {
  padding-bottom: 30px;
  padding-left: 10px;
}
.board_contents p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.download_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.download_header > span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
.download_header > div {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.download_header > div > span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #505050;
}
.download_files {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.download_files a {
  text-decoration: none;
  display: flex;
  align-items: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  margin-top: 20px;
}
.btns {
  display: flex;
}
.btns div {
  width: 90px;
  height: 30px;
  border: 1px solid #8ca0ef;
  border-radius: 4px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btns div:last-child {
  margin-left: 20px;
}
.btns2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
}
.wrap {
  margin-top: 116px;
  display: flex;
  align-items: flex-start;
}
.firePostBox {
  width: 282px;
  height: 346px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-left: 24px;
  padding: 20px;
}
.firePostBox h2 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost {
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 0;
  cursor: pointer;
}
.mycontentsBtn {
  width: 180px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;

  text-align: center;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
.goPageBtn {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;

  text-align: center;
  padding: 8px;
  cursor: pointer;
}
.specialBtn {
  width: 227px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  cursor: pointer;
}
</style>
