import Vue from "vue";
import VueRouter from "vue-router";

//  관리 파일
import LoginRouter from "../router/LoginRouter/loginRouter";
import MatchingRouter from "../router/MatchingRouter/MatchingRouter";
import PortfolioRouter from "../router/PortfolioRouter/PortfolioRouter";
import CommunityRouter from "../router/CommunityRouter/CommunityRouter";
import usersetting from "./userSetting";
import chating from "@/router/chating";
Vue.use(VueRouter);

export const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { top: 0 };
  //   }
  // },
  routes: [
    ...LoginRouter,
    ...MatchingRouter,
    ...PortfolioRouter,
    ...CommunityRouter,
    ...usersetting,
    ...chating,
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeView"),
    },
    {
      path: "/mobile",
      name: "mobile",
      component: () => import("@/mobile/mobile.vue"),
    },
    {
      path: "/info",
      name: "info",
      component: () => import("../views/footer/info.vue"),
    },
    {
      path: "/privacyinfo",
      name: "privacyinfo",
      component: () => import("../views/footer/PrivacyInfo.vue"),
    },
    {
      path: "/termsservice",
      name: "termsservice",
      component: () => import("../views/footer/TermsService.vue"),
    },
    {
      path: "/contactus",
      name: "contactus",
      component: () => import("../views/footer/contactUs.vue"),
    },
    {
      path: "/postview/:id",
      name: "postview",
      component: () => import("../views/postView.vue"),
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/views/testView"),
    },
    {
      path: "/event001",
      name: "event001",
      component: () => import("@/views/collusion"),
    },
    {
      path: "/event002",
      name: "event002",
      component: () => import("@/views/collusion2"),
    },
  ],
});
