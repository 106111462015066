<template>
  <div>
    <div class="PFFeedNav">
      <div>전체</div>
      <div @click="goFollow" class="PFFeedNav2">팔로잉</div>
      <select v-model="fieldValue" @change="fieldSelect()">
        <option value="all">전체</option>
        <option value="music">음악</option>
        <option value="video">영상</option>
        <option value="photography">사진</option>
        <option value="design">디자인</option>
        <option value="specialist">스페셜리스트</option>
      </select>
    </div>
    <div
      v-for="(item, index) in preview_data"
      :key="index"
      class="feedcontents"
    >
      <img
        :src="item.PVData.thumbnail"
        alt=""
        class="feedcontents-img"
        @click="goPost(item.PVData.feed_id)"
        style="margin-bottom: 10px"
      />
      <div class="feed_title">{{ item.PVData.feed_title }}</div>
      <div class="writerInfo">
        <div
          class="writer"
          @click="goOtherPF(item.PVData.userID)"
          style="cursor: pointer"
        >
          <img :src="item.PVData.profile_img" class="writerImg" />
          <span>{{ item.PVData.nickName }}</span>
        </div>
        <div>
          <div v-if="item.PVData.feed_field == 'music'" class="fieldView">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1793_11358"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <path d="M24 0H0V24H24V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_1793_11358)">
                <path
                  d="M20 1C20.7956 1 21.5587 1.31607 22.1213 1.87868C22.6839 2.44129 23 3.20435 23 4V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H20ZM20 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 20C0 21.0609 0.421427 22.0783 1.17157 22.8284C1.92172 23.5786 2.93913 24 4 24H20C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20V4C24 2.93913 23.5786 1.92172 22.8284 1.17157C22.0783 0.421427 21.0609 0 20 0Z"
                  fill="#DBDBDB"
                />
                <path
                  d="M6 20.5C5.50555 20.5 5.0222 20.3534 4.61108 20.0787C4.19995 19.804 3.87952 19.4135 3.6903 18.9567C3.50108 18.4999 3.45157 17.9972 3.54804 17.5123C3.6445 17.0273 3.8826 16.5819 4.23223 16.2322C4.58187 15.8826 5.02732 15.6445 5.51228 15.548C5.99723 15.4516 6.49989 15.5011 6.95671 15.6903C7.41352 15.8795 7.80397 16.2 8.07868 16.6111C8.35338 17.0222 8.5 17.5055 8.5 18C8.5 18.3283 8.43534 18.6534 8.3097 18.9567C8.18406 19.26 7.99991 19.5356 7.76777 19.7678C7.53562 19.9999 7.26002 20.1841 6.95671 20.3097C6.6534 20.4353 6.32831 20.5 6 20.5Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M6 16C6.39556 16 6.78224 16.1173 7.11114 16.3371C7.44004 16.5568 7.69639 16.8692 7.84776 17.2346C7.99914 17.6001 8.03874 18.0022 7.96157 18.3902C7.8844 18.7781 7.69392 19.1345 7.41422 19.4142C7.13451 19.6939 6.77814 19.8844 6.39018 19.9616C6.00222 20.0387 5.60009 19.9991 5.23463 19.8478C4.86918 19.6964 4.55683 19.44 4.33706 19.1111C4.1173 18.7822 4 18.3956 4 18C4 17.4696 4.21072 16.9609 4.58579 16.5858C4.96086 16.2107 5.46957 16 6 16ZM6 15C5.40666 15 4.82664 15.1759 4.33329 15.5056C3.83994 15.8352 3.45543 16.3038 3.22836 16.8519C3.0013 17.4001 2.94189 18.0033 3.05765 18.5853C3.1734 19.1672 3.45912 19.7018 3.87868 20.1213C4.29824 20.5409 4.83279 20.8266 5.41473 20.9424C5.99667 21.0581 6.59987 20.9987 7.14805 20.7716C7.69623 20.5446 8.16477 20.1601 8.49441 19.6667C8.82405 19.1734 9 18.5933 9 18C9 17.2044 8.68393 16.4413 8.12132 15.8787C7.55871 15.3161 6.79565 15 6 15Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M16 18.5C15.5055 18.5 15.0222 18.3534 14.6111 18.0787C14.2 17.804 13.8795 17.4135 13.6903 16.9567C13.5011 16.4999 13.4516 15.9972 13.548 15.5123C13.6445 15.0273 13.8826 14.5819 14.2322 14.2322C14.5819 13.8826 15.0273 13.6445 15.5123 13.548C15.9972 13.4516 16.4999 13.5011 16.9567 13.6903C17.4135 13.8795 17.804 14.2 18.0787 14.6111C18.3534 15.0222 18.5 15.5055 18.5 16C18.5 16.663 18.2366 17.2989 17.7678 17.7678C17.2989 18.2366 16.663 18.5 16 18.5Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M16 14C16.3956 14 16.7822 14.1173 17.1111 14.3371C17.44 14.5568 17.6964 14.8692 17.8478 15.2346C17.9991 15.6001 18.0387 16.0022 17.9616 16.3902C17.8844 16.7781 17.6939 17.1345 17.4142 17.4142C17.1345 17.6939 16.7781 17.8844 16.3902 17.9616C16.0022 18.0387 15.6001 17.9991 15.2346 17.8478C14.8692 17.6964 14.5568 17.44 14.3371 17.1111C14.1173 16.7822 14 16.3956 14 16C14 15.4696 14.2107 14.9609 14.5858 14.5858C14.9609 14.2107 15.4696 14 16 14ZM16 13C15.4067 13 14.8266 13.1759 14.3333 13.5056C13.8399 13.8352 13.4554 14.3038 13.2284 14.8519C13.0013 15.4001 12.9419 16.0033 13.0576 16.5853C13.1734 17.1672 13.4591 17.7018 13.8787 18.1213C14.2982 18.5409 14.8328 18.8266 15.4147 18.9424C15.9967 19.0581 16.5999 18.9987 17.1481 18.7716C17.6962 18.5446 18.1648 18.1601 18.4944 17.6667C18.8241 17.1734 19 16.5933 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7957 13 16 13Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M8 18V7.231L18 4V15.846"
                  stroke="#8CA0EF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <div>음악</div>
          </div>
          <div v-if="item.PVData.feed_field == 'video'" class="fieldView">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1822_11105"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <path d="M24 0H0V24H24V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_1822_11105)">
                <path
                  d="M20 1C20.7956 1 21.5587 1.31607 22.1213 1.87868C22.6839 2.44129 23 3.20435 23 4V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H20ZM20 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 20C0 21.0609 0.421427 22.0783 1.17157 22.8284C1.92172 23.5786 2.93913 24 4 24H20C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20V4C24 2.93913 23.5786 1.92172 22.8284 1.17157C22.0783 0.421427 21.0609 0 20 0Z"
                  fill="#DBDBDB"
                />
                <path
                  d="M16 8.5H5C4.72386 8.5 4.5 8.72386 4.5 9V15C4.5 15.2761 4.72386 15.5 5 15.5H16C16.2761 15.5 16.5 15.2761 16.5 15V9C16.5 8.72386 16.2761 8.5 16 8.5Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M16 15H5V9H16V15ZM16 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H16C16.2652 16 16.5196 15.8946 16.7071 15.7071C16.8946 15.5196 17 15.2652 17 15V9C17 8.73478 16.8946 8.48043 16.7071 8.29289C16.5196 8.10536 16.2652 8 16 8Z"
                  fill="#8CA0EF"
                />
                <path d="M20 9L17 12L20 15V9Z" fill="#8CA0EF" />
                <path
                  d="M20 9L17 12L20 15V9Z"
                  stroke="#8CA0EF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <div>비디오</div>
          </div>
          <div v-if="item.PVData.feed_field == 'design'" class="fieldView">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1822_11124"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <path d="M24 0H0V24H24V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_1822_11124)">
                <path
                  d="M20 1C20.7956 1 21.5587 1.31607 22.1213 1.87868C22.6839 2.44129 23 3.20435 23 4V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H20ZM20 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 20C0 21.0609 0.421427 22.0783 1.17157 22.8284C1.92172 23.5786 2.93913 24 4 24H20C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20V4C24 2.93913 23.5786 1.92172 22.8284 1.17157C22.0783 0.421427 21.0609 0 20 0Z"
                  fill="#DBDBDB"
                />
                <path
                  d="M7.86396 4.57479C4.45396 5.96779 3.11596 10.5878 4.50896 13.9998C4.70109 14.4721 4.94705 14.9208 5.24196 15.3368C5.32359 15.4504 5.43456 15.5396 5.563 15.5949C5.69145 15.6502 5.83252 15.6696 5.97112 15.6509C6.10972 15.6322 6.24062 15.5761 6.34981 15.4888C6.45899 15.4014 6.54236 15.2859 6.59096 15.1548C6.75674 14.7646 7.02492 14.4264 7.36713 14.1761C7.70934 13.9257 8.11285 13.7726 8.53496 13.7328C8.91995 13.6922 9.30894 13.7495 9.6659 13.8993C10.0229 14.0492 10.3362 14.2866 10.577 14.5898C10.8443 14.919 11.0176 15.3144 11.0785 15.7341C11.1394 16.1538 11.0857 16.5822 10.923 16.9738C10.8633 17.1003 10.8392 17.2406 10.8532 17.3798C10.8672 17.5189 10.9187 17.6516 11.0023 17.7637C11.086 17.8758 11.1985 17.963 11.3279 18.0161C11.4573 18.0691 11.5987 18.0859 11.737 18.0648C12.2407 17.9832 12.7335 17.8447 13.206 17.6518C16.616 16.2588 18.977 12.0678 17.584 8.65779C16.191 5.24779 11.274 3.18379 7.86396 4.57679"
                  fill="#8CA0EF"
                />
                <mask
                  id="mask1_1822_11124"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="10"
                  y="5"
                  width="12"
                  height="17"
                >
                  <path
                    d="M21.599 5.55664H10.115V21.0606H21.599V5.55664Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask1_1822_11124)">
                  <mask
                    id="mask2_1822_11124"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="5"
                    width="12"
                    height="17"
                  >
                    <path
                      d="M21.599 5.55664H10.115V21.0606H21.599V5.55664Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask2_1822_11124)">
                    <path
                      d="M21.592 6.1822C21.778 7.6822 18.41 12.7882 16.08 15.5332C16.1248 15.5791 16.1529 15.6387 16.16 15.7025C16.1671 15.7663 16.1526 15.8306 16.119 15.8852L15.011 17.4612C14.9772 17.5014 14.9324 17.5307 14.8821 17.5457C14.8318 17.5606 14.7782 17.5604 14.728 17.5452C14.5491 18.2746 14.189 18.9471 13.681 19.5002C12.7556 20.257 11.6664 20.7874 10.5 21.0492C10.4562 21.0621 10.4102 21.0655 10.365 21.0591C10.3198 21.0528 10.2765 21.0367 10.238 21.0122L10.191 20.9642C10.1544 20.9169 10.1296 20.8616 10.1184 20.8029C10.1073 20.7441 10.1102 20.6836 10.127 20.6262L10.563 18.6932C10.6738 18.1363 10.8994 17.6085 11.2255 17.1437C11.5517 16.6788 11.971 16.287 12.457 15.9932C12.4033 15.947 12.3671 15.8837 12.3544 15.8141C12.3416 15.7444 12.3531 15.6724 12.387 15.6102L13.5 14.0292C13.5335 13.9918 13.5764 13.9641 13.6242 13.949C13.6721 13.9338 13.7231 13.9318 13.772 13.9432C15.561 10.8342 19.238 5.9062 20.732 5.5812C20.8299 5.55345 20.9327 5.54739 21.0333 5.56346C21.1338 5.57952 21.2296 5.61731 21.314 5.6742L21.32 5.6862C21.3984 5.74555 21.4634 5.82075 21.5108 5.9069C21.5581 5.99305 21.5868 6.08821 21.595 6.1862M15.5 15.0932C18.407 11.6782 21.075 7.1052 20.869 6.2842C20.034 6.3842 16.569 10.5022 14.392 14.3142V14.3232L15.5 15.1002V15.0932ZM14.587 16.8102L15.369 15.6932L13.928 14.6842L13.137 15.8002L14.587 16.8102ZM13.987 17.3472C14.0003 17.2759 14.0275 17.208 14.067 17.1472L13.104 16.4702C13.0417 16.5486 12.9623 16.6119 12.872 16.6552C12.4752 16.8476 12.1299 17.1317 11.8647 17.484C11.5995 17.8363 11.4221 18.2467 11.347 18.6812L11.047 20.0362C11.8334 19.8346 12.5675 19.467 13.2 18.9582C13.5986 18.4986 13.8715 17.9436 13.992 17.3472"
                      fill="white"
                    />
                    <path
                      d="M20.871 6.28516C21.071 7.10516 18.409 11.6792 15.502 15.0942V15.1032L14.395 14.3242V14.3152C16.57 10.5032 20.035 6.38116 20.871 6.28516Z"
                      fill="#8CA0EF"
                    />
                    <path
                      d="M15.3709 15.6925L14.5889 16.8095L13.1379 15.7995L13.9289 14.6855L15.3709 15.6925Z"
                      fill="#8CA0EF"
                    />
                    <path
                      d="M14.074 17.1429C14.0342 17.2035 14.007 17.2716 13.994 17.3429C13.8725 17.939 13.5998 18.4937 13.202 18.9539C12.5692 19.4606 11.8355 19.8264 11.05 20.0269L11.35 18.6719C11.4255 18.2374 11.6032 17.827 11.8685 17.4748C12.1339 17.1225 12.4792 16.8384 12.876 16.6459C12.9662 16.6024 13.0455 16.5392 13.108 16.4609L14.074 17.1429Z"
                      fill="#8CA0EF"
                    />
                  </g>
                </g>
                <path
                  d="M6 10.1934C5.90111 10.1934 5.80444 10.164 5.72221 10.1091C5.63999 10.0542 5.5759 9.97606 5.53806 9.8847C5.50022 9.79334 5.49031 9.69281 5.50961 9.59581C5.5289 9.49882 5.57652 9.40973 5.64645 9.33981C5.71637 9.26988 5.80546 9.22226 5.90246 9.20297C5.99945 9.18367 6.09998 9.19358 6.19134 9.23142C6.28271 9.26926 6.36079 9.33335 6.41574 9.41557C6.47068 9.4978 6.5 9.59447 6.5 9.69336C6.5 9.82597 6.44732 9.95315 6.35355 10.0469C6.25979 10.1407 6.13261 10.1934 6 10.1934Z"
                  fill="white"
                />
                <path
                  d="M6 8.69336C5.80222 8.69336 5.60888 8.75201 5.44443 8.86189C5.27998 8.97177 5.15181 9.12795 5.07612 9.31068C5.00043 9.4934 4.98063 9.69447 5.01922 9.88845C5.0578 10.0824 5.15304 10.2606 5.29289 10.4005C5.43275 10.5403 5.61093 10.6356 5.80491 10.6741C5.99889 10.7127 6.19996 10.6929 6.38268 10.6172C6.56541 10.5416 6.72159 10.4134 6.83147 10.2489C6.94135 10.0845 7 9.89114 7 9.69336C7 9.42814 6.89464 9.17379 6.70711 8.98625C6.51957 8.79872 6.26522 8.69336 6 8.69336Z"
                  fill="white"
                />
                <path
                  d="M8.646 7.91992C8.54711 7.91992 8.45044 7.8906 8.36821 7.83566C8.28599 7.78072 8.2219 7.70263 8.18406 7.61126C8.14621 7.5199 8.13631 7.41937 8.1556 7.32238C8.1749 7.22539 8.22252 7.13629 8.29244 7.06637C8.36237 6.99644 8.45146 6.94882 8.54845 6.92953C8.64544 6.91024 8.74597 6.92014 8.83734 6.95798C8.9287 6.99583 9.00679 7.05991 9.06173 7.14214C9.11667 7.22436 9.146 7.32103 9.146 7.41992C9.146 7.55253 9.09332 7.67971 8.99955 7.77348C8.90578 7.86724 8.7786 7.91992 8.646 7.91992Z"
                  fill="white"
                />
                <path
                  d="M8.64697 6.41992C8.44919 6.41992 8.25585 6.47857 8.0914 6.58845C7.92695 6.69833 7.79878 6.85451 7.72309 7.03724C7.64741 7.21996 7.6276 7.42103 7.66619 7.61501C7.70477 7.80899 7.80001 7.98718 7.93987 8.12703C8.07972 8.26688 8.2579 8.36212 8.45188 8.40071C8.64586 8.43929 8.84693 8.41949 9.02966 8.3438C9.21238 8.26811 9.36856 8.13994 9.47844 7.97549C9.58832 7.81104 9.64697 7.6177 9.64697 7.41992C9.64697 7.15471 9.54162 6.90035 9.35408 6.71281C9.16654 6.52528 8.91219 6.41992 8.64697 6.41992Z"
                  fill="white"
                />
                <path
                  d="M12 7.05469C11.9011 7.05469 11.8044 7.02536 11.7222 6.97042C11.64 6.91548 11.5759 6.83739 11.5381 6.74603C11.5002 6.65467 11.4903 6.55413 11.5096 6.45714C11.5289 6.36015 11.5765 6.27106 11.6464 6.20113C11.7164 6.13121 11.8055 6.08359 11.9025 6.0643C11.9994 6.045 12.1 6.0549 12.1913 6.09275C12.2827 6.13059 12.3608 6.19468 12.4157 6.2769C12.4707 6.35913 12.5 6.4558 12.5 6.55469C12.5 6.6873 12.4473 6.81447 12.3536 6.90824C12.2598 7.00201 12.1326 7.05469 12 7.05469Z"
                  fill="white"
                />
                <path
                  d="M12 5.55469C11.8022 5.55469 11.6089 5.61334 11.4444 5.72322C11.28 5.8331 11.1518 5.98928 11.0761 6.172C11.0004 6.35473 10.9806 6.5558 11.0192 6.74978C11.0578 6.94376 11.153 7.12194 11.2929 7.26179C11.4327 7.40165 11.6109 7.49689 11.8049 7.53547C11.9989 7.57406 12.2 7.55425 12.3827 7.47857C12.5654 7.40288 12.7216 7.27471 12.8315 7.11026C12.9414 6.94581 13 6.75247 13 6.55469C13 6.28947 12.8946 6.03512 12.7071 5.84758C12.5196 5.66004 12.2652 5.55469 12 5.55469Z"
                  fill="white"
                />
                <path
                  d="M14.389 9.91992C14.2901 9.91992 14.1935 9.8906 14.1113 9.83566C14.029 9.78072 13.9649 9.70263 13.9271 9.61126C13.8893 9.5199 13.8794 9.41937 13.8986 9.32238C13.9179 9.22539 13.9656 9.1363 14.0355 9.06637C14.1054 8.99644 14.1945 8.94882 14.2915 8.92953C14.3885 8.91024 14.489 8.92014 14.5804 8.95798C14.6717 8.99583 14.7498 9.05991 14.8048 9.14214C14.8597 9.22436 14.889 9.32103 14.889 9.41992C14.889 9.55253 14.8364 9.67971 14.7426 9.77348C14.6488 9.86724 14.5216 9.91992 14.389 9.91992Z"
                  fill="white"
                />
                <path
                  d="M14.389 8.41992C14.1913 8.41992 13.9979 8.47857 13.8335 8.58845C13.669 8.69833 13.5408 8.85451 13.4652 9.03724C13.3895 9.21997 13.3697 9.42103 13.4083 9.61501C13.4468 9.80899 13.5421 9.98718 13.6819 10.127C13.8218 10.2669 14 10.3621 14.1939 10.4007C14.3879 10.4393 14.589 10.4195 14.7717 10.3438C14.9544 10.2681 15.1106 10.1399 15.2205 9.97549C15.3304 9.81104 15.389 9.6177 15.389 9.41992C15.389 9.15471 15.2837 8.90035 15.0961 8.71282C14.9086 8.52528 14.6543 8.41992 14.389 8.41992Z"
                  fill="white"
                />
                <path
                  d="M10.239 12.4004C10.1401 12.4004 10.0435 12.3711 9.96123 12.3161C9.879 12.2612 9.81492 12.1831 9.77707 12.0917C9.73923 12.0004 9.72933 11.8998 9.74862 11.8028C9.76791 11.7059 9.81553 11.6168 9.88546 11.5468C9.95539 11.4769 10.0445 11.4293 10.1415 11.41C10.2385 11.3907 10.339 11.4006 10.4304 11.4385C10.5217 11.4763 10.5998 11.5404 10.6547 11.6226C10.7097 11.7048 10.739 11.8015 10.739 11.9004C10.739 12.033 10.6863 12.1602 10.5926 12.2539C10.4988 12.3477 10.3716 12.4004 10.239 12.4004Z"
                  fill="white"
                />
                <path
                  d="M10.239 10.9004C10.0412 10.9004 9.84789 10.959 9.68344 11.0689C9.51899 11.1788 9.39082 11.335 9.31513 11.5177C9.23945 11.7004 9.21964 11.9015 9.25823 12.0955C9.29681 12.2895 9.39205 12.4676 9.53191 12.6075C9.67176 12.7474 9.84994 12.8426 10.0439 12.8812C10.2379 12.9198 10.439 12.9 10.6217 12.8243C10.8044 12.7486 10.9606 12.6204 11.0705 12.456C11.1804 12.2915 11.239 12.0982 11.239 11.9004C11.239 11.6352 11.1337 11.3808 10.9461 11.1933C10.7586 11.0057 10.5042 10.9004 10.239 10.9004Z"
                  fill="white"
                />
              </g>
            </svg>
            <div>디자인</div>
          </div>
          <div v-if="item.PVData.feed_field == 'photography'" class="fieldView">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1822_11115"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <path d="M24 0H0V24H24V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_1822_11115)">
                <path
                  d="M20 1C20.7956 1 21.5587 1.31607 22.1213 1.87868C22.6839 2.44129 23 3.20435 23 4V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H20ZM20 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 20C0 21.0609 0.421427 22.0783 1.17157 22.8284C1.92172 23.5786 2.93913 24 4 24H20C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20V4C24 2.93913 23.5786 1.92172 22.8284 1.17157C22.0783 0.421427 21.0609 0 20 0Z"
                  fill="#DBDBDB"
                />
                <path
                  d="M19.559 19H5.55896V5H19.559V19ZM19.559 4H5.55896C5.29374 4 5.03939 4.10536 4.85185 4.29289C4.66432 4.48043 4.55896 4.73478 4.55896 5V19C4.55896 19.2652 4.66432 19.5196 4.85185 19.7071C5.03939 19.8946 5.29374 20 5.55896 20H19.559C19.8242 20 20.0785 19.8946 20.2661 19.7071C20.4536 19.5196 20.559 19.2652 20.559 19V5C20.559 4.73478 20.4536 4.48043 20.2661 4.29289C20.0785 4.10536 19.8242 4 19.559 4Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M13.559 9.5C13.559 9.79667 13.471 10.0867 13.3062 10.3334C13.1413 10.58 12.9071 10.7723 12.633 10.8858C12.3589 10.9994 12.0573 11.0291 11.7663 10.9712C11.4754 10.9133 11.2081 10.7704 10.9983 10.5607C10.7885 10.3509 10.6457 10.0836 10.5878 9.79264C10.5299 9.50166 10.5596 9.20006 10.6731 8.92597C10.7867 8.65189 10.9789 8.41762 11.2256 8.2528C11.4723 8.08797 11.7623 8 12.059 8C12.4568 8 12.8383 8.15804 13.1196 8.43934C13.4009 8.72064 13.559 9.10218 13.559 9.5Z"
                  fill="#8CA0EF"
                />
                <path
                  d="M19.559 14.001V19.001H5.55896V17.001L7.89996 13.101C7.97819 12.9706 8.0851 12.8598 8.21258 12.777C8.34005 12.6942 8.48472 12.6415 8.6356 12.623C8.78648 12.6045 8.9396 12.6207 9.08331 12.6702C9.22702 12.7198 9.35754 12.8014 9.46496 12.909L13.059 16.501L15.89 12.053C15.9703 11.9267 16.078 11.8201 16.205 11.741C16.332 11.6619 16.4752 11.6123 16.624 11.5959C16.7727 11.5795 16.9233 11.5967 17.0645 11.6462C17.2057 11.6957 17.334 11.7763 17.44 11.882L19.559 14.001Z"
                  fill="#8CA0EF"
                />
              </g>
            </svg>
            <div>사진</div>
          </div>
          <div v-if="item.PVData.feed_field == 'specialist'" class="fieldView">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0.5H4C2.067 0.5 0.5 2.067 0.5 4V20C0.5 21.933 2.067 23.5 4 23.5H20C21.933 23.5 23.5 21.933 23.5 20V4C23.5 2.067 21.933 0.5 20 0.5Z"
                stroke="#DBDBDB"
              />
              <path
                d="M11.999 11.9995C12.5721 11.9996 13.1396 11.8868 13.6692 11.6676C14.1987 11.4483 14.6798 11.1268 15.0851 10.7216C15.4903 10.3163 15.8118 9.83519 16.0311 9.30566C16.2503 8.77614 16.3631 8.20861 16.363 7.63548C16.3631 7.06236 16.2503 6.49483 16.0311 5.9653C15.8118 5.43578 15.4903 4.95464 15.0851 4.54938C14.6798 4.14412 14.1987 3.82267 13.6692 3.60341C13.1396 3.38414 12.5721 3.27135 11.999 3.27148C11.4259 3.27135 10.8583 3.38414 10.3288 3.60341C9.79927 3.82267 9.31813 4.14412 8.91287 4.54938C8.50761 4.95464 8.18615 5.43578 7.96689 5.9653C7.74762 6.49483 7.63485 7.06236 7.63498 7.63548C7.63485 8.20861 7.74762 8.77614 7.96689 9.30566C8.18615 9.83519 8.50761 10.3163 8.91287 10.7216C9.31813 11.1268 9.79927 11.4483 10.3288 11.6676C10.8583 11.8868 11.4259 11.9996 11.999 11.9995ZM11.999 14.1815C9.08599 14.1815 3.27197 15.6435 3.27197 18.5455V20.7275H20.727V18.5455C20.727 15.6435 14.912 14.1815 11.999 14.1815Z"
                fill="#8CA0EF"
              />
            </svg>

            <div>스페셜리스트</div>
          </div>
        </div>
      </div>
    </div>
    <div class="plusBtn" @click="plusFeedBtn">
      <span>더보기</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "userfeed",
  computed: {
    preview_data() {
      return this.$store.state.PFDataSet.preview_data_all;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
    feed_scroll() {
      return this.$store.state.scroll;
    },
  },
  created() {},
  mounted() {
    window.scrollTo(0, this.feed_scroll);
    let token = this.$cookie.get("access_token");
    let userID = this.$cookie.get("userID");
    let sessionToken = JSON.parse(sessionStorage.getItem("token"));

    if (sessionToken !== null || token !== null) {
      this.isLogin = true;
    } else if (sessionToken == null || token == null) {
      this.isLogin = false;
    }
    if (this.preview_data.length !== 0) {
      return;
    } else {
      if (this.isLogin == true) {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: sessionToken.userID,
                portfolios_field: this.fieldValue,
                currentFeedID: [],
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionToken.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                console.log(res);
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );
                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: userID,
                portfolios_field: this.fieldValue,
                currentFeedID: [],
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );
                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (this.isLogin == false) {
        this.$axios
          .post(this.API_URL + "/portfolios/non-login-print-feed", {
            portfolios_field: this.fieldValue,
            currentFeedID: [],
          })
          .then((res) => {
            if (res.data.status == 200) {
              let currentFeedID = res.data.currentFeedID;
              sessionStorage.setItem(
                "AllcurrentFeedID",
                JSON.stringify({
                  currentFeedID,
                })
              );
              for (let i in res.data.preview_data) {
                this.$store.commit(
                  "set_preview_data",
                  res.data.preview_data[i]
                );
              }
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  },

  data() {
    return {
      isLogin: false,
      // fieldValue: "all",
      fieldValue: localStorage.getItem("fieldValue") !== undefined ? localStorage.getItem("fieldValue") : "all",
    };
  },
  methods: {
    fieldSelect() {
      this.$store.commit("del_preview_data");
      localStorage.setItem("fieldValue", this.fieldValue);
      if (this.isLogin == true) {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: token.userID,
                portfolios_field: this.fieldValue,
                currentFeedID: [],
              },
              {
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );
                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");

          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: userID,
                portfolios_field: this.fieldValue,

                currentFeedID: [],
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );
                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (this.isLogin == false) {
        this.$axios
          .post(this.API_URL + "/portfolios/non-login-print-feed", {
            portfolios_field: this.fieldValue,
            currentFeedID: [],
          })
          .then((res) => {
            if (res.data.status == 200) {
              let currentFeedID = res.data.currentFeedID;
              sessionStorage.setItem(
                "AllcurrentFeedID",
                JSON.stringify({
                  currentFeedID,
                })
              );
              for (let i in res.data.preview_data) {
                this.$store.commit(
                  "set_preview_data",
                  res.data.preview_data[i]
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goOtherPF(Id) {
      this.$router.push({
        path: "/portfoliodetail/" + Id + "/PFWork",
      });
    },
    goPost(feed_id) {
      this.$store.commit("set_scroll", window.scrollY);
      this.$router.push({ path: "/postview/" + feed_id });
    },
    goFollow() {
      if (this.isLogin == true) {
        this.$router.push({ name: "userfeedfollow" });
      } else if (this.isLogin == false) {
        alert("로그인이 필요합니다.");
      }
    },
    plusFeedBtn() {
      if (this.isLogin == false) {
        alert("로그인이 필요합니다.");
      } else if (this.isLogin == true) {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let sessionToken = JSON.parse(sessionStorage.getItem("token"));
          let currentFeedID = JSON.parse(
            sessionStorage.getItem("AllcurrentFeedID")
          );
          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: sessionToken.userID,
                portfolios_field: this.fieldValue,
                currentFeedID: currentFeedID.currentFeedID,
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionToken.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );
                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          let currentFeedID = JSON.parse(
            sessionStorage.getItem("AllcurrentFeedID")
          );
          this.$axios
            .post(
              this.API_URL + "/portfolios/print-feed",
              {
                userID: userID,
                portfolios_field: this.fieldValue,
                currentFeedID: currentFeedID.currentFeedID,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                let currentFeedID = res.data.currentFeedID;
                sessionStorage.setItem(
                  "AllcurrentFeedID",
                  JSON.stringify({
                    currentFeedID,
                  })
                );

                for (let i in res.data.preview_data) {
                  this.$store.commit(
                    "set_preview_data",
                    res.data.preview_data[i]
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.PFFeedNav {
  width: 690px;
  height: 68px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}
.PFFeedNav div:first-child {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #8ca0ef;
  border-bottom: 2px solid #8ca0ef;
  margin-left: 32px;
}
.PFFeedNav2 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
  margin-left: 20px;
  cursor: pointer;
}
.feedcontents {
  background: #ffffff;
  margin-bottom: 40px;
}
.feedcontents-img {
  width: 630px;
  cursor: pointer;
  padding: 30px;
  padding-bottom: 0;
}
.feed_title {
  width: 532px;
  margin-left: 30px;
  font: normal normal bold 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.writerInfo {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  padding-bottom: 20px;
}
.writer {
  display: flex;
  align-items: center;
}
.writer span {
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
}
.writerImg {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 6px;
}
.fieldView {
  display: flex;
  align-items: center;
}
.fieldView div {
  font-family: Noto Sans CJK KR;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.68px;
  margin-left: 10px;
  color: #8ca0ef;
}
.plusBtn {
  width: 690px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  padding-top: 13px;
  cursor: pointer;
}
.plusBtn span {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #707070;
}
select {
  width: 140px;
  height: 36px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
  margin-left: 386px;
}
</style>
