<template>
  <div id="myprofile">
    <div v-if="fieldBox == true">
      <h2>활동 분야</h2>

      <div class="myfield">
        <div v-if="field.music.length !== 0">
          <div class="myfield_name">음악</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in field.music"
              :key="index"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="field.video.length !== 0">
          <div class="myfield_name">영상</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in field.video"
              :key="`video-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="field.photography.length !== 0">
          <div class="myfield_name">사진</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in field.photography"
              :key="`photography-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="field.design.length !== 0">
          <div class="myfield_name">디자인</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in field.design"
              :key="`design-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="field.specialist.length !== 0">
          <div class="myfield_name">스페셜리스트</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in field.specialist"
              :key="`specialist-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="interestsBox == true">
      <h2>키워드</h2>
      <div class="keyword">
        <div v-if="interests.music.length !== 0">
          <div class="myfield_name">음악</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in interests.music"
              :key="index"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="interests.video.length !== 0">
          <div class="myfield_name">영상</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in interests.video"
              :key="`video-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="interests.photography.length !== 0">
          <div class="myfield_name">사진</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in interests.photography"
              :key="`photography-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="interests.design.length !== 0">
          <div class="myfield_name">디자인</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in interests.design"
              :key="`design-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="interests.specialist.length !== 0">
          <div class="myfield_name">스페셜리스트</div>
          <div style="display: flex">
            <div
              v-for="(item, index) in interests.specialist"
              :key="`specialist-${index}`"
              class="myfield_item"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="introduction !== ''">
      <h2>소개</h2>
      <p class="introduce" v-html="handleNewLine(introduction)"></p>
    </div>

    <!-- <div class="EducationBox" v-if="schoolList.length !== 0"> -->
    <div class="EducationBox" v-if="visibleEducationBox">
      <h2>학력</h2>
      <div class="Education">
        <div
          v-for="(item, index) in schoolList"
          :key="index"
          class="EducationItem"
          v-show="item.open == true"
        >
          <div class="Education-info">
            <h4>{{ item.name }}</h4>
            <span>{{ item.class }}</span>
            <span>{{ item.dateS }}~{{ item.dateE }}</span>
            <span>{{ item.status }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="workExperienceBox" v-if="career !== ''">
      <h2>활동경력</h2>
      <div class="workExperience">
        <p v-html="handleNewLine(career)"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myprofile",
  mounted() {
    this.$axios
      .get(this.API_URL + "/accounts/profiles", {
        params: { userID: this.$route.params.id },
      })
      .then((res) => {
        console.log(res);
        this.field.music = res.data.profile_information.field.music;
        this.field.video = res.data.profile_information.field.video;
        this.field.photography = res.data.profile_information.field.photography;
        this.field.design = res.data.profile_information.field.design;
        this.field.specialist = res.data.profile_information.field.specialist;

        this.interests.music = res.data.profile_information.interests.music;
        this.interests.video = res.data.profile_information.interests.video;
        this.interests.photography =
          res.data.profile_information.interests.photography;
        this.interests.design = res.data.profile_information.interests.design;
        this.interests.specialist =
          res.data.profile_information.interests.specialist;
        this.introduction = res.data.profile_information.introduction;
        this.schoolList = res.data.profile_information.graduated_at.schoolList;
        this.career = res.data.profile_information.career;
        if (
          this.field.music.length !== 0 ||
          this.field.video.length !== 0 ||
          this.field.photography.length !== 0 ||
          this.field.design.length !== 0 ||
          this.field.specialist.length !== 0
        ) {
          this.fieldBox = true;
        }
        if (
          this.interests.music.length !== 0 ||
          this.interests.video.length !== 0 ||
          this.interests.photography.length !== 0 ||
          this.interests.design.length !== 0 ||
          this.interests.specialist.length != 0
        ) {
          this.interestsBox = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      field: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
      interests: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
      introduction: "",
      schoolList: [],
      career: "",
      fieldBox: false,
      interestsBox: false,
    };
  },
  computed: {
    profile() {
      return this.$store.state.PFData2.profile;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
    visibleEducationBox() {
        // schoolList에서 open이 true인 항목의 개수를 계산
        return this.schoolList.some(item => item.open);
    }
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#myprofile {
  margin-top: 30px;
  padding-bottom: 294px;
}
#myprofile h2 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 10px;
}

.introduce,
.workExperience,
.myfield,
.keyword {
  width: 1200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 20px;
  box-sizing: border-box;
}
.myfield,
.keyword {
  min-height: 40px;
  margin-bottom: 30px;
  flex-flow: wrap;
  padding: 20px 20px;
}
.myfield > div,
.keyword > div {
  margin-bottom: 20px;
}
.myfield > div:last-child,
.keyword > div:last-child {
  margin-bottom: 0;
}
.myfield_name {
  color: var(--c-main, #8ca0ef);
  font-family: Noto Sans CJK KR;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.myfield_item {
  color: var(--c-191919, #191919);
  font-family: Noto Sans CJK KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
}

.introduce {
  min-height: 136px;
  padding: 20px;
}

.EducationBox {
  margin-top: 30px;
}
.Education {
  width: 1200px;
  min-height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px 20px;
}
.EducationItem {
  margin-bottom: 10px;
}
.EducationItem:last-child {
  margin-bottom: 0px;
}

.Education h4 {
  font: normal normal normal 18px/24px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-right: 10px;
}
.Education-info {
  display: flex;
  align-items: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.Education-info span {
  margin-right: 10px;
}

.introduce p {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}

.workExperienceBox {
  margin-top: 30px;
}
.workExperience {
  min-height: 144px;
  padding: 20px;
}
</style>
