<template>
  <div class="userDelete">
    <div v-if="popup == true" class="popup">
      <div class="white-bg">
        <h2>확인</h2>
        <p>회원 탈퇴가 완료되었습니다.<br />메인 화면으로 이동합니다.</p>
        <div class="goHome" @click="goHome">확인</div>
      </div>
    </div>
    <h2>회원탈퇴</h2>
    <p>본인 인증을 위해<br />비밀번호를 입력해주세요.</p>
    <div class="inputBox">
      <h4>비밀번호</h4>
      <input
        type="password"
        placeholder="비밀번호를 입력하세요."
        v-model="password"
      />
      <span class="errMessage" v-if="passWordErr"
        >비밀번호 형식이 맞지 않습니다.</span
      >
    </div>
    <div class="goNext" @click="goNext">다음</div>
  </div>
</template>

<script>
export default {
  name: "userDelete",
  data() {
    return {
      password: "",
      passWordErr: false,
      popup: false,
    };
  },
  computed: {
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    goNext() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(
            "https://api-v2-test.rubatodev.com/account/connecting-information",
            {
              headers: { Authorization: `Bearer ${token.access_token}` },
            }
          )
          .then((res) => {
            this.$axios
              .delete(this.API_URL + "/accounts/user", {
                params: { userID: token.userID, userPW: this.password },
                headers: { Authorization: `Bearer ${token.access_token}` },
              })
              .then((res) => {
                if (res.data.status == 400) {
                  alert(res.data.msg);
                  this.password = "";
                } else if (res.data.status == 200) {
                  this.$cookie.delete("userID");
                  this.$cookie.delete("access_token");
                  this.$cookie.delete("refresh_token");
                  sessionStorage.clear();
                  localStorage.clear();
                  this.popup = true;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        const userID = this.$cookie.get("userID");
        const token = this.$cookie.get("access_token");
        this.$axios
          .delete(
            "https://api-v2-test.rubatodev.com/account/connecting-information",
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.$axios
              .delete(this.API_URL + "/accounts/user", {
                params: { userID: userID, userPW: this.password },
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                if (res.data.status == 400) {
                  alert(res.data.msg);
                  this.password = "";
                } else if (res.data.status == 200) {
                  this.$cookie.delete("userID");
                  this.$cookie.delete("access_token");
                  this.$cookie.delete("refresh_token");
                  sessionStorage.clear();
                  localStorage.clear();
                  this.popup = true;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goHome() {
      this.$router.replace({ path: "/" });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.userDelete {
  width: 500px;
  height: 403px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 96px 68px;
  text-align: center;
  padding: 0 58px;
}
.userDelete h2 {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
  margin: 40px 0;
}
.userDelete p {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 40px;
}
.inputBox {
  text-align: left;
}
.inputBox h4 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  margin-bottom: 5px;
}
.inputBox input {
  outline: none;
  border: none;
  border-bottom: 1px solid #707070;
  width: 384px;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
}
.inputBox input::placeholder {
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #dbdbdb;
}
.errMessage {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #cf1f1f;
}
.goNext {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding-top: 8px;
  cursor: pointer;
  margin: 35px auto;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
}
.white-bg {
  width: 500px;
  height: 252px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 300px auto;
  text-align: center;
}
.white-bg h2 {
  font: normal normal bold 24px/30px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #8ca0ef;
  padding-top: 40px;
  margin-bottom: 30px;
}
.white-bg p {
  font: normal normal normal 18px/30px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 25px;
}
.goHome {
  width: 100px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;

  font: normal normal normal 16px/30px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
}
</style>
