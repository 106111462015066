<template>
  <div>
    <div id="footerBar">
      <div class="footer-menu">
        <img src="../img/MainLogo.jpg" @click="goHome" />
        <span class="fooTitle"
          >RUBATO
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            @click="toggleBtn"
            v-show="fooSideBar == false"
          >
            <g
              id="그룹_20377"
              data-name="그룹 20377"
              transform="translate(-127 -10)"
            >
              <rect
                id="사각형_25522"
                data-name="사각형 25522"
                width="20"
                height="20"
                transform="translate(127 10)"
                fill="#fff"
              />
              <path
                id="패스_8197"
                data-name="패스 8197"
                d="M132.752,20.62l4-4,4,4"
                transform="translate(0.248 2.379)"
                fill="none"
                stroke="#707070"
                stroke-width="1"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            @click="toggleBtn"
            v-show="fooSideBar == true"
          >
            <g
              id="그룹_20377"
              data-name="그룹 20377"
              transform="translate(-127 -10)"
            >
              <rect
                id="사각형_25522"
                data-name="사각형 25522"
                width="20"
                height="20"
                transform="translate(127 10)"
                fill="#fff"
              />
              <path
                id="패스_8197"
                data-name="패스 8197"
                d="M132.752,16.62l4,4,4-4"
                transform="translate(0.248 2.379)"
                fill="none"
                stroke="#707070"
                stroke-width="1"
              />
            </g>
          </svg>
        </span>
        <div class="fooLine"></div>
        <router-link to="/info" class="footer-item">서비스 소개</router-link>
        <div class="fooLine"></div>
        <router-link to="/termsservice" class="footer-item"
          >이용약관</router-link
        >
        <div class="fooLine"></div>
        <router-link to="/privacyinfo" class="footer-item"
          >개인정보처리방침</router-link
        >
        <div class="fooLine"></div>
        <router-link to="/contactus" class="footer-item">문의하기</router-link>
        <div class="fooLine"></div>
      </div>
      <div class="footer-menu2">
        <a
          href="https://www.instagram.com/rubato.art/"
          class="instar"
          target="blank"
          ><svg
            id="그룹_20682"
            data-name="그룹 20682"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25657"
                  data-name="사각형 25657"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="그룹_20682-2"
              data-name="그룹 20682"
              clip-path="url(#clip-path)"
            >
              <path
                id="패스_8264"
                data-name="패스 8264"
                d="M7.03.084A8.753,8.753,0,0,0,4.119.648,5.873,5.873,0,0,0,2,2.035,5.878,5.878,0,0,0,.616,4.162,8.817,8.817,0,0,0,.064,7.076C.008,8.354,0,8.764,0,12.023S.022,15.69.084,16.97a8.772,8.772,0,0,0,.564,2.911A5.878,5.878,0,0,0,2.036,22a5.873,5.873,0,0,0,2.129,1.38,8.81,8.81,0,0,0,2.913.552c1.277.056,1.688.069,4.946.063s3.668-.021,4.948-.081a8.78,8.78,0,0,0,2.91-.563,6.133,6.133,0,0,0,3.5-3.516,8.777,8.777,0,0,0,.552-2.912c.056-1.281.069-1.69.063-4.948s-.021-3.667-.082-4.946a8.764,8.764,0,0,0-.563-2.912A5.891,5.891,0,0,0,21.966,2,5.856,5.856,0,0,0,19.838.617,8.781,8.781,0,0,0,16.925.065C15.647.009,15.236,0,11.977,0S8.31.022,7.03.084m.14,21.693a6.659,6.659,0,0,1-2.229-.408,3.737,3.737,0,0,1-1.382-.895,3.7,3.7,0,0,1-.9-1.378,6.646,6.646,0,0,1-.417-2.228c-.06-1.265-.072-1.644-.079-4.848s.005-3.583.061-4.848a6.641,6.641,0,0,1,.408-2.228,3.716,3.716,0,0,1,.895-1.382,3.7,3.7,0,0,1,1.378-.9,6.645,6.645,0,0,1,2.227-.417c1.266-.06,1.645-.072,4.848-.079s3.583.005,4.849.061a6.637,6.637,0,0,1,2.228.408,3.711,3.711,0,0,1,1.382.895,3.7,3.7,0,0,1,.9,1.379,6.611,6.611,0,0,1,.417,2.226c.06,1.266.074,1.645.08,4.848s-.006,3.583-.061,4.848a6.667,6.667,0,0,1-.408,2.229,3.974,3.974,0,0,1-2.274,2.281,6.647,6.647,0,0,1-2.226.417c-1.266.06-1.645.072-4.849.079s-3.583-.006-4.848-.061M16.953,5.587a1.44,1.44,0,1,0,1.438-1.442h0a1.44,1.44,0,0,0-1.437,1.442M5.839,12.012a6.162,6.162,0,1,0,6.15-6.174,6.161,6.161,0,0,0-6.15,6.174m2.161,0A4,4,0,1,1,12.008,16,4,4,0,0,1,8,12.008H8"
                transform="translate(0 0)"
              />
            </g>
          </svg>
        </a>
        <a
          href="https://www.youtube.com/@rubato.official"
          class="youtube"
          target="blank"
          ><svg
            id="그룹_20683"
            data-name="그룹 20683"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25655"
                  data-name="사각형 25655"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
            </defs>
            <rect
              id="사각형_25654"
              data-name="사각형 25654"
              width="24"
              height="24"
              rx="4"
            />
            <g id="그룹_20678" data-name="그룹 20678">
              <g
                id="그룹_20677"
                data-name="그룹 20677"
                clip-path="url(#clip-path)"
              >
                <path
                  id="패스_8262"
                  data-name="패스 8262"
                  d="M64.432,75.234a1.98,1.98,0,0,0-1.393-1.4,46.8,46.8,0,0,0-6.158-.332,46.8,46.8,0,0,0-6.158.332,1.98,1.98,0,0,0-1.393,1.4,22.3,22.3,0,0,0,0,7.636,1.981,1.981,0,0,0,1.393,1.4,46.807,46.807,0,0,0,6.158.331,46.807,46.807,0,0,0,6.158-.331,1.981,1.981,0,0,0,1.393-1.4,22.3,22.3,0,0,0,0-7.636M55.269,81.4V76.709l4.119,2.344Z"
                  transform="translate(-44.612 -66.918)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
    <transition name="fade">
      <div id="fooSideBar" v-show="fooSideBar == true">
        <span>상호 : 샤픈(Sharpen)</span>
        <span>사업자 등록번호 : 780-55-00770</span>
        <span>대표명 : 김정운</span>
        <span>대표 전화번호 : 070-4522-4917</span>
        <span
          >사업장 소재지 : 서울특별시 중구 퇴계로 36길 2 충무로관 905호</span
        >
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "footerBar",
  data() {
    return {
      fooSideBar: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    toggleBtn() {
      this.fooSideBar = !this.fooSideBar;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#footerBar {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;

  max-height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-top: 1px solid #dbdbdb;
}
#fooSideBar {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  position: fixed;
  bottom: 40px;
  max-height: 40px;
  min-height: 40px;
  border-top: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
}
#fooSideBar span {
  font: normal normal normal 12px/30px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #505050;
  margin-left: 30px;
}

#footerBar img {
  width: 35px;
  height: 35px;
  margin-left: 30px;
  margin-right: 6px;
  cursor: pointer;
}
.footer-menu {
  display: flex;
  align-items: center;
}
.fooTitle {
  font: normal normal normal 14px/30px Roboto;
  letter-spacing: -0.35px;
  color: #191919;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.fooTitle svg {
  cursor: pointer;
  margin-left: 4px;
}

.footer-item {
  margin: 0 15px;
  text-decoration: none;
  font: normal normal normal 14px/30px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
}
.fooLine {
  width: 1px;
  height: 12px;
  border-left: 1px solid #707070;
}
.instar,
.youtube {
  text-decoration: none;
  color: #505050;
}
.instar {
  margin-right: 20px;
}
.youtube {
  margin-right: 30px;
}
</style>
