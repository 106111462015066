const MCrecommend = {
  state() {
    return {
      scrapArtist: [],
      recommend_information: [],
      search_result: [],
      scrap_load: false,
      scrap_save: false,
      loadName: "",
      search_data: {},
    };
  },
  getters: {},
  mutations: {
    set_searchData(state, payload) {
      state.search_data.userID = payload.userID;
      state.search_data.key = payload.key;
      state.search_data.keywords = payload.keywords;
      state.search_data.values = payload.values;
      state.search_data.gender = payload.gender;
      state.search_data.page = payload.page;
    },
    reset_search_data(state, payload) {
      state.search_data.userID = payload;
      state.search_data.gender = "";
      state.search_data.key = "music";
      state.search_data.keywords = [];
      state.search_data.values = "보컬";
      state.search_data.page = 1;
    },
    set_loadScrapArtist(state, payload) {
      state.scrapArtist = [];
      let i;
      for (i = 0; i < Object.keys(payload.scraped_users).length; i++) {
        state.scrapArtist.push(payload.scraped_users[i]);
      }
      state.loadName = payload.name;
    },
    set_loadName(state, payload) {
      state.loadName = payload;
    },
    set_scrapArtist(state, payload) {
      state.scrapArtist.push(payload);
    },
    DELETE_SCRAP(state, payload) {
      let i;
      let e;
      for (e = 0; e < state.scrapArtist.length; e++) {
        if (state.scrapArtist[e].userID == payload.item.userID) {
          state.scrapArtist.splice(e, 1);
        }
        for (i = 0; i < Object.keys(state.recommend_information).length; i++) {
          if (state.recommend_information[i].userID == payload.item.userID) {
            state.recommend_information[i].my_scrap = false;
          }
        }
        for (i = 0; i < Object.keys(state.search_result).length; i++) {
          if (state.search_result[i].userID == payload.item.userID) {
            state.search_result[i].my_scrap = false;
          }
        }
      }
    },

    set_recommend_data(state, payload) {
      state.recommend_information = payload.recommend_information;
      let i;
      let e;
      for (i = 0; i < Object.keys(payload.recommend_information).length; i++) {
        state.recommend_information[i].my_scrap = false;
        for (e = 0; e < state.scrapArtist.length; e++) {
          if (
            state.recommend_information[i].userID == state.scrapArtist[e].userID
          ) {
            state.recommend_information[i].my_scrap = true;
          }
        }
      }
    },
    set_search_data(state, payload) {
      state.search_result = payload.search_result;
      let i;
      let e;
      for (i = 0; i < Object.keys(payload.search_result).length; i++) {
        state.search_result[i].my_scrap = false;
        for (e = 0; e < state.scrapArtist.length; e++) {
          if (state.search_result[i].userID == state.scrapArtist[e].userID) {
            state.search_result[i].my_scrap = true;
          }
        }
      }
    },
    set_recommend_scrap(state, payload) {
      state.recommend_information[payload].my_scrap = true;
    },
    set_search_scrap(state, payload) {
      state.search_result[payload].my_scrap = true;
    },
    set_recommend_unscrap(state, payload) {
      let i;
      let e;
      for (e = 0; e < state.scrapArtist.length; e++) {
        if (state.scrapArtist[e].userID == payload.item.userID) {
          state.scrapArtist.splice(e, 1);
        }
        for (i = 0; i < Object.keys(state.recommend_information).length; i++) {
          if (state.recommend_information[i].userID == payload.item.userID) {
            state.recommend_information[i].my_scrap = false;
          }
        }
      }
    },
    set_search_unscrap(state, payload) {
      let i;
      let e;
      for (e = 0; e < state.scrapArtist.length; e++) {
        if (state.scrapArtist[e].userID == payload.item.userID) {
          state.scrapArtist.splice(e, 1);
        }
        for (i = 0; i < Object.keys(state.search_result).length; i++) {
          if (state.search_result[i].userID == payload.item.userID) {
            state.search_result[i].my_scrap = false;
          }
        }
      }
    },
    scrap_loadToggle(state) {
      state.scrap_load = !state.scrap_load;
    },
    scrap_saveToggle(state) {
      state.scrap_save = !state.scrap_save;
    },
  },
  actions: {},
};

export default MCrecommend;
