import writepage from "@/components/myCommunity/writePage.vue";
import CMRetouch from "@/components/myCommunity/CMRetouch.vue";
import mainpage from "@/components/myCommunity/mainPage.vue";
import freepage from "@/components/myCommunity/freePage.vue";
import InquirePage from "@/components/myCommunity/InquirePage.vue";
import myCMPage from "@/components/myCommunity/myCMPage";
import inquireWrite from "@/components/myCommunity/inquireWrite.vue";
import freeDetail from "@/components/myCommunity/freeDetail.vue"
import noticeDetail from "@/components/myCommunity/noticeDetail.vue"

export default [
  {
    path: "/community",
    name: "community",
    component: () => import("../../views/community/CommunityView.vue"),
    children: [
      {
        path: "mainpage/:id",
        name: "mainpage",
        component: mainpage,
      },
      {
        path: "writepage",
        name: "writepage",
        component: writepage,
      },
      { 
        path: "cmretouch",
        name: "cmretouch",
        component: CMRetouch,
      },
      {
        path: "freepage/:id",
        name: "freepage",
        component: freepage,
      },   
      {
        path:"freeDetail/:id",
        name:"freeDetail",
        component:freeDetail
      },
      {
        path:"noticeDetail/:id",
        name:"noticeDetail",
        component:noticeDetail
      },
      {
        path: "Inquirepage/:id",
        name: "Inquirepage",
        component: InquirePage,
      },
      {
        path: "myCMPage/:id",
        name: "myCMPage",
        component: myCMPage,
      },
      {
        path: "inquirewrite",
        name: "inquirewrite",
        component: inquireWrite,
      },
    ],
  },
];
