<template>
  <div class="PFImgChange">
    <div class="wait-bg" v-if="waitPopup == true">
      <div class="wait-wg">업로드 중입니다.<br />잠시만 기다려주세요.</div>
    </div>
    <div class="white-bg">
      <h2>프로필 사진 설정</h2>

      <div class="imgBox2">
        <cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="image.src"
          :auto-zoom="true"
          :stencil-props="{
            handlers: {},
            movable: true,
            scalable: false,
          }"
          :stencil-size="{
            width: 282,
            height: 282,
          }"
          stencil-component="circle-stencil"
          image-restriction="stencil"
          @error="thumbnailErr"
        />
      </div>
      <div class="btns">
        <div @click="backBtn">이전</div>
        <div class="btn2" @click="save">적용</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
export default {
  components: {
    Cropper,
  },
  mounted() {
    if (this.PFChangeImg) {
      this.fileName = this.PFChangeImg.name;
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
      const blob = URL.createObjectURL(this.PFChangeImg);

      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = {
          src: blob,
          type: getMimeType(e.target.result, this.PFChangeImg.type),
        };
      };
      reader.readAsArrayBuffer(this.PFChangeImg);
    }
  },
  data() {
    return {
      image: {
        src: null,
        type: null,
      },
      fileName: "",
      waitPopup: false,
    };
  },
  computed: {
    PFChangeImg() {
      return this.$store.state.userSetup.PFChangeImg;
    },
    userInfo() {
      return this.$store.state.userSetup.userInfoSetup;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    thumbnailErr() {
      alert("썸네일을 다시 설정해주세요.");
      this.$router.go();
    },
    save() {
      this.waitPopup = true;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          const file = new File([blob], this.fileName);

          let loginUg = localStorage.getItem("loginUg");
          if (loginUg == "false") {
            const token = JSON.parse(sessionStorage.getItem("token"));
            const form = new FormData();
            form.append("userID", token.userID);
            form.append("profile_img", file);
            this.$axios
              .patch(this.API_URL + "/accounts/profile-img", form, {
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.data.status == 200) {
                  this.userInfo.profileImg = res.data.profile_img;
                  alert("프로필 이미지가 변경되었습니다.");
                  this.$router.go();
                } else {
                  alert(res.data.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (loginUg == "true") {
            let token = this.$cookie.get("access_token");
            let userID = this.$cookie.get("userID");
            const form = new FormData();
            form.append("userID", userID);
            form.append("profile_img", file);

            this.$axios
              .patch(this.API_URL + "/accounts/profile-img", form, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.data.status == 200) {
                  this.userInfo.profileImg = res.data.profile_img;
                  alert("프로필 이미지가 변경되었습니다.");
                  this.$router.go();
                } else {
                  alert(res.data.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }

          // 파일형식
        }, "image/jpeg");
      }
    },

    backBtn() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.PFImgChange {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 6;
}
.wait-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 11;
}
.wait-wg {
  width: 300px;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 350px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.white-bg {
  width: 460px;
  height: 554px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  margin: 203px auto;
  padding: 30px;
}
.white-bg h2 {
  margin-bottom: 20px;
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
}

.imgBox2 {
  width: 400px;
  height: 400px;
  margin-bottom: 20px;
}
.upload-example-cropper {
  width: 400px;
  height: 400px;
  border: 1px solid #000000;
  background: #ddd;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns div {
  width: 90px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 15px;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  cursor: pointer;
}
.btns div:last-child {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: none;
  color: #ffffff;
  margin-left: 20px;
}
</style>
