<template>
  <div>
    <div class="wait-bg" v-if="waitPopup == true">
      <div class="wait-wg">업로드 중입니다.<br />잠시만 기다려주세요.</div>
    </div>
    <div class="popup-bg">
      <div class="white-bg">
        <div class="contentsBox">
          <h4>썸네일</h4>
          <div class="imgSize">1 <i class="fas fa-times"></i> 1</div>
          <div class="imgBox" v-if="fileName == ''">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500"
              height="750"
              viewBox="0 0 500 750"
            >
              <g
                id="사각형_25193"
                data-name="사각형 25193"
                fill="none"
                stroke="#707070"
                stroke-width="2"
                stroke-dasharray="10 10"
              >
                <rect width="500" height="750" stroke="none" />
                <rect x="1" y="1" width="498" height="748" fill="none" />
              </g>
            </svg>
            <div class="imgBoxText">
              <button @click="$refs.file.click()">썸네일 업로드</button>
              <span
                >썸네일을 업로드하지 않으면<br />기본 이미지가 적용됩니다.</span
              >
            </div>
          </div>
          <div class="imgBox2" v-if="fileName !== ''">
            <cropper
              ref="cropper"
              class="upload-example-cropper"
              :src="image.src"
              :auto-zoom="true"
              :stencil-props="{
                handlers: {},
                movable: true,
                scalable: false,
              }"
              :stencil-size="{
                width: 421,
                height: 421,
              }"
              image-restriction="stencil"
              @error="thumbnailErr"
            />
          </div>

          <div class="contentsBoxBtns">
            <div class="backBtn" @click="backBtn">이전</div>
            <div class="loadingBtn">
              <button class="button" @click="$refs.file.click()">
                <input
                  type="file"
                  ref="file"
                  @change="loadImage($event)"
                  accept="image/*"
                  style="display: none"
                />
                썸네일 업로드
              </button>
            </div>
          </div>
        </div>
        <div class="contentsBox2">
          <h4>제목</h4>
          <div class="titleInput">
            <input
              type="text"
              v-model="uploadData.feed_title"
              placeholder="제목"
            />
          </div>
          <h4>작성자</h4>
          <div class="writerInput">
            <div class="writerList">
              <span>{{ this.$store.state.navStore.navData.nickname }}</span>
              <span></span>
            </div>
            <div></div>
          </div>
          <h4>분야</h4>
          <select v-model="fieldValue" @change="fieldSelect()">
            <option value="music" selected>음악</option>
            <option value="video">영상</option>
            <option value="photography">사진</option>
            <option value="design">디자인</option>
            <option value="specialist">스페셜리스트</option>
          </select>
          <h4>키워드</h4>
          <div class="keywordInput">
            <div class="keywordInputBox">
              <div class="keywordView">
                <span id="keywordViewNo" v-show="keywordList.length == 0">
                  추가하기를 눌러 어울리는 키워드를 추가해주세요.
                </span>
                <span
                  v-for="item in keywordList"
                  :key="item"
                  v-show="keywordList.length > 0"
                  >#{{ item }}</span
                >
              </div>
              <div class="keywordPlusBtn" @click="keywordBox_onoffBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="그룹_20319"
                    data-name="그룹 20319"
                    transform="translate(-295 -685)"
                  >
                    <rect
                      id="사각형_24940"
                      data-name="사각형 24940"
                      width="24"
                      height="24"
                      transform="translate(295 685)"
                      fill="none"
                    />
                    <path
                      id="패스_8055"
                      data-name="패스 8055"
                      d="M313.23,696.23h-5.46v-5.46a.77.77,0,0,0-1.54,0v5.46h-5.46a.77.77,0,1,0,0,1.54h5.46v5.46a.77.77,0,0,0,1.54,0v-5.46h5.46a.77.77,0,1,0,0-1.54Z"
                      transform="translate(0 0)"
                      fill="#707070"
                    />
                  </g>
                </svg>
                추가하기
              </div>
            </div>
            <div class="keywordSelect">
              <div
                class="keywordInput-music"
                v-if="
                  this.keywordBox_onoff == true && this.keyword_value == 'music'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21128"
                    data-name="그룹 21128"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26095"
                          data-name="사각형 26095"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21127"
                      data-name="그룹 21127"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8308"
                        data-name="패스 8308"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8309"
                        data-name="패스 8309"
                        d="M6,20.5A2.5,2.5,0,1,1,8.5,18,2.5,2.5,0,0,1,6,20.5"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8310"
                        data-name="패스 8310"
                        d="M6,16a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8311"
                        data-name="패스 8311"
                        d="M16,18.5A2.5,2.5,0,1,1,18.5,16,2.5,2.5,0,0,1,16,18.5"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8312"
                        data-name="패스 8312"
                        d="M16,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8313"
                        data-name="패스 8313"
                        d="M8,18V7.231L18,4V15.846"
                        fill="none"
                        stroke="#8ca0ef"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                  <span>음악</span>
                </div>
                <div class="music_box">
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-발라드"
                    value="발라드"
                  />
                  <label for="음악-발라드"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-알앤비"
                    value="알앤비"
                  />
                  <label for="음악-알앤비"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-팝"
                    value="팝"
                  />
                  <label for="음악-팝"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-어쿠스틱"
                    value="어쿠스틱"
                  />
                  <label for="음악-어쿠스틱"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-힙합"
                    value="힙합"
                  />
                  <label for="음악-힙합"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-재즈"
                    value="재즈"
                  />
                  <label for="음악-재즈"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-모던락"
                    value="모던락"
                  />
                  <label for="음악-모던락"></label>

                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-k-pop"
                    value="k-pop"
                  />
                  <label for="음악-k-pop"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-댄스"
                    value="댄스"
                  />
                  <label for="음악-댄스"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="음악-디스코"
                    value="디스코"
                  />
                  <label for="음악-디스코"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-video"
                v-if="
                  this.keywordBox_onoff == true && this.keyword_value == 'video'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21130"
                    data-name="그룹 21130"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26097"
                          data-name="사각형 26097"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21129"
                      data-name="그룹 21129"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8314"
                        data-name="패스 8314"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <rect
                        id="사각형_26096"
                        data-name="사각형 26096"
                        width="12"
                        height="7"
                        rx="0.5"
                        transform="translate(4.5 8.5)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8315"
                        data-name="패스 8315"
                        d="M16,15H5V9H16Zm0-7H5A1,1,0,0,0,4,9v6a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8316"
                        data-name="패스 8316"
                        d="M20,9l-3,3,3,3Z"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8317"
                        data-name="패스 8317"
                        d="M20,9l-3,3,3,3Z"
                        fill="none"
                        stroke="#8ca0ef"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                  <span>영상</span>
                </div>
                <div class="video_box">
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-액션"
                    value="액션"
                  />
                  <label for="영상-액션"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-코미디"
                    value="코미디"
                  />
                  <label for="영상-코미디"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-범죄"
                    value="범죄"
                  />
                  <label for="영상-범죄"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-공포"
                    value="공포"
                  />
                  <label for="영상-공포"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-로맨스"
                    value="로맨스"
                  />
                  <label for="영상-로맨스"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-모험"
                    value="모험"
                  />
                  <label for="영상-모험"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-스릴러"
                    value="스릴러"
                  />
                  <label for="영상-스릴러"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-다큐"
                    value="다큐"
                  />
                  <label for="영상-다큐"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-SF"
                    value="SF"
                  />
                  <label for="영상-SF"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-드라마"
                    value="드라마"
                  />
                  <label for="영상-드라마"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-가족"
                    value="가족"
                  />
                  <label for="영상-가족"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-판타지"
                    value="판타지"
                  />
                  <label for="영상-판타지"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-서스펜스"
                    value="서스펜스"
                  />
                  <label for="영상-서스펜스"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-웹드라마"
                    value="웹드라마"
                  />
                  <label for="영상-웹드라마"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-필름룩스"
                    value="필름룩스"
                  />
                  <label for="영상-필름룩스"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-컴퓨터그래픽"
                    value="컴퓨터그래픽"
                  />
                  <label for="영상-컴퓨터그래픽"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-애니메이션"
                    value="애니메이션"
                  />
                  <label for="영상-애니메이션"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-독립영화"
                    value="독립영화"
                  />
                  <label for="영상-독립영화"></label>

                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-유튜브"
                    value="유튜브"
                  />
                  <label for="영상-유튜브"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="영상-코레오"
                    value="코레오"
                  />
                  <label for="영상-코레오"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-photo"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.keyword_value == 'photography'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21132"
                    data-name="그룹 21132"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26098"
                          data-name="사각형 26098"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21131"
                      data-name="그룹 21131"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8318"
                        data-name="패스 8318"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8319"
                        data-name="패스 8319"
                        d="M19.559,19h-14V5h14Zm0-15h-14a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h14a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8320"
                        data-name="패스 8320"
                        d="M13.559,9.5a1.5,1.5,0,1,1-1.5-1.5,1.5,1.5,0,0,1,1.5,1.5"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8321"
                        data-name="패스 8321"
                        d="M19.559,14v5h-14V17L7.9,13.1a1,1,0,0,1,1.565-.192L13.059,16.5l2.831-4.448a1,1,0,0,1,1.55-.171Z"
                        transform="translate(-0.559)"
                        fill="#8ca0ef"
                      />
                    </g>
                  </svg>
                  <span>사진</span>
                </div>
                <div class="photo_box">
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-차가운"
                    value="차가운"
                  />
                  <label for="사진-차가운"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-따뜻한"
                    value="따뜻한"
                  />
                  <label for="사진-따뜻한"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-필름"
                    value="필름"
                  />
                  <label for="사진-필름"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-건물"
                    value="건물"
                  />
                  <label for="사진-건물"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-앨범"
                    value="앨범"
                  />
                  <label for="사진-앨범"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-아트워크"
                    value="아트워크"
                  />
                  <label for="사진-아트워크"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-예술"
                    value="예술"
                  />
                  <label for="사진-예술"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-누드"
                    value="누드"
                  />
                  <label for="사진-누드"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-모던"
                    value="모던"
                  />
                  <label for="사진-모던"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-자연"
                    value="자연"
                  />
                  <label for="사진-자연"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="사진-코스프레"
                    value="코스프레"
                  />
                  <label for="사진-코스프레"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-design"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.keyword_value == 'design'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    id="그룹_21138"
                    data-name="그룹 21138"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="사각형_26101"
                          data-name="사각형 26101"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect
                          id="사각형_26100"
                          data-name="사각형 26100"
                          width="11.484"
                          height="15.504"
                          transform="translate(10.115 5.557)"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="그룹_21137"
                      data-name="그룹 21137"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="패스_8322"
                        data-name="패스 8322"
                        d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                        fill="#dbdbdb"
                      />
                      <path
                        id="패스_8323"
                        data-name="패스 8323"
                        d="M7.864,4.575C4.454,5.968,3.116,10.588,4.509,14a6.633,6.633,0,0,0,.733,1.337.771.771,0,0,0,1.349-.182,2.352,2.352,0,0,1,1.944-1.422,2.3,2.3,0,0,1,2.042.857,2.351,2.351,0,0,1,.346,2.384.771.771,0,0,0,.814,1.091,6.735,6.735,0,0,0,1.469-.413c3.41-1.393,5.771-5.584,4.378-8.994s-6.31-5.474-9.72-4.081"
                        fill="#8ca0ef"
                      />
                      <g id="그룹_21136" data-name="그룹 21136">
                        <g
                          id="그룹_21135"
                          data-name="그룹 21135"
                          clip-path="url(#clip-path-2)"
                        >
                          <g id="그룹_21134" data-name="그룹 21134">
                            <g
                              id="그룹_21133"
                              data-name="그룹 21133"
                              clip-path="url(#clip-path-2)"
                            >
                              <path
                                id="패스_8324"
                                data-name="패스 8324"
                                d="M21.592,6.182c.186,1.5-3.182,6.606-5.512,9.351a.288.288,0,0,1,.039.352l-1.108,1.576a.268.268,0,0,1-.283.084A4.461,4.461,0,0,1,13.681,19.5,7.683,7.683,0,0,1,10.5,21.049a.319.319,0,0,1-.262-.037l-.047-.048a.379.379,0,0,1-.064-.338l.436-1.933a4.087,4.087,0,0,1,1.894-2.7.31.31,0,0,1-.07-.383L13.5,14.029a.28.28,0,0,1,.272-.086c1.789-3.109,5.466-8.037,6.96-8.362a.7.7,0,0,1,.582.093l.006.012a.7.7,0,0,1,.275.5M15.5,15.093c2.907-3.415,5.575-7.988,5.369-8.809-.835.1-4.3,4.218-6.477,8.03l0,.009L15.5,15.1Zm-.913,1.717.782-1.117-1.441-1.009L13.137,15.8Zm-.6.537a.555.555,0,0,1,.08-.2l-.963-.677a.661.661,0,0,1-.232.185,2.777,2.777,0,0,0-1.525,2.026l-.3,1.355A5.689,5.689,0,0,0,13.2,18.958a3.524,3.524,0,0,0,.792-1.611"
                                fill="#fff"
                              />
                              <path
                                id="패스_8325"
                                data-name="패스 8325"
                                d="M20.871,6.284c.2.82-2.462,5.394-5.369,8.809l0,.009-1.107-.779,0-.009c2.175-3.812,5.641-7.934,6.477-8.03"
                                fill="#8ca0ef"
                              />
                              <path
                                id="패스_8326"
                                data-name="패스 8326"
                                d="M15.371,15.693l-.782,1.117L13.138,15.8l.791-1.114Z"
                                fill="#8ca0ef"
                              />
                              <path
                                id="패스_8327"
                                data-name="패스 8327"
                                d="M14.074,17.142a.539.539,0,0,0-.08.2,3.548,3.548,0,0,1-.792,1.611,5.7,5.7,0,0,1-2.152,1.073l.3-1.355a2.78,2.78,0,0,1,1.526-2.026.67.67,0,0,0,.232-.185Z"
                                fill="#8ca0ef"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <path
                        id="패스_8328"
                        data-name="패스 8328"
                        d="M6,10.193a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8329"
                        data-name="패스 8329"
                        d="M6,8.693a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8330"
                        data-name="패스 8330"
                        d="M8.646,7.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8331"
                        data-name="패스 8331"
                        d="M8.647,6.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8332"
                        data-name="패스 8332"
                        d="M12,7.054a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8333"
                        data-name="패스 8333"
                        d="M12,5.554a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8334"
                        data-name="패스 8334"
                        d="M14.389,9.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8335"
                        data-name="패스 8335"
                        d="M14.389,8.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                      <path
                        id="패스_8336"
                        data-name="패스 8336"
                        d="M10.239,12.4a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                        fill="#fff"
                      />
                      <path
                        id="패스_8337"
                        data-name="패스 8337"
                        d="M10.239,10.9a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <span>디자인</span>
                </div>
                <div class="design_box">
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-드로잉"
                    value="드로잉"
                  />
                  <label for="디자인-드로잉"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-회화"
                    value="회화"
                  />
                  <label for="디자인-회화"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-콜라주"
                    value="콜라주"
                  />
                  <label for="디자인-콜라주"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-손그림"
                    value="손그림"
                  />
                  <label for="디자인-손그림"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-3D"
                    value="3D"
                  />
                  <label for="디자인-3D"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-목업디자인"
                    value="목업디자인"
                  />
                  <label for="디자인-목업디자인"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-모델링"
                    value="모델링"
                  />
                  <label for="디자인-모델링"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-브랜딩"
                    value="브랜딩"
                  />
                  <label for="디자인-브랜딩"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-키치"
                    value="키치"
                  />
                  <label for="디자인-키치"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-포토그래피"
                    value="포토그래피"
                  />
                  <label for="디자인-포토그래피"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="디자인-패키지"
                    value="패키지"
                  />
                  <label for="디자인-패키지"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
              <div
                class="keywordInput-specialist"
                v-if="
                  this.keywordBox_onoff == true &&
                  this.keyword_value == 'specialist'
                "
              >
                <div class="keywordBox-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="그룹_21244"
                      data-name="그룹 21244"
                      transform="translate(-740 -1401)"
                    >
                      <g
                        id="사각형_26093"
                        data-name="사각형 26093"
                        transform="translate(740 1401)"
                        fill="#fff"
                        stroke="#dbdbdb"
                        stroke-width="1"
                      >
                        <rect width="24" height="24" rx="4" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="3.5"
                          fill="none"
                        />
                      </g>
                      <rect
                        id="사각형_26094"
                        data-name="사각형 26094"
                        width="18"
                        height="18"
                        transform="translate(743.001 1404)"
                        fill="#fff"
                      />
                      <path
                        id="패스_8307"
                        data-name="패스 8307"
                        d="M336.394-159.375a4.363,4.363,0,0,0,4.364-4.364,4.363,4.363,0,0,0-4.364-4.364,4.363,4.363,0,0,0-4.364,4.364A4.363,4.363,0,0,0,336.394-159.375Zm0,2.182c-2.913,0-8.727,1.462-8.727,4.364v2.182h17.455v-2.182C345.122-155.731,339.307-157.193,336.394-157.193Z"
                        transform="translate(415.605 1572.375)"
                        fill="#8ca0ef"
                      />
                    </g>
                  </svg>

                  <span>스페셜리스트</span>
                </div>
                <div class="specialist_box">
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-드라마"
                    value="드라마"
                  />
                  <label for="스페셜리스트-드라마"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-영화"
                    value="영화"
                  />
                  <label for="스페셜리스트-영화"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-연극"
                    value="연극"
                  />
                  <label for="스페셜리스트-연극"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-뷰티"
                    value="뷰티"
                  />
                  <label for="스페셜리스트-뷰티"></label>

                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-패션"
                    value="패션"
                  />
                  <label for="스페셜리스트-패션"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-안무"
                    value="안무"
                  />
                  <label for="스페셜리스트-안무"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-브이로그"
                    value="브이로그"
                  />
                  <label for="스페셜리스트-브이로그"></label>
                  <input
                    type="checkbox"
                    v-model="keywordList"
                    id="스페셜리스트-코스프레"
                    value="코스프레"
                  />
                  <label for="스페셜리스트-코스프레"></label>
                </div>
                <div class="keywordChoiceBtn" @click="keywordBox_onoff = false">
                  선택완료
                </div>
              </div>
            </div>
          </div>

          <h4>공개범위 *</h4>
          <select
            name="publicScope"
            id="publicScope"
            v-model="uploadData.feed_range"
          >
            <option value="all">전체 공개</option>
            <option value="no">비공개</option>
            <option value="onlySearch">검색만 가능</option>
          </select>

          <div class="noticeBox">
            <span>*</span>
            <p class="notice">
              정보통신망에서 불법촬영물등을 유통할 경우 「전기통신사업법」
              제22조의5 제1항에 따른 삭제·접속차단 등 유통방지에 필요한 조치가
              취해질 수 있으며 관 련 법률에 따라 처벌받을 수 있습니다. 이에 따라
              루바토는 불법촬영물 등 위법성 게시물에 대해 무통보 삭제, 이용정지
              및 관련기관 신고 등의 추가 조치를 취할 수 있음을 알려드립니다.
              게시물 작성시 유의해주시기 바랍니다.
            </p>
          </div>

          <div class="contentsBoxBtns2">
            <div class="save" @click="save">게시</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throws } from "assert";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
export default {
  name: "PFUploadPopup",
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    console.log(this.writer);
  },
  components: {
    Cropper,
  },
  data() {
    return {
      keywordList: [],
      result: [],
      image: {
        src: null,
        type: null,
      },
      fileName: "",
      waitPopup: false,
      fieldValue: "music",
      keywordBox_onoff: false,
      keyword_value: "music",
    };
  },
  computed: {
    navStore() {
      return this.$store.state.navStore.navData;
    },
    uploadData() {
      return this.$store.state.uploadData;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    fieldSelect() {
      this.keywordList = [];
      this.keywordBox_onoff = false;
    },
    backBtn() {
      this.$router.go(-1);
    },
    thumbnailErr() {
      alert("썸네일을 다시 설정해주세요.");
    },

    save() {
      if (this.uploadData.feed_title == "") {
        alert("제목을 작성해주세요.");
      } else {
        this.waitPopup = true;
        console.log(this.uploadData);
        if (this.fileName !== "") {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            const formData = new FormData();
            canvas.toBlob((blob) => {
              const file = new File([blob], this.fileName);
              formData.append("feed_thumbnail", file);
              formData.append("feed_keyword", this.keywordList);
              formData.append("feed_title", this.uploadData.feed_title);
              formData.append("feed_range", this.uploadData.feed_range);
              formData.append("feed_text", this.uploadData.text);
              formData.append("feed_field", this.fieldValue);
              for (let i = 0; i < this.uploadData.files.length; i++) {
                formData.append("feed_contents", this.uploadData.files[i].file);
              }
              for (let i = 0; i < this.uploadData.link_url.length; i++) {
                formData.append(
                  "youtube_embed",
                  this.uploadData.link_url[i].url
                );
              }
              const loginUg = localStorage.getItem("loginUg");
              if (loginUg == "false") {
                const token = JSON.parse(sessionStorage.getItem("token"));
                formData.append("userID", token.userID);
                this.$axios
                  .post(this.API_URL + "/portfolios/upload-feed", formData, {
                    headers: {
                      Authorization: `Bearer ${token.access_token}`,
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((res) => {
                    if (res.data.status == 200) {
                      this.uploadData.files = [];
                      this.uploadData.feed_title = "";
                      alert("업로드를 완료했습니다.");
                      this.$router.replace({
                        path:
                          "/portfoliodetail/" +
                          this.navStore.userID +
                          "/PFWork",
                      });
                    } else if (res.data.status == 400) {
                      alert(res.data.msg);
                      this.waitPopup = false;
                    } else { // 400이 아닌 다른 상태코드가 올 경우
                      alert("업로드를 실패했습니다.");
                      this.waitPopup = false;
                      alert(res.data.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else if (loginUg == "true") {
                const token = this.$cookie.get("access_token");
                const userID = this.$cookie.get("userID");
                formData.append("userID", userID);
                this.$axios
                  .post(this.API_URL + "/portfolios/upload-feed", formData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((res) => {
                    if (res.data.status == 200) {
                      this.uploadData.files = [];
                      this.uploadData.feed_title = "";
                      alert("업로드를 완료했습니다.");
                      this.$router.replace({
                        path:
                          "/portfoliodetail/" +
                          this.navStore.userID +
                          "/PFWork",
                      });
                    } else if (res.data.status == 400) {
                      alert(res.data.msg);
                      this.waitPopup = false;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              // 파일형식
            }, "image/jpeg");
          }
        } else if (this.fileName == "") {
          const formData = new FormData();
          formData.append("feed_keyword", this.keywordList);
          formData.append("feed_title", this.uploadData.feed_title);
          formData.append("feed_range", this.uploadData.feed_range);
          formData.append("feed_field", this.fieldValue);
          formData.append("feed_text", this.uploadData.text);

          for (let i = 0; i < this.uploadData.files.length; i++) {
            formData.append("feed_contents", this.uploadData.files[i].file);
          }
          for (let i = 0; i < this.uploadData.link_url.length; i++) {
            formData.append("youtube_embed", this.uploadData.link_url[i].url);
          }
          const loginUg = localStorage.getItem("loginUg");
          if (loginUg == "false") {
            const token = JSON.parse(sessionStorage.getItem("token"));
            formData.append("userID", token.userID);

            this.$axios
              .post(this.API_URL + "/portfolios/upload-feed", formData, {
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.data.status == 200) {
                  this.uploadData.files = [];
                  this.uploadData.feed_title = "";
                  alert("업로드를 완료했습니다.");
                  this.$router.replace({
                    path:
                      "/portfoliodetail/" + this.navStore.userID + "/PFWork",
                  });
                } else if (res.data.status == 400) {
                  alert(res.data.msg);
                  this.waitPopup = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (loginUg == "true") {
            const cookieToken = this.$cookie.get("access_token");
            const userID = this.$cookie.get("userID");
            formData.append("userID", userID);

            this.$axios
              .post(this.API_URL + "/portfolios/upload-feed", formData, {
                headers: {
                  Authorization: `Bearer ${cookieToken}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.data.status == 200) {
                  this.uploadData.files = [];
                  this.uploadData.feed_title = "";
                  alert("업로드를 완료했습니다.");
                  this.$router.replace({
                    path:
                      "/portfoliodetail/" + this.navStore.userID + "/PFWork",
                  });
                } else if (res.data.status == 400) {
                  alert(res.data.msg);
                  this.waitPopup = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    },
    loadImage(event) {
      const { files } = event.target;
      this.fileName = files[0].name;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    keywordBox_onoffBtn() {
      this.keyword_value = "";
      this.keywordBox_onoff = !this.keywordBox_onoff;
      if (this.fieldValue == "music") {
        this.keyword_value = "music";
      } else if (this.fieldValue == "video") {
        this.keyword_value = "video";
      } else if (this.fieldValue == "photography") {
        this.keyword_value = "photography";
      } else if (this.fieldValue == "design") {
        this.keyword_value = "design";
      } else if (this.fieldValue == "specialist") {
        this.keyword_value = "specialist";
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wait-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 11;
}
.wait-wg {
  width: 300px;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 350px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
select {
  width: 94px;
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
select::-ms-expand {
  display: none; /*for IE10,11*/
}
.popup-bg {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 1;
  overflow: auto;
}

.white-bg {
  width: 1138px;
  height: 924px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 138px auto;
  opacity: 1;
  display: flex;
  padding: 30px;
  overflow: auto;
}

.imgSize {
  width: 60px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  padding-top: 2px;
  margin-bottom: 10px;
}
.imgBox {
  width: 500px;
  height: 750px;
  position: relative;
}
.imgBoxText {
  position: absolute;
  top: 343px;
  left: 165px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imgBoxText button {
  width: 130px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  text-align: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding-top: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
}
.imgBoxText span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  margin-bottom: 10px;
}
.imgBox2 {
  width: 500px;
  height: 750px;
  border: 1px solid #000000;
}
.contentsBoxBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.contentsBox h4,
.contentsBox2 h4 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 10px;
}
.contentsBox2 {
  position: relative;
}
.upload-example-cropper {
  width: 500px;
  height: 750px;
  border: 1px solid #000000;
  background: #ddd;
}
.backBtn {
  width: 90px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}

.loadingBtn {
  width: 130px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  text-align: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding: 3px;
  cursor: pointer;
}
.loadingBtn button {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
}
.contentsBox2 {
  margin-left: 50px;
}
.titleInput,
.writerInput,
.keywordInput {
  margin-bottom: 20px;
}

.titleInput input,
.writerList {
  width: 500px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #707070;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
.titleInput input::placeholder {
  color: #dbdbdb;
}

.writerList {
  position: relative;
  padding-top: 10px;
}
.PlusBtn {
  position: absolute;
  top: 5px;
  right: 0;
  width: 106px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

#publicScope {
  width: 168px;
  margin-bottom: 20px;
}
.noticeBox {
  display: flex;
  align-items: flex-start;
  position: absolute;
  bottom: 80px;
  left: 0;
}
.noticeBox span {
  margin-top: 3px;
  margin-right: 6px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #dbdbdb;
}
.noticeBox p {
  width: 438px;
  height: 100px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #dbdbdb;
}
.contentsBoxBtns2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -10px;
  right: 0;
}

.save {
  width: 90px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  text-align: center;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding: 3px;
  cursor: pointer;
}
select {
  width: 168px;
  height: 30px;
  padding-left: 10px;
  padding-top: 1px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/matchingarrow.png") no-repeat 97% 50%/15px auto;
  outline: none;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 20px;
}
select::-ms-expand {
  display: none; /*for IE10,11*/
}
.keywordInput {
  position: relative;
}
.keywordInputBox {
  width: 500px;
  height: 40px;
  border-bottom: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.keywordView {
  width: 365px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.keywordView span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-right: 8px;
}
#keywordViewNo {
  color: #dbdbdb;
}
.keywordPlusBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  cursor: pointer;
}
.keywordSelect {
  position: absolute;
  top: 50px;
  left: 0;
}
.keywordSelect input {
  display: none;
}
input + label {
  cursor: pointer;
}
input + label::before {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s;
}
input:checked + label::before {
  color: #8ca0ef;
}
.keywordInput-music,
.keywordInput-video,
.keywordInput-photo,
.keywordInput-design,
.keywordInput-specialist {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 10px;
}
.keywordBox-header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.keywordBox-header span {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-left: 10px;
}
.keywordInput-music {
  width: 500px;
  height: 216px;
}
.keywordInput-video {
  width: 500px;
  height: 284px;
}
.keywordInput-photo {
  width: 500px;
  height: 216px;
}
.keywordInput-design {
  width: 500px;
  height: 216px;
}
.keywordInput-specialist {
  width: 500px;
  height: 216px;
}
.music_box,
.video_box,
.photo_box,
.design_box,
.specialist_box {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-top: 10px;
  display: flex;
  flex-flow: wrap;
  padding: 0 20px;
  padding-top: 10px;
}
.keywordInput label {
  margin-right: 15px;
}
.music_box {
  width: 480px;
  height: 98px;
}
.video_box {
  width: 480px;
  height: 166px;
}
.photo_box {
  width: 480px;
  height: 98px;
}
.design_box {
  width: 480px;
  height: 98px;
}
.specialist_box {
  width: 480px;
  height: 98px;
}
.keywordChoiceBtn {
  width: 89px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-left: 385px;
  cursor: pointer;
}
input#음악-발라드 + label:before {
  content: "#발라드";
  width: 57px;
  height: 30px;
}
input#음악-알앤비 + label:before {
  content: "#알앤비";
  width: 57px;
  height: 30px;
}
input#음악-모던락 + label:before {
  content: "#모던락";
  width: 57px;
  height: 30px;
}
input#음악-힙합 + label:before {
  content: "#힙합";
  width: 42px;
  height: 30px;
}
input#음악-k-pop + label:before {
  content: "#k-pop";
  width: 53px;
  height: 30px;
}
input#음악-어쿠스틱 + label:before {
  content: "#어쿠스틱";
  width: 75px;
  height: 30px;
}
input#음악-재즈 + label:before {
  content: "#재즈";
  width: 42px;
  height: 30px;
}
input#음악-팝 + label:before {
  content: "#팝";
  width: 27px;
  height: 30px;
}
input#음악-디스코 + label:before {
  content: "#디스코";
  width: 57px;
  height: 30px;
}
input#음악-댄스 + label:before {
  content: "#댄스";
  width: 42px;
  height: 30px;
}
input#영상-액션 + label:before {
  content: "#액션";
  width: 42px;
  height: 30px;
}
input#영상-코미디 + label:before {
  content: "#코미디";
  width: 57px;
  height: 30px;
}
input#영상-공포 + label:before {
  content: "#공포";
  width: 42px;
  height: 30px;
}
input#영상-로맨스 + label:before {
  content: "#로맨스";
  width: 57px;
  height: 30px;
}
input#영상-모험 + label:before {
  content: "#모험";
  width: 42px;
  height: 30px;
}
input#영상-범죄 + label:before {
  content: "#범죄";
  width: 42px;
  height: 30px;
}
input#영상-드라마 + label:before {
  content: "#드라마";
  width: 57px;
  height: 30px;
}
input#영상-스릴러 + label:before {
  content: "#스릴러";
  width: 57px;
  height: 30px;
}
input#영상-다큐 + label:before {
  content: "#다큐";
  width: 42px;
  height: 30px;
}
input#영상-SF + label:before {
  content: "#SF";
  width: 31px;
  height: 30px;
}
input#영상-판타지 + label:before {
  content: "#판타지";
  width: 57px;
  height: 30px;
}
input#영상-가족 + label:before {
  content: "#가족";
  width: 42px;
  height: 30px;
}
input#영상-애니메이션 + label:before {
  content: "#애니메이션";
  width: 89px;
  height: 30px;
}
input#영상-독립영화 + label:before {
  content: "#독립영화";
  width: 73px;
  height: 30px;
}
input#영상-서스펜스 + label:before {
  content: "#서스펜스";
  width: 72px;
  height: 30px;
}
input#영상-유튜브 + label:before {
  content: "#유튜브";
  width: 57px;
  height: 30px;
}
input#영상-코레오 + label:before {
  content: "#코레오";
  width: 57px;
  height: 30px;
}
input#영상-웹드라마 + label:before {
  content: "#웹드라마";
  width: 73px;
  height: 30px;
}
input#영상-필름룩스 + label:before {
  content: "#필름룩스";
  width: 73px;
  height: 30px;
}
input#영상-컴퓨터그래픽 + label:before {
  content: "#컴퓨터그래픽";
  width: 104px;
  height: 30px;
}
input#사진-차가운 + label:before {
  content: "#차가운";
  width: 57px;
  height: 30px;
}
input#사진-따뜻한 + label:before {
  content: "#따뜻한";
  width: 57px;
  height: 30px;
}
input#사진-필름 + label:before {
  content: "#필름";
  width: 42px;
  height: 30px;
}
input#사진-건물 + label:before {
  content: "#건물";
  width: 42px;
  height: 30px;
}
input#사진-앨범 + label:before {
  content: "#앨범";
  width: 42px;
  height: 30px;
}
input#사진-아트워크 + label:before {
  content: "#아트워크";
  width: 72px;
  height: 30px;
}
input#사진-예술 + label:before {
  content: "#예술";
  width: 42px;
  height: 30px;
}
input#사진-누드 + label:before {
  content: "#누드";
  width: 42px;
  height: 30px;
}
input#사진-모던 + label:before {
  content: "#모던";
  width: 42px;
  height: 30px;
}
input#사진-자연 + label:before {
  content: "#자연";
  width: 42px;
  height: 30px;
}
input#사진-코스프레 + label:before {
  content: "#코스프레";
  width: 72px;
  height: 30px;
}
input#디자인-드로잉 + label:before {
  content: "#드로잉";
  width: 57px;
  height: 30px;
}
input#디자인-회화 + label:before {
  content: "#회화";
  width: 42px;
  height: 30px;
}
input#디자인-콜라주 + label:before {
  content: "#콜라주";
  width: 57px;
  height: 30px;
}
input#디자인-손그림 + label:before {
  content: "#손그림";
  width: 57px;
  height: 30px;
}
input#디자인-3D + label:before {
  content: "#3D";
  width: 32px;
  height: 30px;
}
input#디자인-목업디자인 + label:before {
  content: "#목업디자인";
  width: 88px;
  height: 30px;
}
input#디자인-모델링 + label:before {
  content: "#모델링";
  width: 57px;
  height: 30px;
}
input#디자인-브랜딩 + label:before {
  content: "#브랜딩";
  width: 57px;
  height: 30px;
}
input#디자인-포토그래피 + label:before {
  content: "#포토그래피";
  width: 88px;
  height: 30px;
}
input#디자인-패키지 + label:before {
  content: "#패키지";
  width: 57px;
  height: 30px;
}
input#디자인-키치 + label:before {
  content: "#키치";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-드라마 + label:before {
  content: "#드라마";
  width: 57px;
  height: 30px;
}

input#스페셜리스트-영화 + label:before {
  content: "#영화";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-연극 + label:before {
  content: "#연극";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-뷰티 + label:before {
  content: "#뷰티";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-코스프레 + label:before {
  content: "#코스프레";
  width: 73px;
  height: 30px;
}
input#스페셜리스트-패션 + label:before {
  content: "#패션";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-안무 + label:before {
  content: "#안무";
  width: 42px;
  height: 30px;
}
input#스페셜리스트-브이로그 + label:before {
  content: "#브이로그";
  width: 73px;
  height: 30px;
}
</style>
