const postData = {
  state() {
    return {
      postData: {
        writer: {
          profileImg: "https://source.unsplash.com/random",
          title: "기타치는 멋쟁이 백승연 짱짱걸 뀨",
          postImg: [
            "https://source.unsplash.com/random",
            "https://source.unsplash.com/random",
          ],
          postId: "1",
          like: true,
          likeCount: "506",
          nickName: "김아무개",
          field: "음악",
          role: "기타",
          postField: "음악",
          postRole: "기타",
        },
        comments: [
          {
            commentId: "1",
            postId: "1",
            text: "헐 대박이다진짜<br>줄바꿈테스트<br>한글로 ",
            writerImg: "https://source.unsplash.com/random",
            writerName: "백승연",
            edit: false,
            created_at: "16:35",
          },
          {
            commentId: "2",
            postId: "1",
            text: "헐 대박이다진짜 ",
            writerImg: "https://source.unsplash.com/random",
            writerName: "_picslike",
            edit: true,

            created_at: "16:35",
          },
          {
            commentId: "3",
            postId: "1",
            text: "헐 대박이다진짜 ",
            writerImg: "https://source.unsplash.com/random",
            writerName: "오승연",
            edit: false,

            created_at: "16:35",
          },
          {
            commentId: "4",
            postId: "1",
            text: "헐 대박이다진짜 ",
            writerImg: "https://source.unsplash.com/random",
            writerName: "_picslike",
            edit: true,

            created_at: "16:35",
          },
        ],
      },
      comments_information: {},
      my_information: {},
      my_feed: false,
      imgView: false,
      feeds: [],
    };
  },
  getters: {},
  mutations: {
    SET_IMGVIEW(state) {
      state.imgView = !state.imgView;
    },
    set_myFeed(state, payload) {
      state.my_feed = payload;
    },
    set_comments_information(state, payload) {
      state.comments_information = payload;
    },
    set_my_information(state, payload) {
      state.my_information.my_nickname = payload.my_nickname;
      state.my_information.my_profile_img = payload.my_profile_img;
    },
    set_feeds(state, payload) {
      state.feeds = payload;
    },
  },
  actions: {},
};

export default postData;
