<template>
  <div id="black-bg">
    <div class="last_finish" v-if="make_baskit == true">
      <div class="title2">스크랩 목록</div>
      <div class="ment">
        제목을 입력하세요.<span>입력 안하면 날짜로 저장</span>
      </div>
      <input type="text" v-model="title" :placeholder="today_date" />
      <div class="btns2">
        <div @click="save_list">만들기</div>
        <div @click="scrap_loadBtn">취소</div>
      </div>
    </div>
    <div class="white-bg" v-else-if="make_baskit == false">
      <div class="title">스크랩 목록</div>
      <div class="baskitBox">
        <div class="new_baskit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            style="cursor: pointer"
            @click="make_baskit = !make_baskit"
          >
            <g
              id="그룹_21033"
              data-name="그룹 21033"
              transform="translate(-787 -311)"
            >
              <g
                id="사각형_25990"
                data-name="사각형 25990"
                transform="translate(787 311)"
                fill="#fff"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="60" height="60" rx="4" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="59"
                  height="59"
                  rx="3.5"
                  fill="none"
                />
              </g>
              <g
                id="사각형_26012"
                data-name="사각형 26012"
                transform="translate(815 326)"
                fill="#707070"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="4" height="30" rx="2" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="3"
                  height="29"
                  rx="1.5"
                  fill="none"
                />
              </g>
              <g
                id="사각형_26013"
                data-name="사각형 26013"
                transform="translate(832 339) rotate(90)"
                fill="#707070"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="4" height="30" rx="2" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="3"
                  height="29"
                  rx="1.5"
                  fill="none"
                />
              </g>
            </g>
          </svg>
          <div>새로 저장하기</div>
        </div>
        <div
          v-for="(item, index) in scrap_information"
          :key="index"
          class="baskit-list"
          @click="selectBox(item, index)"
          :id="item.name"
        >
          <div class="imgBox">
            <div v-if="item.scraped_users[0]">
              <img :src="item.scraped_users[0].profile_image" alt="" />
            </div>
            <div v-if="item.scraped_users[1]">
              <img :src="item.scraped_users[1].profile_image" alt="" />
            </div>
            <div v-if="item.scraped_users[2]">
              <img :src="item.scraped_users[2].profile_image" alt="" />
            </div>
            <div v-if="item.scraped_users[3]">
              <img :src="item.scraped_users[3].profile_image" alt="" />
            </div>
          </div>
          <div class="baskit-info">
            <div>{{ item.name }}</div>
            <div>{{ Object.keys(item.scraped_users).length }} 명</div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="btn_onoffBtn(index)"
          >
            <g
              id="그룹_21025"
              data-name="그룹 21025"
              transform="translate(-1102 -324)"
            >
              <rect
                id="사각형_26000"
                data-name="사각형 26000"
                width="30"
                height="30"
                transform="translate(1102 324)"
                fill="none"
              />
              <g id="그룹_21024" data-name="그룹 21024">
                <g
                  id="타원_289"
                  data-name="타원 289"
                  transform="translate(1104 336)"
                  fill="#707070"
                  stroke="#707070"
                  stroke-width="1"
                >
                  <circle cx="3" cy="3" r="3" stroke="none" />
                  <circle cx="3" cy="3" r="2.5" fill="none" />
                </g>
                <g
                  id="타원_290"
                  data-name="타원 290"
                  transform="translate(1114 336)"
                  fill="#707070"
                  stroke="#707070"
                  stroke-width="1"
                >
                  <circle cx="3" cy="3" r="3" stroke="none" />
                  <circle cx="3" cy="3" r="2.5" fill="none" />
                </g>
                <g
                  id="타원_291"
                  data-name="타원 291"
                  transform="translate(1124 336)"
                  fill="#707070"
                  stroke="#707070"
                  stroke-width="1"
                >
                  <circle cx="3" cy="3" r="3" stroke="none" />
                  <circle cx="3" cy="3" r="2.5" fill="none" />
                </g>
              </g>
            </g>
          </svg>
          <div
            class="baskit-delBtn"
            v-show="hoverBox[index].active"
            @click="del_baskit(item)"
          >
            삭제
          </div>
        </div>
      </div>
      <div class="btns">
        <div @click="select_save">저장</div>
        <div @click="scrap_loadBtn">닫기</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    let today = new Date();
    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    let date = today.getDate();
    this.today_date = year + "." + month + "." + date;
    let loginUg = localStorage.getItem("loginUg");
    if (loginUg == "false") {
      let token = JSON.parse(sessionStorage.getItem("token"));
      this.$axios
        .get(this.API_URL + "/search/scrap", {
          params: {
            userID: token.userID,
          },
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        })
        .then((res) => {
          this.scrap_information = res.data.scrap_information;
          let i;
          for (i = 0; i < Object.keys(this.scrap_information).length; i++) {
            this.hoverBox.push({ active: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (loginUg == "true") {
      let token = this.$cookie.get("access_token");
      let userID = this.$cookie.get("userID");
      this.$axios
        .get(this.API_URL + "/search/scrap", {
          params: {
            userID: userID,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.scrap_information = res.data.scrap_information;
          let i;
          for (i = 0; i < Object.keys(this.scrap_information).length; i++) {
            this.hoverBox.push({ active: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    scrapAT() {
      return this.$store.state.MCrecommend.scrapArtist;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  data() {
    return {
      make_baskit: false,
      title: "",
      today_date: "",
      scrap_information: [],
      hoverBox: [],
      select_baskit: [],
    };
  },
  methods: {
    scrap_loadBtn() {
      this.$store.commit("scrap_saveToggle");
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
    },
    save_list() {
      let users = [];
      let title = "";
      if (this.title == "") {
        title = this.today_date;
      } else {
        title = this.title;
      }
      let i;
      for (i = 0; i < this.scrapAT.length; i++) {
        users.push(this.scrapAT[i].userID);
      }

      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/search/scrap",
            {
              userID: token.userID,
              scrap_name: title,
              target: users,
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              alert("스크랩목록을 저장했습니다.");
              this.$store.commit("set_loadName", title);
              this.$store.commit("scrap_saveToggle");

              document.getElementsByTagName("body")[0].style.overflow =
                "scroll";
            }
          })
          .catch((err) => {
            console.log(err);
            alert("같은이름의 스크랩 목록을 만들수 없습니다.");
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/search/scrap",
            {
              userID: userID,
              scrap_name: title,
              target: users,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              alert("스크랩목록을 저장했습니다.");
              this.$store.commit("scrap_saveToggle");
              document.getElementsByTagName("body")[0].style.overflow =
                "scroll";
            }
          })
          .catch((err) => {
            console.log(err);
            alert("같은이름의 스크랩 목록을 만들수 없습니다.");
          });
      }
    },
    btn_onoffBtn(idx) {
      this.hoverBox[idx].active = !this.hoverBox[idx].active;
    },
    del_baskit(item) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(this.API_URL + "/search/scrap", {
            params: {
              userID: token.userID,
              scrap_id: item.scrap_id,
              name: item.name,
            },
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$router.go();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .delete(this.API_URL + "/search/scrap", {
            params: {
              userID: userID,
              scrap_id: item.scrap_id,
              name: item.name,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$router.go();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectBox(item, idx) {
      let list = document.getElementsByClassName("baskit-list");
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i].classList.length == 2) {
          list[i].classList.remove("select-list");
        }
      }
      document.getElementById(item.name).className += " select-list";
      this.select_baskit = item;
    },
    select_save() {
      let users = [];
      console.log(this.select_baskit.scrap_id);
      if (this.select_baskit == "") {
        alert("저장할 폴더를 골라주세요.");
      } else {
        let i;
        for (i = 0; i < this.scrapAT.length; i++) {
          users.push(this.scrapAT[i].userID);
        }
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .patch(
              this.API_URL + "/search/scrap",
              {
                userID: token.userID,
                name: this.select_baskit.name,
                scrap_id: this.select_baskit.scrap_id,
                target: users,
              },
              {
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert("스크랩목록을 저장했습니다.");
                this.$store.commit("set_loadName", this.select_baskit.name);
                this.$store.commit("scrap_saveToggle");

                document.getElementsByTagName("body")[0].style.overflow =
                  "scroll";
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .patch(
              this.API_URL + "/search/scrap",
              {
                userID: userID,
                name: this.select_baskit.name,
                scrap_id: this.select_baskit.scrap_id,
                target: users,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert("스크랩목록을 저장했습니다.");
                this.$store.commit("set_loadName", this.select_baskit.name);
                this.$store.commit("scrap_saveToggle");

                document.getElementsByTagName("body")[0].style.overflow =
                  "scroll";
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#black-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background: rgba(0, 0, 0, 0.9);
}
.white-bg {
  width: 420px;
  height: 432px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 150px auto;
  padding: 30px;
}
.last_finish {
  width: 420px;
  height: 274px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 150px auto;
  padding: 30px;
}
.title {
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #8ca0ef;
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #707070;
}
.title2 {
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #8ca0ef;
  text-align: center;
  padding-bottom: 30px;
}
.new_baskit {
  width: 360px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.new_baskit div {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
  margin-left: 20px;
}

.baskitBox {
  height: 240px;
  padding: 15px 0;
  overflow: auto;
  scrollbar-width: none;
}
.baskitBox::-webkit-scrollbar {
  display: none;
}
.baskit-list {
  width: 360px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
}
.select-list {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  padding-top: 30px;
}
.btns2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btns div,
.btns2 div {
  width: 170px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btns div:last-child,
.btns2 div:last-child {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #505050;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #505050;
  margin-left: 20px;
}
.ment {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
  margin-bottom: 20px;
}
.ment span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
}
.last_finish input {
  width: 360px;
  height: 40px;
  padding-left: 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.imgBox {
  width: 60px;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
}
.imgBox div > img {
  width: 30px;
  height: 30px;
}
.baskit-info {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.baskit-info div {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
}
.baskit-info div:last-child {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 3px;
}
.baskit-list svg {
  margin-left: 25px;
  cursor: pointer;
}
.baskit-delBtn {
  width: 90px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #cf1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45px;
  right: 0;
  cursor: pointer;
}
</style>
