import profileSetup from "@/components/userSetup/profileSetup";
import userInfoSetup from "@/components/userSetup/userInfoSetup";
import userInfoCheck from "@/components/userSetup/userInfoCheck";
import userDelete from "@/components/userSetup/userDelete";
import userDelete2 from "@/components/userSetup/userDelete2";
import educationSet from "@/components/userSetup/educationSet";
import educationRetouch from "@/components/userSetup/educationRetouch";
import saveFinish from "@/components/userSetup/saveFinish";
import setField from "@/components/userSetup/setField";
import setKeyword from "@/components/userSetup/setKeyword";

export default [
  {
    path: "/usersetting",
    name: "usersetting",
    component: () => import("@/views/userSettingView"),
    children: [
      {
        path: "profileset",
        name: "profileset",
        component: profileSetup,
        children: [
          {
            path: "educationset",
            name: "educationset",
            component: educationSet,
          },
          {
            path: "educationretouch",
            name: "educationretouch",
            component: educationRetouch,
          },
          {
            path: "savefinish",
            name: "savefinish",
            component: saveFinish,
          },
          {
            path: "setfield",
            name: "setfield",
            component: setField,
          },
          {
            path: "setkeyword",
            name: "setkeyword",
            component: setKeyword,
          },
        ],
      },
      {
        path: "userinfoset",
        name: "userinfoset",
        component: userInfoSetup,
      },
      {
        path: "userinfocheck",
        name: "userinfocheck",
        component: userInfoCheck,
      },
      {
        path: "userdelete",
        name: "userdelete",
        component: userDelete,
      },
      {
        path: "userdelete2",
        name: "userdelete2",
        component: userDelete2,
      },
    ],
  },
];
