<template>
  <div id="pushArtist">
    <h2 class="titleText">다양한 분야의 아티스트들을 만나보세요!</h2>
    <div class="contentsView">
      <div v-if="pushPost == 'pushPost_all'" class="items">
        <div
          v-for="(item, index) in portfolios"
          :key="index"
          class="item"
          @mouseover="hover(index)"
          @mouseleave="hoverleave"
        >
          <img
            :src="item.thumbnail_image"
            alt=""
            @click="goPost(item.feed_id)"
          />
          <div
            class="hoverBox"
            :class="{ columnHoverEffect: columnHoverCheck(index) }"
            @click="goPost2(item.feed_id)"
            style="cursor: pointer"
          >
            <img
              :src="item.profile_image"
              alt=""
              @click="goOtherPF(item.userID)"
            />
            <span class="hoverBox-name" @click="goOtherPF(item.userID)">{{
              item.nickname
            }}</span>
            <!-- <div class="btnBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                class="likeBtn"
                v-show="item.like == true"
                @click="unLikeBtn(item.feed_id, index)"
              >
                <g
                  id="그룹_19680"
                  data-name="그룹 19680"
                  transform="translate(-102 -685)"
                >
                  <rect
                    id="사각형_24938"
                    data-name="사각형 24938"
                    width="30"
                    height="30"
                    transform="translate(102 685)"
                    fill="none"
                  />
                  <path
                    id="패스_8051"
                    data-name="패스 8051"
                    d="M117,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,117,688Z"
                    fill="#fff"
                  />
                  <path
                    id="_패스_7622-2"
                    d="M118,695.7l-1,1.2-1-1.2a3.1,3.1,0,0,0-4.5-.4c-.2.1-.3.3-.4.4h0a4.392,4.392,0,0,0,0,5.7l6,6.2,6-6.2a4.392,4.392,0,0,0,0-5.7h0a3.1,3.1,0,0,0-4.5-.4,1.275,1.275,0,0,0-.6.4Z"
                    fill="#ff3120"
                  />
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                class="likeBtn"
                v-show="item.like == false"
                @click="likeBtn(item.feed_id, index)"
              >
                <g
                  id="그룹_19680"
                  data-name="그룹 19680"
                  transform="translate(-102 -685)"
                >
                  <rect
                    id="사각형_24938"
                    data-name="사각형 24938"
                    width="30"
                    height="30"
                    transform="translate(102 685)"
                    fill="none"
                  />
                  <path
                    id="패스_8051"
                    data-name="패스 8051"
                    d="M117,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,117,688Z"
                    fill="#fff"
                  />
                  <path
                    id="_패스_7622-2"
                    d="M118,695.7l-1,1.2-1-1.2a3.1,3.1,0,0,0-4.5-.4c-.2.1-.3.3-.4.4h0a4.392,4.392,0,0,0,0,5.7l6,6.2,6-6.2a4.392,4.392,0,0,0,0-5.7h0a3.1,3.1,0,0,0-4.5-.4,1.275,1.275,0,0,0-.6.4Z"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                </g>
              </svg>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pushArtist",
  mounted() {
    this.$axios
      .get(this.API_URL + "/search/recommend", {})
      .then((res) => {
        this.portfolios = res.data.portfolios;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      pushPost: "pushPost_all",
      hoverBar: null,
      portfolios: [],
      imgChkBtn: false,
    };
  },
  computed: {
    pushPost_all() {
      return this.$store.state.MCData.pushArtist_all;
    },
    pushPost_photo() {
      return this.$store.state.MCData.pushArtist_photo;
    },
    pushPost_video() {
      return this.$store.state.MCData.pushArtist_video;
    },
    pushPost_music() {
      return this.$store.state.MCData.pushArtist_music;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    hover(index) {
      this.hoverBar = index;
    },
    hoverleave() {
      this.hoverBar = null;
    },
    columnHoverCheck(index) {
      if (index === this.hoverBar) {
        return true;
      } else {
        return false;
      }
    },
    goPost(userId) {
      this.$router.push({ path: "/postview/" + userId });
    },
    goPost2(userId) {
      if (this.imgChkBtn == true) {
        this.imgChkBtn = false;
      } else {
        this.$router.push({ path: "/postview/" + userId });
        this.imgChkBtn = false;
      }
    },
    goOtherPF(Id) {
      this.imgChkBtn = true;
      window.open("/portfoliodetail/" + Id + "/PFWork");
    },
    // likeBtn(feed_id, index) {
    //   let loginUg = localStorage.getItem("loginUg");
    //   if (loginUg == "false") {
    //     let token = JSON.parse(sessionStorage.getItem("token"));
    //     this.$axios
    //       .post(
    //         this.API_URL + "/portfolios/feed-like",
    //         { userID: token.userID, feed_id: feed_id },
    //         {
    //           headers: { Authorization: `Bearer ${token.access_token}` },
    //         }
    //       )
    //       .then((res) => {
    //         this.portfolios[index].like = res.data.my_like;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   } else if (loginUg == "true") {
    //     let token = this.$cookie.get("access_token");
    //     let userID = this.$cookie.get("userID");
    //     this.$axios
    //       .post(
    //         this.API_URL + "/portfolios/feed-like",
    //         { userID: userID, feed_id: feed_id },
    //         {
    //           headers: { Authorization: `Bearer ${token}` },
    //         }
    //       )
    //       .then((res) => {
    //         this.portfolios[index].like = res.data.my_like;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // },
    // unLikeBtn(feed_id, index) {
    //   let loginUg = localStorage.getItem("loginUg");
    //   if (loginUg == "false") {
    //     let token = JSON.parse(sessionStorage.getItem("token"));
    //     this.$axios
    //       .patch(
    //         this.API_URL + "/portfolios/feed-like",
    //         { userID: token.userID, feed_id: feed_id },
    //         {
    //           headers: { Authorization: `Bearer ${token.access_token}` },
    //         }
    //       )
    //       .then((res) => {
    //         this.portfolios[index].like = res.data.my_like;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   } else if (loginUg == "true") {
    //     let token = this.$cookie.get("access_token");
    //     let userID = this.$cookie.get("userID");
    //     this.$axios
    //       .patch(
    //         this.API_URL + "/portfolios/feed-like",
    //         { userID: userID, feed_id: feed_id },
    //         {
    //           headers: { Authorization: `Bearer ${token}` },
    //         }
    //       )
    //       .then((res) => {
    //         this.portfolios[index].like = res.data.my_like;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#pushArtist {
  width: 1200px;
  margin-top: 40px;
  text-align: center;
}
.titleText {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #191919;
}
#pointText {
  color: #8ca0ef;
}

.contentsView {
  margin-top: 30px;
  margin-bottom: 221px;
}
.items {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.item {
  width: 282px;
  height: 282px;
  margin-right: 18px;
  margin-bottom: 30px;
  position: relative;
}
.item img {
  width: 282px;
  height: 282px;
  cursor: pointer;
  border-radius: 4px;
}
.hoverBox {
  position: absolute;
  bottom: 0;
  width: 282px;
  height: 110px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  align-items: flex-end;
  padding-bottom: 22px;
  opacity: 0;
  transition: 0.2s;
}
.columnHoverEffect {
  opacity: 1;
}
.hoverBox img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 10px;
}
.hoverBox-name {
  width: 160px;
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.btnBox {
  display: flex;
}
.btnBox svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 20px;
}
.likeBtn {
  /* margin-right: 10px; */
  transition: 0.2s;
  margin-left: 40px;
}
.likeBtn:hover {
  width: 40px;
  height: 40px;
}
</style>
