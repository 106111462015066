import Vue from "vue";
import App from "./App.vue";
import { router } from "./router/index.js";
import axios from "axios";
import store from "./store/store";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import VueCookie from "vue-cookie";
import io from "socket.io-client";
import VueGtag from "vue-gtag";

// const socket = io("https://rubatalk-test.rubatodev.com/socket", {

// const socket = io("https://rubatalk.rubatodev.com/socket", {
// const socket = io("https://api.office.rubatodev.com/socket", {
const socket = io("https://api.rubatodev.com/socket", {
  transports: ["websocket"],
  upgrade: true,
  secure: true,
  reconnection: true,
  "force new connection": true,
  forceNew: true,
  encoding: "UTF-8",
  withCredentials: true,
});

Vue.prototype.$socket = socket;

Vue.prototype.$axios = axios;
import qs from "qs";
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};
Vue.use(VueCookies);
Vue.use(VueCookie);
Vue.use(VueClipboard);
Vue.$cookies.config("1d");

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-1MB96D1WYD",
    },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
