<template>
  <div class="freeDetail">
    <div class="header">
      <div class="title">{{ freeDetail.board_information.title }}</div>
      <div class="header-btn" v-if="freeDetail.board_information.my_board">
        <div @click="postDelBtn">삭제하기</div>
        <div @click="postRetouchBtn">수정하기</div>
      </div>
    </div>
    <div class="board_info">
      <div>{{ freeDetail.board_information.writer_nickname }}</div>
      <div>
        {{ freeDetail.board_information.created_date }} | 조회수 :
        {{ freeDetail.board_information.view_count }} | 댓글 : 0
      </div>
    </div>
    <div
      class="imgBox"
      v-for="(item, index) in freeDetail.board_information.images"
      :key="index"
    >
      <img :src="item" alt="" />
    </div>
    <div class="board_contents">
      <p
        v-html="handleNewLine(freeDetail.board_information.board_contents)"
      ></p>
    </div>
    <div class="upBox">
      <div class="like_cnt">
        <div class="lickBtnBox" @click="lickBtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.022"
            height="21"
            viewBox="0 0 21.022 21"
            style="cursor: pointer"
            v-if="freeDetail.board_information.my_like == true"
          >
            <g
              id="그룹_20696"
              data-name="그룹 20696"
              transform="translate(-967.5 -799.5)"
            >
              <path
                id="패스_8070"
                data-name="패스 8070"
                d="M597.295,896.162a12.78,12.78,0,0,1,5.8,0c2.5.8.8,3.085.8,3.085s2.5,1.493,0,3.085c0,0,1.6,2.289-.8,3.085,0,0,1.6,3.085-3.3,3.085l-9.893-.8v-8.458a2.724,2.724,0,0,1,1.6-2.289c1.6-.8,2.5-6.169,2.5-6.169s.8-2.289,1.6-2.289,2.5,0,2.5,2.289a26.436,26.436,0,0,1-.8,5.373Z"
                transform="translate(383 -88.5)"
                fill="#8ca0ef"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="패스_8071"
                data-name="패스 8071"
                d="M586,897.1h2.9a.945.945,0,0,1,1,1V907a.945.945,0,0,1-1,1H586a.945.945,0,0,1-1-1v-8.9A1,1,0,0,1,586,897.1Z"
                transform="translate(383 -88.6)"
                fill="#8ca0ef"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.022"
            height="21"
            viewBox="0 0 21.022 21"
            v-if="freeDetail.board_information.my_like == false"
          >
            <g
              id="그룹_20054"
              data-name="그룹 20054"
              transform="translate(-967.5 -799.5)"
            >
              <path
                id="패스_8070"
                data-name="패스 8070"
                d="M597.295,896.162a12.78,12.78,0,0,1,5.8,0c2.5.8.8,3.085.8,3.085s2.5,1.493,0,3.085c0,0,1.6,2.289-.8,3.085,0,0,1.6,3.085-3.3,3.085l-9.893-.8v-8.458a2.724,2.724,0,0,1,1.6-2.289c1.6-.8,2.5-6.169,2.5-6.169s.8-2.289,1.6-2.289,2.5,0,2.5,2.289a26.436,26.436,0,0,1-.8,5.373Z"
                transform="translate(383 -88.5)"
                fill="none"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="패스_8071"
                data-name="패스 8071"
                d="M586,897.1h2.9a.945.945,0,0,1,1,1V907a.945.945,0,0,1-1,1H586a.945.945,0,0,1-1-1v-8.9A1,1,0,0,1,586,897.1Z"
                transform="translate(383 -88.6)"
                fill="none"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
          <div class="unlikeBtnBox-box">
            <span>추천</span>
            <span>{{ freeDetail.board_information.like_cnt }}</span>
          </div>
        </div>
      </div>
      <div class="like_cnt">
        <div class="unlickBtnBox" @click="unlickBtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.022"
            height="21"
            viewBox="0 0 21.022 21"
            v-if="freeDetail.board_information.my_unlike == false"
          >
            <g
              id="그룹_20055"
              data-name="그룹 20055"
              transform="translate(-1089.5 -800.6)"
            >
              <path
                id="패스_8075"
                data-name="패스 8075"
                d="M701.295,901.938a12.78,12.78,0,0,0,5.8,0c2.5-.8.8-3.085.8-3.085s2.5-1.493,0-3.085c0,0,1.6-2.289-.8-3.085,0,0,1.6-3.085-3.3-3.085l-9.893.8v8.458a2.724,2.724,0,0,0,1.6,2.289c1.6.8,2.5,6.169,2.5,6.169s.8,2.289,1.6,2.289,2.5,0,2.5-2.289a26.434,26.434,0,0,0-.8-5.373Z"
                transform="translate(401 -88.5)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="패스_8076"
                data-name="패스 8076"
                d="M690,890.2h2.9a.945.945,0,0,1,1,1v8.9a.945.945,0,0,1-1,1H690a.945.945,0,0,1-1-1v-8.9A.945.945,0,0,1,690,890.2Z"
                transform="translate(401 -88.5)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.022"
            height="21"
            viewBox="0 0 21.022 21"
            style="cursor: pointer"
            v-if="freeDetail.board_information.my_unlike == true"
          >
            <g
              id="그룹_20697"
              data-name="그룹 20697"
              transform="translate(-1089.5 -800.6)"
            >
              <path
                id="패스_8075"
                data-name="패스 8075"
                d="M701.295,901.938a12.78,12.78,0,0,0,5.8,0c2.5-.8.8-3.085.8-3.085s2.5-1.493,0-3.085c0,0,1.6-2.289-.8-3.085,0,0,1.6-3.085-3.3-3.085l-9.893.8v8.458a2.724,2.724,0,0,0,1.6,2.289c1.6.8,2.5,6.169,2.5,6.169s.8,2.289,1.6,2.289,2.5,0,2.5-2.289a26.434,26.434,0,0,0-.8-5.373Z"
                transform="translate(401 -88.5)"
                fill="#707070"
                stroke="#707070"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="패스_8076"
                data-name="패스 8076"
                d="M690,890.2h2.9a.945.945,0,0,1,1,1v8.9a.945.945,0,0,1-1,1H690a.945.945,0,0,1-1-1v-8.9A.945.945,0,0,1,690,890.2Z"
                transform="translate(401 -88.5)"
                fill="#707070"
                stroke="#707070"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
          <div class="unlikeBtnBox-box">
            <span>비추천</span>
            <span>{{ freeDetail.board_information.unlike_cnt }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="reportBtn"><span @click="postReport">게시물 신고</span></div>
    <div class="commentBox">
      <h2>댓글</h2>
      <div class="commentsList">
        <div
          v-for="(item, index) in freeDetail.comment_information"
          :key="index"
          class="commentsListBox"
        >
          <div class="comment_itemBox">
            <div class="comment_item">
              <img
                :src="item.commenter_profile_img"
                alt=""
                @click="goOtherPF(item.commenter_userID)"
                style="cursor: pointer"
              />
              <span
                @click="goOtherPF(item.commenter_userID)"
                style="cursor: pointer"
                >{{ item.commenter_nickname }}</span
              >
              <span id="comment_created_at">{{ item.comment_created_at }}</span>
            </div>
            <p v-html="handleNewLine(item.comment_contents)"></p>
          </div>
          <div class="comment_item2">
            <div
              id="red_text"
              v-if="
                item.my_comment == true &&
                freeDetail.board_information.my_board == true
              "
              @click="commentDelBtn(item.comment_id)"
            >
              삭제
            </div>
            <div
              @click="commentReportBtn(item.comment_id)"
              v-if="item.my_comment == false"
            >
              신고
            </div>
          </div>
        </div>
        <div class="pagingBox">
          <div @click="lastBackComment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              style="display: flex; align-item: center; cursor: pointer"
            >
              <g
                id="그룹_20908"
                data-name="그룹 20908"
                transform="translate(-825 -1333)"
              >
                <rect
                  id="사각형_24385"
                  data-name="사각형 24385"
                  width="20"
                  height="20"
                  transform="translate(825 1333)"
                  fill="none"
                  opacity="0.5"
                />
                <g
                  id="그룹_19026"
                  data-name="그룹 19026"
                  transform="translate(-150 157)"
                >
                  <path
                    id="패스_7802"
                    data-name="패스 7802"
                    d="M4,0,0,5l4,5"
                    transform="translate(981 1181)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                  <path
                    id="패스_7803"
                    data-name="패스 7803"
                    d="M4,0,0,5l4,5"
                    transform="translate(986 1181)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div @click="backComment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              style="display: flex; align-item: center; cursor: pointer"
            >
              <g
                id="그룹_20907"
                data-name="그룹 20907"
                transform="translate(-845 -1333)"
              >
                <rect
                  id="사각형_192"
                  data-name="사각형 192"
                  width="20"
                  height="20"
                  transform="translate(845 1333)"
                  fill="none"
                  opacity="0.5"
                />
                <path
                  id="패스_82"
                  data-name="패스 82"
                  d="M4,0,0,5l4,5"
                  transform="translate(853 1338.5)"
                  fill="none"
                  stroke="#767676"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
          <div
            v-for="(item, index) in freeDetail.comment.last_pageData[
              freeDetail.comment.pageCount
            ]"
            :key="index"
            @click="goPage(item)"
            class="pageNum"
            :class="{ nowPage_color: freeDetail.comment.nowPage == item + 1 }"
          >
            <span id="">
              {{ item + 1 }}
            </span>
          </div>
          <div @click="goComment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              style="display: flex; align-item: center; cursor: pointer"
            >
              <g
                id="그룹_20906"
                data-name="그룹 20906"
                transform="translate(-1105 -1333)"
              >
                <rect
                  id="사각형_192"
                  data-name="사각형 192"
                  width="20"
                  height="20"
                  transform="translate(1125 1353) rotate(180)"
                  fill="none"
                  opacity="0.5"
                />
                <path
                  id="패스_82"
                  data-name="패스 82"
                  d="M4,0,0,5l4,5"
                  transform="translate(1117 1348.5) rotate(180)"
                  fill="none"
                  stroke="#767676"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
          <div @click="lastGoComment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              style="display: flex; align-item: center; cursor: pointer"
            >
              <g
                id="그룹_20905"
                data-name="그룹 20905"
                transform="translate(-1125 -1333)"
              >
                <rect
                  id="사각형_24384"
                  data-name="사각형 24384"
                  width="20"
                  height="20"
                  transform="translate(1145 1353) rotate(180)"
                  fill="none"
                  opacity="0.5"
                />
                <g
                  id="그룹_19025"
                  data-name="그룹 19025"
                  transform="translate(-101 158)"
                >
                  <path
                    id="패스_7804"
                    data-name="패스 7804"
                    d="M0,0,4,5,0,10"
                    transform="translate(1237 1180)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                  <path
                    id="패스_7805"
                    data-name="패스 7805"
                    d="M0,0,4,5,0,10"
                    transform="translate(1232 1180)"
                    fill="none"
                    stroke="#767676"
                    stroke-width="2"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="comment_writeBox">
        <div class="comment_writeBox-info">
          <div class="writer_info">
            <div class="writer_info-img">
              <img :src="userData.profileImg" alt="" />
            </div>
            <div class="writer_info-name">{{ userData.nickname }}</div>
          </div>
          <div>
            <textarea v-model="commentwrite" class="commentwriteBox"></textarea>
          </div>
        </div>
        <div class="doCommentSave" @click="doCommentSave">등록</div>
      </div>
    </div>
    <div class="btnBox">
      <div class="btns">
        <div @click="backPost">이전</div>
        <div @click="goPost">다음</div>
      </div>
      <div class="btns2" @click="goList">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="그룹_19647"
            data-name="그룹 19647"
            transform="translate(-240 -884)"
          >
            <rect
              id="사각형_24936"
              data-name="사각형 24936"
              width="30"
              height="30"
              transform="translate(240 884)"
              fill="none"
            />
            <rect
              id="_사각형_24896"
              width="24"
              height="24"
              transform="translate(243 887)"
              fill="#8ca0ef"
            />
            <rect
              id="_사각형_24603"
              width="24"
              height="24"
              transform="translate(243 887)"
              fill="none"
            />
            <line
              id="_선_123"
              x2="16"
              transform="translate(247 893)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
            <line
              id="_선_124"
              x2="16"
              transform="translate(247 905)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
            <line
              id="_선_125"
              x2="16"
              transform="translate(247 899)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
          </g>
        </svg>
        <span>목록</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  computed: {
    freeDetail() {
      return this.$store.state.community.myCMDetail;
    },
    userData() {
      return this.$store.state.navStore.navData;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  data() {
    return {
      commentwrite: "",
    };
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
    doCommentSave() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/community/free-board-comment",
            {
              userID: token.userID,
              comment: this.commentwrite,
              id: this.freeDetail.postID,
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_myCMDetailComment", res.data);
              this.commentwrite = "";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");

        this.$axios
          .post(
            this.API_URL + "/community/free-board-comment",
            {
              userID: userID,
              comment: this.commentwrite,
              id: this.freeDetail.postID,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_myCMDetailComment", res.data);
              this.commentwrite = "";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    commentDelBtn(delNum) {
      if (!confirm("확인(예) 또는 취소(아니오)를 선택해주세요.")) {
        // 취소(아니오) 버튼 클릭 시 이벤트
        false;
      } else {
        // 확인(예) 버튼 클릭 시 이벤트
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .delete(this.API_URL + "/community/free-board-comment", {
              params: {
                userID: token.userID,
                id: this.freeDetail.postID,
                comment_id: delNum,
              },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetailComment", res.data);
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .delete(this.API_URL + "/community/free-board-comment", {
              params: {
                userID: userID,
                id: this.freeDetail.postID,
                comment_id: delNum,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetailComment", res.data);
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goPage(idx) {
      let num = idx + 1;
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: token.userID,
                id: this.freeDetail.postID,
                page: num,
              },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_myCMDetailComment", res.data);
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: userID,
                id: this.freeDetail.postID,
                page: num,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_myCMDetailComment", res.data);
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lickBtn() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/community/free-board-like",
            {
              userID: token.userID,
              id: this.freeDetail.postID,
              status: "like",
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.freeDetail.board_information.like_cnt = res.data.like_count;
              this.freeDetail.board_information.my_like = res.data.my_like;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/community/free-board-like",
            {
              userID: userID,
              id: this.freeDetail.postID,
              status: "like",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              console.log(res);
              this.freeDetail.board_information.like_cnt = res.data.like_count;
              this.freeDetail.board_information.my_like = res.data.my_like;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    unlickBtn() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/community/free-board-like",
            {
              userID: token.userID,
              id: this.freeDetail.postID,
              status: "unlike",
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.freeDetail.board_information.unlike_cnt =
                res.data.unlike_count;
              this.freeDetail.board_information.my_unlike = res.data.my_unlike;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/community/free-board-like",
            {
              userID: userID,
              id: this.freeDetail.postID,
              status: "unlike",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              if (res.data.status == 200) {
                this.freeDetail.board_information.unlike_cnt =
                  res.data.unlike_count;
                this.freeDetail.board_information.my_unlike =
                  res.data.my_unlike;
              } else {
                alert(res.data.msg);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    postDelBtn() {
      if (!confirm("확인(예) 또는 취소(아니오)를 선택해주세요.")) {
        alert("삭제요청을 취소하셨습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .delete(
              this.API_URL + "/community/free-board",

              {
                params: {
                  userID: token.userID,
                  id: this.freeDetail.postID,
                },
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert(res.data.msg);
                this.$router.go();
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .delete(
              this.API_URL + "/community/free-board",

              {
                params: {
                  userID: userID,
                  id: this.freeDetail.postID,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert(res.data.msg);
                this.$router.go();
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    postRetouchBtn() {
      this.$store.commit("set_myCMDel");
      this.$router.push({ name: "cmretouch" });
    },
    commentReportBtn(num) {
      if (!confirm("확인(예) 또는 취소(아니오)를 선택해주세요.")) {
        // 취소(아니오) 버튼 클릭 시 이벤트
        false;
      } else {
        // 확인(예) 버튼 클릭 시 이벤트
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .post(
              this.API_URL + "/community/report-free-comment",
              {
                userID: token.userID,
                id: this.freeDetail.postID,
                comment_id: num,
              },
              {
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert(res.data.msg);
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .post(
              this.API_URL + "/community/report-free-comment",
              {
                userID: userID,
                id: this.freeDetail.postID,
                comment_id: num,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert(res.data.msg);
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    postReport() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/community/report-free-board",
            {
              userID: token.userID,
              id: this.freeDetail.postID,
            },
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/community/report-free-comment",
            {
              userID: userID,
              id: this.freeDetail.postID,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lastBackComment() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: token.userID,
                id: this.freeDetail.postID,
                page: 1,
              },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
              this.freeDetail.comment.pageCount = 0;
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: userID,
                id: this.freeDetail.postID,
                page: num,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
              this.freeDetail.comment.pageCount = 0;
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lastGoComment() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: token.userID,
                id: this.freeDetail.postID,
                page: this.freeDetail.comment_last_page,
              },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
              this.freeDetail.comment.pageCount =
                this.freeDetail.comment.last_pageData.length - 1;
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .get(
            this.API_URL + "/community/free-board-comment",

            {
              params: {
                userID: userID,
                id: this.freeDetail.postID,
                page: this.freeDetail.comment_last_page,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
              this.freeDetail.comment.pageCount =
                this.freeDetail.comment.last_pageData.length - 1;
              document.documentElement.scrollTop = 0;
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    backComment() {
      let goPage = this.freeDetail.comment.nowPage - 1;
      if (goPage == 0) {
        true;
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(
              this.API_URL + "/community/free-board-comment",

              {
                params: {
                  userID: token.userID,
                  id: this.freeDetail.postID,
                  page: goPage,
                },
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_freeDetailComment", res.data);
                document.documentElement.scrollTop = 0;
                let nowPage = this.freeDetail.comment.nowPage;
                let last_pageData = this.freeDetail.comment.last_pageData;
                let i;
                for (i = 0; i < last_pageData.length; i++) {
                  if (last_pageData[i].includes(nowPage - 1) == true) {
                    this.freeDetail.comment.pageCount = i;
                  } else {
                    true;
                  }
                }
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(
              this.API_URL + "/community/free-board-comment",

              {
                params: {
                  userID: userID,
                  id: this.freeDetail.postID,
                  page: goPage,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_freeDetailComment", res.data);
                document.documentElement.scrollTop = 0;
                let nowPage = this.freeDetail.comment.nowPage;
                let last_pageData = this.freeDetail.comment.last_pageData;
                let i;
                for (i = 0; i < last_pageData.length; i++) {
                  if (last_pageData[i].includes(nowPage - 1) == true) {
                    this.freeDetail.comment.pageCount = i;
                  } else {
                    true;
                  }
                }
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goComment() {
      let goPage = this.freeDetail.comment.nowPage + 1;
      if (goPage == 0) {
        true;
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(
              this.API_URL + "/community/free-board-comment",

              {
                params: {
                  userID: token.userID,
                  id: this.freeDetail.postID,
                  page: goPage,
                },
                headers: {
                  Authorization: `Bearer ${token.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_freeDetailComment", res.data);
                document.documentElement.scrollTop = 0;
                let nowPage = this.freeDetail.comment.nowPage;
                let last_pageData = this.freeDetail.comment.last_pageData;
                let i;
                for (i = 0; i < last_pageData.length; i++) {
                  if (last_pageData[i].includes(nowPage - 1) == true) {
                    this.freeDetail.comment.pageCount = i;
                  } else {
                    true;
                  }
                }
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(
              this.API_URL + "/community/free-board-comment",

              {
                params: {
                  userID: userID,
                  id: this.freeDetail.postID,
                  page: goPage,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_freeDetailComment", res.data);
                document.documentElement.scrollTop = 0;
                let nowPage = this.freeDetail.comment.nowPage;
                let last_pageData = this.freeDetail.comment.last_pageData;
                let i;
                for (i = 0; i < last_pageData.length; i++) {
                  if (last_pageData[i].includes(nowPage - 1) == true) {
                    this.freeDetail.comment.pageCount = i;
                  } else {
                    true;
                  }
                }
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    backPost() {
      let postID = this.freeDetail.board_information.prev_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/free-board", {
              params: { userID: token.userID, id: postID, print_myboards: "N" },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/free-board", {
              params: { userID: userID, id: postID, print_myboards: "N" },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goPost() {
      let postID = this.freeDetail.board_information.next_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/free-board", {
              params: { userID: token.userID, id: postID, print_myboards: "Y" },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/free-board", {
              params: { userID: userID, id: postID, print_myboards: "Y" },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_myCMDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goList() {
      this.$store.commit("set_myCMDel");
    },
    goOtherPF(Id) {
      this.$router.push({
        path: "/portfoliodetail/" + Id + "/PFWork",
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.freeDetail {
  width: 690px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 30px;
  padding-top: 18px;
}
.header {
  display: flex;
  border-bottom: 1px solid #707070;
}
.title {
  width: 510px;
  height: 40px;
  padding: 7px 10px;
  font-weight: bold;
}
.header-btn {
  display: flex;
  align-items: center;
}
.header-btn div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #cf1f1f;
  cursor: pointer;
}
.header-btn div:last-child {
  color: #707070;
  margin-left: 10px;
}
.board_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 6px;
  padding-bottom: 30px;
}
.board_info div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.imgBox {
  max-width: 610px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.imgBox img {
  max-width: 610px;
}
.board_contents {
  padding-bottom: 30px;
  padding-left: 10px;
}
.board_contents p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}
.upBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.like_cnt {
  display: flex;
  align-items: center;
}
.lickBtnBox {
  width: 100px;
  height: 54px;
  border: 1px solid #8ca0ef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lickBtnBox svg {
  margin-right: 17px;
}
.lickBtnBox div > span {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #8ca0ef;
}
.unlickBtnBox {
  width: 100px;
  height: 54px;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
}
.unlickBtnBox svg {
  margin-right: 17px;
}
.unlickBtnBox div > span {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
}
.unlikeBtnBox-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reportBtn {
  text-align: right;

  padding-right: 10px;
  margin-bottom: 20px;
}
.reportBtn span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  cursor: pointer;
}
.commentBox h2 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px 10px;
}
.commentsListBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #dbdbdb;
}
.comment_item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.comment_item img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;

  border-radius: 50%;
}
.comment_item span {
  width: 104px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-right: 6px;
}
.comment_itemBox p {
  max-width: 530px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  word-wrap: break-word;
  margin-left: 50px;
  padding-bottom: 10px;
}
#comment_created_at {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #707070;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.comment_item2 {
  display: flex;
  margin-right: 10px;
  padding-top: 16px;
}
.comment_item2 div {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  cursor: pointer;
  color: #707070;
}
.comment_item2 div:last-child {
  margin-left: 10px;
}
#red_text {
  color: #cf1f1f;
}
.pagingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.pageNum {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nowPage_color {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: #ffffff;
}
.comment_writeBox {
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid #707070;
}
.comment_writeBox-info {
  display: flex;
  align-items: flex-start;
}
.writer_info {
  display: flex;
  align-items: center;
}
.writer_info-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.writer_info-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.writer_info-name {
  width: 50px;
  height: 24px;
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.commentwriteBox {
  width: 530px;
  height: 100px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  resize: none;
  outline: none;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
  padding: 10px;
  box-sizing: border-box;
}
.doCommentSave {
  width: 90px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  margin-top: 10px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 540px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}
.btns {
  display: flex;
}
.btns div {
  width: 90px;
  height: 30px;
  border: 1px solid #8ca0ef;
  border-radius: 4px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btns div:last-child {
  margin-left: 20px;
}
.btns2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
}
</style>
