const navStore = {
  state() {
    return {
      navData: {
        profileImg: "",
        main_field: "",
        main_role: "",
        nickname: "",
        userID: "",
      },
    };
  },
  getters: {},
  mutations: {
    set_navdata(state, payload) {
      state.navData.profileImg = payload.profile_img;
      state.navData.main_field = payload.main_field;
      state.navData.main_role = payload.main_role;
      state.navData.nickname = payload.nickname;
    },
    set_userID(state, payload) {
      state.navData.userID = payload;
    },
  },
  actions: {},
};
export default navStore;
