<template>
  <div class="freeDetail">
    <div class="header">
      <div class="title">{{ inquireDetail.title }}</div>
      <div
        class="delBtn"
        @click="delBtn"
        v-if="inquireDetail.answer_status !== false"
      >
        삭제하기
      </div>
    </div>
    <div class="board_info">
      <div>{{ inquireDetail.writer_nickname }}</div>
      <div>
        {{ inquireDetail.created_date }}
      </div>
    </div>
    <div
      class="imgBox"
      v-for="(item, index) in inquireDetail.images"
      :key="index"
    >
      <img :src="item" alt="" />
    </div>
    <div class="board_contents">
      <p v-html="handleNewLine(inquireDetail.board_contents)"></p>
    </div>

    <div class="commentBox">
      <h2>댓글</h2>
      <div class="commentsList">
        <div
          v-for="(item, index) in inquireDetail2"
          :key="index"
          class="commentsListBox"
        >
          <div class="comment_item">
            <img :src="item.profileImage" alt="" />
            <span>{{ item.answerer_nickname }}</span>

            <span id="comment_created_at">{{ item.answer_created_at }}</span>
          </div>
          <p v-html="handleNewLine(item.answer)"></p>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <div class="btns">
        <div @click="backPost">이전</div>
        <div @click="goPost">다음</div>
      </div>
      <div class="btns2" @click="goList">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="그룹_19647"
            data-name="그룹 19647"
            transform="translate(-240 -884)"
          >
            <rect
              id="사각형_24936"
              data-name="사각형 24936"
              width="30"
              height="30"
              transform="translate(240 884)"
              fill="none"
            />
            <rect
              id="_사각형_24896"
              width="24"
              height="24"
              transform="translate(243 887)"
              fill="#8ca0ef"
            />
            <rect
              id="_사각형_24603"
              width="24"
              height="24"
              transform="translate(243 887)"
              fill="none"
            />
            <line
              id="_선_123"
              x2="16"
              transform="translate(247 893)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
            <line
              id="_선_124"
              x2="16"
              transform="translate(247 905)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
            <line
              id="_선_125"
              x2="16"
              transform="translate(247 899)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
          </g>
        </svg>
        <span>목록</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  computed: {
    inquirePageData() {
      return this.$store.state.community.inquirePageData;
    },
    inquireDetail() {
      return this.$store.state.community.inquireDetail.question_information;
    },
    userData() {
      return this.$store.state.navStore.navData;
    },
    inquireDetail2() {
      return this.$store.state.community.inquireDetail.answer_information;
    },
    freeDetail() {
      return this.$store.state.community.freeDetail;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  data() {
    return {
      commentwrite: "",
    };
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },

    commentDelBtn(delNum) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(this.API_URL + "/community/free-board-comment", {
            params: {
              userID: token.userID,
              id: this.freeDetail.postID,
              comment_id: delNum,
            },
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .delete(this.API_URL + "/community/free-board-comment", {
            params: {
              userID: userID,
              id: this.freeDetail.postID,
              comment_id: delNum,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$store.commit("set_freeDetailComment", res.data);
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    delBtn() {
      console.log(this.inquireDetail.id);
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(
            this.API_URL +
              `/community/question/id=${Number(this.inquireDetail.id)}`,
            {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        this.$axios
          .delete(this.API_URL + "/community/question", {
            params: { id: Number(this.inquireDetail.id) },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
              this.$router.go();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    backPost() {
      let postID = this.inquireDetail.prev_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/question", {
              params: { userID: token.userID, id: postID },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_inquireDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/question", {
              params: { userID: userID, id: postID },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_inquireDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goPost() {
      let postID = this.inquireDetail.next_id;
      if (postID == 0) {
        alert("더이상 게시물이 존재하지 않습니다.");
      } else {
        let loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          let token = JSON.parse(sessionStorage.getItem("token"));
          this.$axios
            .get(this.API_URL + "/community/question", {
              params: { userID: token.userID, id: postID },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_inquireDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          let userID = this.$cookie.get("userID");
          this.$axios
            .get(this.API_URL + "/community/question", {
              params: { userID: userID, id: postID },
              headers: {
                Authorization: `Bearer ${token.access_token}`,
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.commit("set_inquireDetail", res.data);
                document.documentElement.scrollTop = 0;
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    goList() {
      this.$store.commit("set_inquirePageDataDel");
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.freeDetail {
  width: 690px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 30px;
  padding-top: 18px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
}
.title {
  width: 510px;
  height: 40px;
  padding: 7px 10px;
  font-weight: bold;
}
.delBtn {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #cf1f1f;
  cursor: pointer;
}
.board_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 6px;
  padding-bottom: 30px;
}
.board_info div {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.imgBox {
  max-width: 610px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.imgBox img {
  max-width: 610px;
}
.board_contents {
  padding-bottom: 30px;
  padding-left: 10px;
}
.board_contents p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
}

.commentBox h2 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px 10px;
}
.commentsListBox {
  display: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}
.comment_item {
  display: flex;
  align-items: center;

  padding: 5px 0;
}
.comment_item img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;

  border-radius: 50%;
}
.comment_item span {
  max-width: 104px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-right: 10px;
}
.commentsListBox p {
  max-width: 530px;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  word-wrap: break-word;
  margin-left: 50px;
  padding-bottom: 10px;
}
#comment_created_at {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: -0.3px;
  color: #707070;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.btnBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  margin-top: 20px;
}
.btns {
  display: flex;
}
.btns div {
  width: 90px;
  height: 30px;
  border: 1px solid #8ca0ef;
  border-radius: 4px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btns div:last-child {
  margin-left: 20px;
}
.btns2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
}
</style>
