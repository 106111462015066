import Vue from "vue";
import Vuex from "vuex";

//모듈
import userAccount from "./modules/userAccount";
import MCrecommend from "./modules/MCrecommend";
import chat from "./modules/chat";
import community from "./modules/community";
import userSetup from "./modules/userSetup";
import postData from "./modules/postData";
import PFDataSet from "./modules/PFDataSet";
import navStore from "./modules/navStore";
import uploadData from "./modules/uploadData";
import createPersistedState from "vuex-persistedstate";

// 플러그인

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    userAccount: userAccount,
    MCrecommend: MCrecommend,
    chat: chat,
    community: community,
    userSetup: userSetup,
    postData: postData,
    PFDataSet: PFDataSet,
    navStore: navStore,
    uploadData: uploadData,
  },
  plugins: [
    createPersistedState({
      paths: ["MCrecommend"],
      key: "scrap_users",
      storage: window.sessionStorage,
    }),
  ],
  state() {
    return {
      isLogin: false,
      // API_URL: "https://api.office.rubatodev.com", // 백엔드 배포서버
      API_URL: "https://api.rubatodev.com", // 백엔드 배포서버
      // sungjun_URL: "https://api-v2.office.rubatodev.com",
      sungjun_URL: "https://api-v2.rubatodev.com",
      scroll: 0,
      follow_scroll: 0,
    };
  },
  getters: {},
  mutations: {
    set_scroll(state, payload) {
      state.scroll = payload;
    },
    set_follow_scroll(state, payload) {
      state.follow_scroll = payload;
    },
  },
  actions: {},
});

export default store;
