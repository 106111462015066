<template>
  <div id="oterWorks">
    <div class="items">
      <div
        class="item"
        v-for="(item, index) in otherPost"
        v-bind:key="index"
        @mouseover="hover(index)"
        @mouseleave="hoverleave"
      >
        <img
          :src="item.PVData.thumbnail_file"
          @click="goPost(item.PVData.feed_id)"
        />
        <div
          class="hoverBox"
          :class="{ columnHoverEffect: columnHoverCheck(index) }"
        >
          <span class="hoverBox-name" @click="goPost(item.PVData.feed_id)">{{
            item.PVData.feed_title
          }}</span>
          <div class="btnBox">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              class="likeBtn"
              v-show="item.PVData.my_like == true"
              @click="unLikeBtn(item.PVData.feed_id, index)"
            >
              <g
                id="그룹_19680"
                data-name="그룹 19680"
                transform="translate(-102 -685)"
              >
                <rect
                  id="사각형_24938"
                  data-name="사각형 24938"
                  width="30"
                  height="30"
                  transform="translate(102 685)"
                  fill="none"
                />
                <path
                  id="패스_8051"
                  data-name="패스 8051"
                  d="M117,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,117,688Z"
                  fill="#fff"
                />
                <path
                  id="_패스_7622-2"
                  d="M118,695.7l-1,1.2-1-1.2a3.1,3.1,0,0,0-4.5-.4c-.2.1-.3.3-.4.4h0a4.392,4.392,0,0,0,0,5.7l6,6.2,6-6.2a4.392,4.392,0,0,0,0-5.7h0a3.1,3.1,0,0,0-4.5-.4,1.275,1.275,0,0,0-.6.4Z"
                  fill="#ff3120"
                />
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              class="likeBtn"
              v-show="item.PVData.my_like == false"
              @click="likeBtn(item.PVData.feed_id, index)"
            >
              <g
                id="그룹_19680"
                data-name="그룹 19680"
                transform="translate(-102 -685)"
              >
                <rect
                  id="사각형_24938"
                  data-name="사각형 24938"
                  width="30"
                  height="30"
                  transform="translate(102 685)"
                  fill="none"
                />
                <path
                  id="패스_8051"
                  data-name="패스 8051"
                  d="M117,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,117,688Z"
                  fill="#fff"
                />
                <path
                  id="_패스_7622-2"
                  d="M118,695.7l-1,1.2-1-1.2a3.1,3.1,0,0,0-4.5-.4c-.2.1-.3.3-.4.4h0a4.392,4.392,0,0,0,0,5.7l6,6.2,6-6.2a4.392,4.392,0,0,0,0-5.7h0a3.1,3.1,0,0,0-4.5-.4,1.275,1.275,0,0,0-.6.4Z"
                  fill="none"
                  stroke="#707070"
                  stroke-width="1"
                />
              </g>
            </svg>
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <g
                id="그룹_19679"
                data-name="그룹 19679"
                transform="translate(-151 -685)"
              >
                <rect
                  id="사각형_24937"
                  data-name="사각형 24937"
                  width="30"
                  height="30"
                  transform="translate(151 685)"
                  fill="none"
                />
                <path
                  id="패스_8050"
                  data-name="패스 8050"
                  d="M166,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,166,688Z"
                  fill="#fff"
                />
                <circle
                  id="_타원_185"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(158 698)"
                  fill="#707070"
                />
                <circle
                  id="_타원_186"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(164 698)"
                  fill="#707070"
                />
                <circle
                  id="_타원_187"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(170 698)"
                  fill="#707070"
                />
              </g>
            </svg> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oterWorks",
  mounted() {
    let loginUg = localStorage.getItem("loginUg");
    if (loginUg == "false") {
      let token = JSON.parse(sessionStorage.getItem("token"));
      this.$axios
        .get(this.API_URL + "/portfolios/load-preview", {
          params: { userID: token.userID, feederID: this.$route.params.id },
          // headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((res) => {
          for (let i in res.data.preview_data) {
            let PVData = {
              my_like: res.data.preview_data[i].my_like,
              thumbnail_file: res.data.preview_data[i].thumbnail_file,
              feed_id: res.data.preview_data[i].feed_id,
              feed_title: res.data.preview_data[i].feed_title,
            };
            this.otherPost.push({ PVData });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (loginUg == "true") {
      let token = this.$cookie.get("access_token");
      let userID = this.$cookie.get("userID");
      this.$axios
        .get(this.API_URL + "/portfolios/load-preview", {
          params: { userID: userID, feederID: this.$route.params.id },
          // headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          for (let i in res.data.preview_data) {
            let PVData = {
              my_like: res.data.preview_data[i].my_like,
              thumbnail_file: res.data.preview_data[i].thumbnail_file,
              feed_id: res.data.preview_data[i].feed_id,
              feed_title: res.data.preview_data[i].feed_title,
            };
            this.otherPost.push({ PVData });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  data() {
    return {
      hoverBar: null,

      otherPost: [],
    };
  },
  computed: {
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    hover(index) {
      this.hoverBar = index;
    },
    hoverleave() {
      this.hoverBar = null;
    },
    columnHoverCheck(index) {
      if (index === this.hoverBar) {
        return true;
      } else {
        return false;
      }
    },
    goPost(userId) {
      this.$router.push({ path: "/postview/" + userId });
    },
    likeBtn(feed_id, index) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/portfolios/feed-like",
            { userID: token.userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token.access_token}` },
            }
          )
          .then((res) => {
            this.otherPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/portfolios/feed-like",
            { userID: userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.otherPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    unLikeBtn(feed_id, index) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .patch(
            this.API_URL + "/portfolios/feed-like",
            { userID: token.userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token.access_token}` },
            }
          )
          .then((res) => {
            this.otherPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .patch(
            this.API_URL + "/portfolios/feed-like",
            { userID: userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.otherPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#oterWorks {
  margin-top: 30px;
  margin-bottom: 100px;
}
.items {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.item {
  width: 282px;
  height: 282px;
  margin-right: 18px;
  margin-bottom: 18px;
  position: relative;
}

.item img {
  width: 282px;
  height: 282px;
  cursor: pointer;
  border-radius: 4px;
}

.hoverBox {
  position: absolute;
  bottom: 0;
  width: 282px;
  height: 110px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  align-items: flex-end;
  padding-bottom: 22px;
  opacity: 0;
  transition: 0.2s;
}
.columnHoverEffect {
  opacity: 1;
}

.hoverBox-name {
  width: 160px;
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  margin-left: 20px;
  cursor: pointer;
}
.btnBox {
  display: flex;
}
.btnBox svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.likeBtn {
  /* margin-right: 10px; */
  transition: 0.2s;
  margin-left: 40px;
}
</style>
