export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/LoginView.vue"),
  },
  {
    path: "/findid",
    name: "findid",
    component: () => import("@/components/login/FindIdView"),
  },
  {
    path: "/findid2",
    name: "findid2",
    component: () => import("@/components/login/FindId2View"),
  },
  {
    path: "/findpw",
    name: "findpw",
    component: () => import("@/components/login/findPW"),
  },
  {
    path: "/failView",
    name: "failView",
    component: () => import("@/components/login/failView.vue"),
  },
  {
    path: "/resetpw",
    name: "resetpw",
    component: () => import("@/components/login/ResetPwView"),
  },
  {
    path: "/resetpw2",
    name: "resetpw2",
    component: () => import("@/components/login/ResetPw2View"),
  },
  {
    path: "/consent",
    name: "consent",
    component: () => import("@/components/account/ConsentView"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/components/account/SignUp2View"),
  },

  {
    path: "/finish",
    name: "finish",
    component: () => import("@/components/account/FinishView"),
  },
  {
    path: "/selectfield",
    name: "selectfield",
    component: () => import("@/components/account/selectField"),
  },
  {
    path: "/mainfield",
    name: "mainfield",
    component: () => import("@/components/account/mainField_Role"),
  },
  {
    path: "/selectkeyword",
    name: "selectkeyword",
    component: () => import("@/components/account/selectKeyword"),
  },
];
