<template>
  <div class="saveFinish">
    <div class="mainBox">
      <h2>확인</h2>
      <p>수정이 완료되었습니다.</p>
      <div @click="end">확인</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "saveFinish",
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },
  methods: {
    end() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.saveFinish {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.mainBox {
  width: 500px;
  height: 224px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 428px auto;
  text-align: center;
}
.mainBox h2 {
  font: normal normal bold 24px/30px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #8ca0ef;
  padding-top: 40px;
}
.mainBox p {
  font: normal normal normal 18px/30px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin: 30px 0;
}
.mainBox div {
  width: 100px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;

  font: normal normal normal 16px/30px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}
</style>
