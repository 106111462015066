<template>
  <div class="setKeyword">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      class="closeBtn"
      @click="closeBtn"
    >
      <g
        id="그룹_20400"
        data-name="그룹 20400"
        transform="translate(-1865 -25)"
      >
        <rect
          id="사각형_25550"
          data-name="사각형 25550"
          width="30"
          height="30"
          transform="translate(1865 25)"
          fill="none"
        />
        <g
          id="그룹_19204"
          data-name="그룹 19204"
          transform="translate(1869.703 30)"
        >
          <line
            id="선_81"
            data-name="선 81"
            x1="20"
            y2="20"
            transform="translate(0.297)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="선_82"
            data-name="선 82"
            x1="20"
            y1="20"
            transform="translate(0.297)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
    <div class="popup" v-if="popup">
      <div class="white-bg">
        <h2>키워드 제안하기</h2>
        <p>
          추가되었으면 하는 키워드가 있다면 적어주세요.<br />검토 후
          반영하겠습니다.
        </p>
        <input type="text" v-model="suggest" placeholder="키워드 입력" />
        <div class="popupBtns">
          <div @click="dosuggest">제출</div>
          <div @click="suggestCancel">취소</div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <h2>키워드 선택</h2>
      <p>
        주로 즐기는 취향의 키워드를 선택해주세요.<br />잘 맞는 아티스트를
        제안해드릴게요!<br /><span>( 복수 선택 가능 )</span>
      </p>
      <div class="music">
        <div class="selectHeader">
          <svg
            id="그룹_20665"
            data-name="그룹 20665"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25647"
                  data-name="사각형 25647"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="그룹_20664"
              data-name="그룹 20664"
              clip-path="url(#clip-path)"
            >
              <path
                id="패스_8232"
                data-name="패스 8232"
                d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                fill="#dbdbdb"
              />
              <path
                id="패스_8233"
                data-name="패스 8233"
                d="M6,20.5A2.5,2.5,0,1,1,8.5,18,2.5,2.5,0,0,1,6,20.5"
                fill="#8ca0ef"
              />
              <path
                id="패스_8234"
                data-name="패스 8234"
                d="M6,16a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                fill="#8ca0ef"
              />
              <path
                id="패스_8235"
                data-name="패스 8235"
                d="M16,18.5A2.5,2.5,0,1,1,18.5,16,2.5,2.5,0,0,1,16,18.5"
                fill="#8ca0ef"
              />
              <path
                id="패스_8236"
                data-name="패스 8236"
                d="M16,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
                fill="#8ca0ef"
              />
              <path
                id="패스_8237"
                data-name="패스 8237"
                d="M8,18V7.231L18,4V15.846"
                fill="none"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
          <h4>음악</h4>
        </div>
        <div class="selectBox">
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="발라드"
              id="ballad"
            />
            <label for="ballad"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="알앤비"
              id="rnb"
            />
            <label for="rnb"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="모던락"
              id="modernrock"
            />
            <label for="modernrock"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="k-pop"
              id="kpop"
            />
            <label for="kpop"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="어쿠스틱"
              id="acoustic"
            />
            <label for="acoustic"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="힙합"
              id="hiphop"
            />
            <label for="hiphop"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="재즈"
              id="jazz"
            />
            <label for="jazz"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="팝"
              id="pop"
            />
            <label for="pop"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="디스코"
              id="disco"
            />
            <label for="disco"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.music"
              value="댄스"
              id="dance"
            />
            <label for="dance"></label>
          </div>
        </div>
      </div>
      <div class="video">
        <div class="selectHeader">
          <svg
            id="그룹_20668"
            data-name="그룹 20668"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25649"
                  data-name="사각형 25649"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="그룹_20667"
              data-name="그룹 20667"
              clip-path="url(#clip-path)"
            >
              <path
                id="패스_8238"
                data-name="패스 8238"
                d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                fill="#dbdbdb"
              />
              <rect
                id="사각형_25648"
                data-name="사각형 25648"
                width="12"
                height="7"
                rx="0.5"
                transform="translate(4.5 8.5)"
                fill="#8ca0ef"
              />
              <path
                id="패스_8239"
                data-name="패스 8239"
                d="M16,15H5V9H16Zm0-7H5A1,1,0,0,0,4,9v6a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1"
                fill="#8ca0ef"
              />
              <path
                id="패스_8240"
                data-name="패스 8240"
                d="M20,9l-3,3,3,3Z"
                fill="#8ca0ef"
              />
              <path
                id="패스_8241"
                data-name="패스 8241"
                d="M20,9l-3,3,3,3Z"
                fill="none"
                stroke="#8ca0ef"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>

          <h4>영상</h4>
        </div>
        <div class="selectBox">
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="액션"
              id="action"
            />
            <label for="action"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="코미디"
              id="comedy"
            />
            <label for="comedy"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="공포"
              id="horror"
            />
            <label for="horror"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="로맨스"
              id="romance"
            />
            <label for="romance"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="모험"
              id="adventure"
            />
            <label for="adventure"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="범죄"
              id="criminal"
            />
            <label for="criminal"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="다큐"
              id="docu"
            />
            <label for="docu"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="드라마"
              id="f_drama"
            />
            <label for="f_drama"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="스릴러"
              id="thriller"
            />
            <label for="thriller"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="판타지"
              id="fantasy"
            />
            <label for="fantasy"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="SF"
              id="sfantasy"
            />
            <label for="sfantasy"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="가족"
              id="family"
            />
            <label for="family"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="서스펜스"
              id="suspense"
            />
            <label for="suspense"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="애니메이션"
              id="animation"
            />
            <label for="animation"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="독립영화"
              id="independentfilm"
            />
            <label for="independentfilm"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="유튜브"
              id="f_youtube"
            />
            <label for="f_youtube"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="코레오"
              id="choreo"
            />
            <label for="choreo"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="컴퓨터 그래픽"
              id="cgraphic"
            />
            <label for="cgraphic"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.video"
              value="필름룩스"
              id="filmlooks"
            />
            <label for="filmlooks"></label>
          </div>
        </div>
      </div>
      <div class="photo">
        <div class="selectHeader">
          <svg
            id="그룹_20670"
            data-name="그룹 20670"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25650"
                  data-name="사각형 25650"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="그룹_20669"
              data-name="그룹 20669"
              clip-path="url(#clip-path)"
            >
              <path
                id="패스_8242"
                data-name="패스 8242"
                d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                fill="#dbdbdb"
              />
              <path
                id="패스_8243"
                data-name="패스 8243"
                d="M19.559,19h-14V5h14Zm0-15h-14a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h14a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1"
                fill="#8ca0ef"
              />
              <path
                id="패스_8244"
                data-name="패스 8244"
                d="M13.559,9.5a1.5,1.5,0,1,1-1.5-1.5,1.5,1.5,0,0,1,1.5,1.5"
                fill="#8ca0ef"
              />
              <path
                id="패스_8245"
                data-name="패스 8245"
                d="M19.559,14v5h-14V17L7.9,13.1a1,1,0,0,1,1.565-.192L13.059,16.5l2.831-4.448a1,1,0,0,1,1.55-.171Z"
                fill="#8ca0ef"
              />
            </g>
          </svg>
          <h4>사진</h4>
        </div>
        <div class="selectBox">
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="차가운"
              id="cold"
            />
            <label for="cold"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="따뜻한"
              id="warm"
            />
            <label for="warm"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="필름"
              id="film"
            />
            <label for="film"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="건물"
              id="building"
            />
            <label for="building"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="앨범"
              id="album"
            />
            <label for="album"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="아트워크"
              id="artwork"
            />
            <label for="artwork"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="누드"
              id="nude"
            />
            <label for="nude"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="예술"
              id="artistic"
            />
            <label for="artistic"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="모던"
              id="modern"
            />
            <label for="modern"></label>
          </div>

          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.photography"
              value="코스프레"
              id="costume"
            />
            <label for="costume"></label>
          </div>
        </div>
      </div>
      <div class="design">
        <div class="selectHeader">
          <svg
            id="그룹_20676"
            data-name="그룹 20676"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="사각형_25653"
                  data-name="사각형 25653"
                  width="24"
                  height="24"
                  fill="none"
                />
              </clipPath>
              <clipPath id="clip-path-2">
                <rect
                  id="사각형_25652"
                  data-name="사각형 25652"
                  width="11.484"
                  height="15.504"
                  transform="translate(10.115 5.557)"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="그룹_20675"
              data-name="그룹 20675"
              clip-path="url(#clip-path)"
            >
              <path
                id="패스_8246"
                data-name="패스 8246"
                d="M20,1a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1Zm0-1H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4"
                fill="#dbdbdb"
              />
              <path
                id="패스_8247"
                data-name="패스 8247"
                d="M7.864,4.575C4.454,5.968,3.116,10.588,4.509,14a6.633,6.633,0,0,0,.733,1.337.771.771,0,0,0,1.349-.182,2.352,2.352,0,0,1,1.944-1.422,2.3,2.3,0,0,1,2.042.857,2.351,2.351,0,0,1,.346,2.384.771.771,0,0,0,.814,1.091,6.735,6.735,0,0,0,1.469-.413c3.41-1.393,5.771-5.584,4.378-8.994s-6.31-5.474-9.72-4.081"
                fill="#8ca0ef"
              />
              <g id="그룹_20674" data-name="그룹 20674">
                <g
                  id="그룹_20673"
                  data-name="그룹 20673"
                  clip-path="url(#clip-path-2)"
                >
                  <g id="그룹_20672" data-name="그룹 20672">
                    <g
                      id="그룹_20671"
                      data-name="그룹 20671"
                      clip-path="url(#clip-path-2)"
                    >
                      <path
                        id="패스_8248"
                        data-name="패스 8248"
                        d="M21.592,6.182c.186,1.5-3.182,6.606-5.512,9.351a.288.288,0,0,1,.039.352l-1.108,1.576a.268.268,0,0,1-.283.084A4.461,4.461,0,0,1,13.681,19.5,7.683,7.683,0,0,1,10.5,21.049a.319.319,0,0,1-.262-.037l-.047-.048a.379.379,0,0,1-.064-.338l.436-1.933a4.087,4.087,0,0,1,1.894-2.7.31.31,0,0,1-.07-.383L13.5,14.029a.28.28,0,0,1,.272-.086c1.789-3.109,5.466-8.037,6.96-8.362a.7.7,0,0,1,.582.093l.006.012a.7.7,0,0,1,.275.5M15.5,15.093c2.907-3.415,5.575-7.988,5.369-8.809-.835.1-4.3,4.218-6.477,8.03l0,.009L15.5,15.1Zm-.913,1.717.782-1.117-1.441-1.009L13.137,15.8Zm-.6.537a.555.555,0,0,1,.08-.2l-.963-.677a.661.661,0,0,1-.232.185,2.777,2.777,0,0,0-1.525,2.026l-.3,1.355A5.689,5.689,0,0,0,13.2,18.958a3.524,3.524,0,0,0,.792-1.611"
                        fill="#fff"
                      />
                      <path
                        id="패스_8249"
                        data-name="패스 8249"
                        d="M20.871,6.284c.2.82-2.462,5.394-5.369,8.809l0,.009-1.107-.779,0-.009c2.175-3.812,5.641-7.934,6.477-8.03"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8250"
                        data-name="패스 8250"
                        d="M15.371,15.693l-.782,1.117L13.138,15.8l.791-1.114Z"
                        fill="#8ca0ef"
                      />
                      <path
                        id="패스_8251"
                        data-name="패스 8251"
                        d="M14.074,17.142a.539.539,0,0,0-.08.2,3.548,3.548,0,0,1-.792,1.611,5.7,5.7,0,0,1-2.152,1.073l.3-1.355a2.78,2.78,0,0,1,1.526-2.026.67.67,0,0,0,.232-.185Z"
                        fill="#8ca0ef"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <path
                id="패스_8252"
                data-name="패스 8252"
                d="M6,10.193a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                fill="#fff"
              />
              <path
                id="패스_8253"
                data-name="패스 8253"
                d="M6,8.693a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                fill="#fff"
              />
              <path
                id="패스_8254"
                data-name="패스 8254"
                d="M8.646,7.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                fill="#fff"
              />
              <path
                id="패스_8255"
                data-name="패스 8255"
                d="M8.647,6.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                fill="#fff"
              />
              <path
                id="패스_8256"
                data-name="패스 8256"
                d="M12,7.054a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                fill="#fff"
              />
              <path
                id="패스_8257"
                data-name="패스 8257"
                d="M12,5.554a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                fill="#fff"
              />
              <path
                id="패스_8258"
                data-name="패스 8258"
                d="M14.389,9.919a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                fill="#fff"
              />
              <path
                id="패스_8259"
                data-name="패스 8259"
                d="M14.389,8.419a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                fill="#fff"
              />
              <path
                id="패스_8260"
                data-name="패스 8260"
                d="M10.239,12.4a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5"
                fill="#fff"
              />
              <path
                id="패스_8261"
                data-name="패스 8261"
                d="M10.239,10.9a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
                fill="#fff"
              />
            </g>
          </svg>
          <h4>디자인</h4>
        </div>
        <div class="selectBox">
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="드로잉"
              id="drawing"
            />
            <label for="drawing"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="회화"
              id="painting"
            />
            <label for="painting"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="콜라주"
              id="collage"
            />
            <label for="collage"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="손그림"
              id="handdraw"
            />
            <label for="handdraw"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="목업디자인"
              id="mockup"
            />
            <label for="mockup"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="3D"
              id="threed"
            />
            <label for="threed"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="모델링"
              id="modeling"
            />
            <label for="modeling"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="브랜딩"
              id="branding"
            />
            <label for="branding"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="키치"
              id="kitsch"
            />
            <label for="kitsch"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="패키지"
              id="package"
            />
            <label for="package"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.design"
              value="포토그래피"
              id="photograpy"
            />
            <label for="photograpy"></label>
          </div>
        </div>
      </div>
      <div class="specialist">
        <div class="selectHeader">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g
              id="그룹_20666"
              data-name="그룹 20666"
              transform="translate(-740 -1401)"
            >
              <g
                id="사각형_25646"
                data-name="사각형 25646"
                transform="translate(740 1401)"
                fill="#fff"
                stroke="#dbdbdb"
                stroke-width="1"
              >
                <rect width="24" height="24" rx="4" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  fill="none"
                />
              </g>
              <rect
                id="사각형_23996"
                data-name="사각형 23996"
                width="18"
                height="18"
                transform="translate(743.001 1404)"
                fill="#fff"
              />
              <path
                id="패스_7696"
                data-name="패스 7696"
                d="M336.394-159.375a4.363,4.363,0,0,0,4.364-4.364,4.363,4.363,0,0,0-4.364-4.364,4.363,4.363,0,0,0-4.364,4.364A4.363,4.363,0,0,0,336.394-159.375Zm0,2.182c-2.913,0-8.727,1.462-8.727,4.364v2.182h17.455v-2.182C345.122-155.731,339.307-157.193,336.394-157.193Z"
                transform="translate(415.605 1572.375)"
                fill="#8ca0ef"
              />
            </g>
          </svg>
          <h4>스페셜리스트</h4>
        </div>
        <div class="selectBox">
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="드라마"
              id="sdrama"
            />
            <label for="sdrama"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="영화"
              id="smovie"
            />
            <label for="smovie"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="연극"
              id="stheater"
            />
            <label for="stheater"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="뷰티"
              id="sbeauty"
            />
            <label for="sbeauty"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="코스프레"
              id="scostume"
            />
            <label for="scostume"></label>
          </div>
          <div>
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="패션"
              id="fashion"
            />
            <label for="fashion"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="안무"
              id="S-dance"
            />
            <label for="S-dance"></label>
          </div>
          <div id="marginNone">
            <input
              type="checkbox"
              v-model="fieldData.interests.specialist"
              value="브이로그"
              id="vlog"
            />
            <label for="vlog"></label>
          </div>
        </div>
      </div>
      <div class="suggest">
        <span>원하시는 키워드가 없나요?</span>
        <span class="suggestBtn" @click="suggestPopup">키워드 제안하기</span>
      </div>
      <div class="nextBtn" @click="nextPage">완료</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "setKeyword",

  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },
  computed: {
    fieldData() {
      return this.$store.state.userSetup.userInfoSetup;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  data() {
    return {
      popup: false,
      suggest: "",
    };
  },
  methods: {
    suggestCancel() {
      this.suggest = "";
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
      this.popup = false;
    },
    suggestPopup() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.popup = true;
    },
    nextPage() {
      this.$router.go(-1);
    },
    closeBtn() {
      this.$router.go(-1);
    },
    dosuggest() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        if (this.suggest == "") {
          alert("건의하실 내용을 적어주세요.");
        } else if (this.suggest !== "") {
          this.$axios
            .post(
              this.API_URL + "/accounts/report-field",
              {
                userID: token.userID,
                play_field: this.suggest,
              },
              {
                headers: { Authorization: `Bearer ${token.access_token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert("성공적으로 제출했습니다. 검토 후 반영하겠습니다.");
                this.popup = false;
                this.suggest = "";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (loginUg == "true") {
        if (this.suggest == "") {
          alert("건의하실 내용을 적어주세요.");
        } else if (this.suggest !== "") {
          let token = this.$cookie.get("access_token");
          let userID = this.$cookie.get("userID");
          this.$axios
            .post(
              this.API_URL + "/accounts/report-field",
              {
                userID: userID,
                play_field: this.suggest,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              if (res.data.status == 200) {
                alert("성공적으로 제출했습니다. 검토 후 반영하겠습니다.");
                this.popup = false;
                this.suggest = "";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.closeBtn {
  cursor: pointer;
  position: fixed;
  top: 40px;
  right: 40px;
}
.setKeyword {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  overflow: auto;
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.white-bg {
  width: 500px;
  height: 338px;
  padding: 0 50px;
  margin: 338px auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
}
.white-bg h2 {
  padding-top: 40px;
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #8ca0ef;
}
.white-bg p {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin: 30px;
}
.white-bg input {
  width: 384px;
  height: 40px;
  outline: none;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
}
.popupBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.popupBtns div {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupBtns div:last-child {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #707070;
  margin-left: 24px;
}
.wrap {
  width: 500px;
  height: 1774px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 98px auto;
  padding: 0 50px;
  margin-bottom: 114px;
  text-align: center;
}
.wrap h2 {
  padding: 40px 0;
  font: normal normal bold 28px/41px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
}
.wrap p {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  margin-bottom: 40px;
}
.wrap p > span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
}
.selectHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.selectHeader h4 {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
}
.selectHeader svg {
  margin-right: 10px;
}
.selectBox {
  width: 384px;
  padding: 16px;
  display: flex;
  flex-flow: wrap;
  margin-bottom: 30px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.selectBox div {
  margin-right: 14px;
  height: 30px;
  margin-bottom: 12px;
}
#marginNone {
  margin-bottom: 0px;
}
.wrap input {
  display: none;
}
input + label {
  cursor: pointer;
}
input + label::before {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s;
}
input:checked + label::before {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: none;
  color: #ffffff;
}
input#ballad + label:before {
  content: "# 발라드";
  width: 100px;
  height: 30px;
}
input#rnb + label:before {
  content: "# 알앤비";
  width: 100px;
  height: 30px;
}
input#modernrock + label:before {
  content: "# 모던락";
  width: 100px;
  height: 30px;
}
input#kpop + label:before {
  content: "# k-pop";
  width: 100px;
  height: 30px;
}
input#acoustic + label:before {
  content: "# 어쿠스틱";
  width: 120px;
  height: 30px;
}
input#hiphop + label:before {
  content: "# 힙합";
  width: 80px;
  height: 30px;
}
input#jazz + label:before {
  content: "# 재즈";
  width: 90px;
  height: 30px;
}
input#pop + label:before {
  content: "# 팝";
  width: 80px;
  height: 30px;
}
input#disco + label:before {
  content: "# 디스코";
  width: 100px;
  height: 30px;
}
input#dance + label:before {
  content: "# 댄스";
  width: 90px;
  height: 30px;
}

/* 비디오 키워드 */
input#action + label:before {
  content: "# 액션";
  width: 90px;
  height: 30px;
}
input#comedy + label:before {
  content: "# 코미디";
  width: 100px;
  height: 30px;
}
input#horror + label:before {
  content: "# 공포";
  width: 90px;
  height: 30px;
}
input#romance + label:before {
  content: "# 로맨스";
  width: 100px;
  height: 30px;
}
input#adventure + label:before {
  content: "# 모험";
  width: 90px;
  height: 30px;
}
input#criminal + label:before {
  content: "# 범죄";
  width: 90px;
  height: 30px;
}
input#docu + label:before {
  content: "# 다큐";
  width: 90px;
  height: 30px;
}
input#f_drama + label:before {
  content: "# 드라마";
  width: 100px;
  height: 30px;
}
input#thriller + label:before {
  content: "# 스릴러";
  width: 100px;
  height: 30px;
}
input#fantasy + label:before {
  content: "# 판타지";
  width: 100px;
  height: 30px;
}
input#sfantasy + label:before {
  content: "# SF";
  width: 90px;
  height: 30px;
}
input#family + label:before {
  content: "# 가족";
  width: 90px;
  height: 30px;
}
input#suspense + label:before {
  content: "# 서스펜스";
  width: 120px;
  height: 30px;
}
input#animation + label:before {
  content: "# 애니메이션";
  width: 140px;
  height: 30px;
}
input#independentfilm + label:before {
  content: "# 독립영화";
  width: 120px;
  height: 30px;
}
input#f_youtube + label:before {
  content: "# 유튜브";
  width: 90px;
  height: 30px;
}
input#choreo + label:before {
  content: "# 코레오";
  width: 90px;
  height: 30px;
}
input#cgraphic + label:before {
  content: "# 컴퓨터그래픽";
  width: 150px;
  height: 30px;
}
input#filmlooks + label:before {
  content: "# 필름룩스";
  width: 120px;
  height: 30px;
}

/* 사진 키워드 */
input#cold + label:before {
  content: "# 차가운";
  width: 100px;
  height: 30px;
}
input#warm + label:before {
  content: "# 따뜻한";
  width: 100px;
  height: 30px;
}
input#film + label:before {
  content: "# 필름";
  width: 90px;
  height: 30px;
}
input#building + label:before {
  content: "# 건물";
  width: 90px;
  height: 30px;
}
input#album + label:before {
  content: "# 앨범";
  width: 90px;
  height: 30px;
}
input#artwork + label:before {
  content: "# 아트워크";
  width: 120px;
  height: 30px;
}
input#artistic + label:before {
  content: "# 예술";
  width: 90px;
  height: 30px;
}
input#nude + label:before {
  content: "# 누드";
  width: 90px;
  height: 30px;
}
input#modern + label:before {
  content: "# 모던";
  width: 90px;
  height: 30px;
}
input#costume + label:before {
  content: "# 코스프레";
  width: 120px;
  height: 30px;
}

/* 디자인 키워드 */
input#drawing + label:before {
  content: "# 드로잉";
  width: 100px;
  height: 30px;
}
input#painting + label:before {
  content: "# 회화";
  width: 90px;
  height: 30px;
}
input#collage + label:before {
  content: "# 콜라주";
  width: 100px;
  height: 30px;
}
input#handdraw + label:before {
  content: "# 손그림";
  width: 100px;
  height: 30px;
}
input#mockup + label:before {
  content: "# 목업디자인";
  width: 120px;
  height: 30px;
}
input#threed + label:before {
  content: "# 3D";
  width: 80px;
  height: 30px;
}
input#modeling + label:before {
  content: "# 모델링";
  width: 100px;
  height: 30px;
}
input#branding + label:before {
  content: "# 브랜딩";
  width: 100px;
  height: 30px;
}
input#kitsch + label:before {
  content: "# 키치";
  width: 90px;
  height: 30px;
}
input#package + label:before {
  content: "# 패키지";
  width: 100px;
  height: 30px;
}
input#photograpy + label:before {
  content: "# 포토그래피";
  width: 120px;
  height: 30px;
}

/* 스페셜리스트 키워드 */
input#sdrama + label:before {
  content: "# 드라마";
  width: 100px;
  height: 30px;
}
input#smovie + label:before {
  content: "# 영화";
  width: 90px;
  height: 30px;
}
input#stheater + label:before {
  content: "# 연극";
  width: 90px;
  height: 30px;
}
input#sbeauty + label:before {
  content: "# 뷰티";
  width: 90px;
  height: 30px;
}
input#scostume + label:before {
  content: "# 코스프레";
  width: 120px;
  height: 30px;
}
input#fashion + label:before {
  content: "# 패션";
  width: 90px;
  height: 30px;
}
input#S-dance + label:before {
  content: "# 안무";
  width: 90px;
  height: 30px;
}
input#vlog + label:before {
  content: "# 브이로그";
  width: 120px;
  height: 30px;
}
.suggest {
  padding-top: 10px;
}
.suggest span {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
}
.suggestBtn {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}
.nextBtn {
  width: 384px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;

  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
}
</style>
