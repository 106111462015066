<template>
  <div id="myworks">
    <div class="items">
      <div
        class="item"
        v-for="(item, index) in myPost"
        v-bind:key="index"
        @mouseover="hover(index)"
        @mouseleave="hoverleave"
      >
        <img
          :src="item.PVData.thumbnail_file"
          @click="goPost(item.PVData.feed_id)"
        />
        <div
          class="hoverBox"
          :class="{ columnHoverEffect: columnHoverCheck(index) }"
          @click="goPost2(item.PVData.feed_id)"
          style="cursor: pointer"
          @mouseleave="boxClose"
        >
          <span class="hoverBox-name">{{ item.PVData.feed_title }}</span>
          <div class="btnBox" v-if="me_chk == true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              @click="retouchBoxBtn"
            >
              <g
                id="그룹_19679"
                data-name="그룹 19679"
                transform="translate(-151 -685)"
              >
                <rect
                  id="사각형_24937"
                  data-name="사각형 24937"
                  width="30"
                  height="30"
                  transform="translate(151 685)"
                  fill="none"
                />
                <path
                  id="패스_8050"
                  data-name="패스 8050"
                  d="M166,688h0a12.035,12.035,0,0,1,12,12h0a12.035,12.035,0,0,1-12,12h0a12.035,12.035,0,0,1-12-12h0A12.035,12.035,0,0,1,166,688Z"
                  fill="#fff"
                />
                <circle
                  id="_타원_185"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(158 698)"
                  fill="#707070"
                />
                <circle
                  id="_타원_186"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(164 698)"
                  fill="#707070"
                />
                <circle
                  id="_타원_187"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(170 698)"
                  fill="#707070"
                />
              </g>
            </svg>
            <div v-if="retouchBox == true" class="retouchBox">
              <div @click="retuchBtn(item.PVData)">수정하기</div>
              <div @click="deleteBtn(item.PVData.feed_id)">삭제</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";

export default {
  name: "PFmyworks",
  created() {
    this.paramsID(this.$route.params.id);
  },
  mounted() {
    this.handleScroll = throttle(this.checkBottom, 2000);
    window.addEventListener("scroll", this.handleScroll);
  },

  data() {
    return {
      hoverBar: null,
      myPost: [],
      retouchBox: false,
      me_chk: false,
      isLogin: false,
      feedsTotalCount: "",
      currentFeedCount: "",
      likeBtnChk: false,
    };
  },
  computed: {
    API_URL() {
      return this.$store.state.API_URL;
    },
    navStore() {
      return this.$store.state.navStore.navData;
    },
  },
  methods: {
    checkBottom(event) {
      var isScrollAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (isScrollAtBottom) {
        if (this.feedsTotalCount > this.currentFeedCount) {
          if (this.isLogin == true) {
            this.$axios
              .get(this.API_URL + "/portfolios/load-additional-preview", {
                params: {
                  userID: this.navStore.userID,
                  feederID: this.$route.params.id,
                  currentFeedCount: this.currentFeedCount,
                },
              })
              .then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                  for (let i in res.data.preview_data) {
                    let PVData = {
                      my_like: res.data.preview_data[i].my_like,
                      thumbnail_file: res.data.preview_data[i].thumbnail_file,
                      feed_id: res.data.preview_data[i].feed_id,
                      feed_title: res.data.preview_data[i].feed_title,
                    };
                    this.myPost.push({ PVData });
                  }
                  this.currentFeedCount = res.data.currentFeedCount;
                } else {
                  console.log(res);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (this.isLogin == false) {
            this.$axios
              .get(this.API_URL + "/portfolios/load-additional-preview", {
                params: {
                  feederID: this.$route.params.id,
                  currentFeedCount: this.currentFeedCount,
                },
              })
              .then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                  for (let i in res.data.preview_data) {
                    let PVData = {
                      my_like: res.data.preview_data[i].my_like,
                      thumbnail_file: res.data.preview_data[i].thumbnail_file,
                      feed_id: res.data.preview_data[i].feed_id,
                      feed_title: res.data.preview_data[i].feed_title,
                    };
                    this.myPost.push({ PVData });
                  }
                  this.currentFeedCount = res.data.currentFeedCount;
                } else {
                  console.log(res);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    },
    paramsID(id) {
      this.myPost = [];
      let token = this.$cookie.get("access_token");
      let sessionToken = JSON.parse(sessionStorage.getItem("token"));
      if (sessionToken !== null || token !== null) {
        this.isLogin = true;
        let userID = this.$cookie.get("userID");
        if (userID == this.$route.params.id) {
          this.me_chk = true;
        }
        this.$axios
          .get(this.API_URL + "/portfolios/load-preview", {
            params: {
              userID: userID,
              feederID: this.$route.params.id,
            },
          })
          .then((res) => {
            for (let i in res.data.preview_data) {
              let PVData = {
                my_like: res.data.preview_data[i].my_like,
                thumbnail_file: res.data.preview_data[i].thumbnail_file,
                feed_id: res.data.preview_data[i].feed_id,
                feed_title: res.data.preview_data[i].feed_title,
              };
              this.myPost.push({ PVData });
            }
            this.currentFeedCount = res.data.currentFeedCount;
            this.feedsTotalCount = res.data.feedsTotalCount;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (sessionToken == null || token == null) {
        this.isLogin = false;
        this.$axios
          .get(this.API_URL + "/portfolios/load-preview", {
            params: { feederID: this.$route.params.id },
          })
          .then((res) => {
            for (let i in res.data.preview_data) {
              let PVData = {
                my_like: res.data.preview_data[i].my_like,
                thumbnail_file: res.data.preview_data[i].thumbnail_file,
                feed_id: res.data.preview_data[i].feed_id,
                feed_title: res.data.preview_data[i].feed_title,
              };
              this.myPost.push({ PVData });
            }
            this.currentFeedCount = res.data.currentFeedCount;
            this.feedsTotalCount = res.data.feedsTotalCount;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    hover(index) {
      this.hoverBar = index;
    },

    hoverleave() {
      this.hoverBar = null;
    },
    columnHoverCheck(index) {
      if (index === this.hoverBar) {
        return true;
      } else {
        return false;
      }
    },
    boxClose() {
      this.retouchBox = false;
    },
    goPost(userId) {
      this.$router.push({ path: "/postview/" + userId });
    },
    goPost2(userId) {
      if (this.likeBtnChk == true) {
        this.likeBtnChk = false;
      } else {
        this.$router.push({ path: "/postview/" + userId });
        this.likeBtnChk = false;
      }
    },
    retouchBoxBtn() {
      this.likeBtnChk = true;
      this.retouchBox = !this.retouchBox;
    },
    likeBtn(feed_id, index) {
      this.likeBtnChk = true;
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .post(
            this.API_URL + "/portfolios/feed-like",
            { userID: token.userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token.access_token}` },
            }
          )
          .then((res) => {
            this.myPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .post(
            this.API_URL + "/portfolios/feed-like",
            { userID: userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.myPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    unLikeBtn(feed_id, index) {
      this.likeBtnChk = true;

      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .patch(
            this.API_URL + "/portfolios/feed-like",
            { userID: token.userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token.access_token}` },
            }
          )
          .then((res) => {
            this.myPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .patch(
            this.API_URL + "/portfolios/feed-like",
            { userID: userID, feed_id: feed_id },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.myPost[index].PVData.my_like = res.data.my_like;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteBtn(feed_id) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .delete(this.API_URL + "/portfolios/edit-feed", {
            params: { userID: token.userID, feed_id: feed_id },
            headers: { Authorization: `Bearer ${token.access_token}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
              this.$router.go();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");
        this.$axios
          .delete(this.API_URL + "/portfolios/edit-feed", {
            params: { userID: userID, feed_id: feed_id },
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              alert(res.data.msg);
              this.$router.go();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    retuchBtn(item) {
      this.$router.push({ path: "/pfretouch/" + item.feed_id });
    },
  },
  watch: {
    $route(to, form) {
      if (to.path !== form.path) this.paramsID(this.$route.params.id);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#myworks {
  margin-top: 30px;
  margin-bottom: 100px;
}

.items {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.item {
  width: 282px;
  height: 282px;
  margin-right: 18px;
  margin-bottom: 18px;
  position: relative;
}

.item img {
  width: 282px;
  height: 282px;
  cursor: pointer;
  border-radius: 4px;
}

.hoverBox {
  position: absolute;
  bottom: 0;
  width: 282px;
  height: 110px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  align-items: flex-end;
  padding-bottom: 22px;
  opacity: 0;
  transition: 0.2s;
}
.columnHoverEffect {
  opacity: 1;
}

.hoverBox-name {
  width: 160px;
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  margin-left: 20px;
}
.btnBox {
  display: flex;
  position: relative;
}
.btnBox svg {
  width: 30px;
  height: 30px;
  margin-left: 40px;
  cursor: pointer;
}
.likeBtn {
  margin-right: 10px;
  transition: 0.2s;
}
.retouchBox {
  width: 84px;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  right: 2px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  z-index: 5;
}
.retouchBox div {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
  /* margin-bottom: 10px; */
  cursor: pointer;
  width: 84px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.retouchBox div:last-child {
  color: #b73737;
}
</style>
