<template>
  <div class="educationSet">
    <div class="white-bg">
      <h2>학력</h2>
      <div class="main">
        <div class="inputBox">
          <h4>학교</h4>
          <input type="text" v-model="retouch.name" />
        </div>
        <div class="inputBox">
          <h4>전공</h4>
          <input type="text" v-model="retouch.class" />
        </div>
      </div>
      <div class="main">
        <div class="inputBox2">
          <h4>재학 기간</h4>
          <div class="inputBox2-input">
            <input type="number" v-model="retouch.dateS" maxlength="4" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="2"
              viewBox="0 0 16 2"
            >
              <g
                id="사각형_25090"
                data-name="사각형 25090"
                fill="#fff"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="16" height="2" stroke="none" />
                <rect x="0.5" y="0.5" width="15" height="1" fill="none" />
              </g>
            </svg>
            <input type="number" v-model="retouch.dateE" maxlength="4" />
            <select name="status" id="status" v-model="retouch.status">
              <option value="재학">재학</option>
              <option value="졸업">졸업</option>
              <option value="휴학">휴학</option>
              <option value="중퇴">중퇴</option>
            </select>
          </div>
        </div>
      </div>
      <div class="Btns">
        <div @click="cancelBtn">취소</div>
        <div @click="pushEducation">완료</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "educationSet",
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },

  data() {
    return {};
  },
  computed: {
    retouch() {
      return this.$store.state.userSetup.educationRetouch;
    },
  },
  methods: {
    cancelBtn() {
      if (this.retouch.name == "") {
        alert("학교를 입력해주세요.");
      } else if (this.retouch.class == "") {
        alert("전공을 입력해주세요.");
      } else if (this.retouch.dateS == "") {
        alert("재학기간을 입력해주세요.");
      } else if (this.retouch.dateE == "") {
        alert("재학기간을 입력해주세요.");
      } else {
        this.$store.commit("retouch_schoolList", {
          name: this.retouch.name,
          class: this.retouch.class,
          dateS: this.retouch.dateS,
          dateE: this.retouch.dateE,
          status: this.retouch.status,
        });
        this.$router.go(-1);
      }
    },
    pushEducation() {
      if (this.retouch.name == "") {
        alert("학교를 입력해주세요.");
      } else if (this.retouch.class == "") {
        alert("전공을 입력해주세요.");
      } else if (this.retouch.dateS == "") {
        alert("재학기간을 입력해주세요.");
      } else if (this.retouch.dateE == "") {
        alert("재학기간을 입력해주세요.");
      } else {
        this.$store.commit("retouch_schoolList", {
          name: this.retouch.name,
          class: this.retouch.class,
          dateS: this.retouch.dateS,
          dateE: this.retouch.dateE,
          status: this.retouch.status,
        });
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
select {
  width: 170px;
  height: 40px;
  padding-left: 20px;
  padding-right: 4px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../../img/userSet/profile/downArrow.png") no-repeat 97% 50%/15px
    auto;
  outline: none;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  margin-left: 20px;
}
.educationSet {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.white-bg {
  width: 752px;
  height: 326px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 377px;
  padding: 0 30px;
}
.white-bg h2 {
  font: normal normal normal 18px/26px Noto Sans CJK KR;
  letter-spacing: -0.45px;
  color: #191919;
  padding: 30px 0;
}
.main {
  display: flex;
  margin-bottom: 10px;
}
.inputBox h4,
.inputBox2 h4 {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #191919;
  margin-bottom: 10px;
}
.inputBox input {
  outline: none;
  border: none;
  width: 336px;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.inputBox2-input {
  display: flex;
  align-items: center;
}
.inputBox2 input {
  outline: none;
  border: none;
  width: 150px;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px 20px;
  box-sizing: border-box;
}
.inputBox2 svg {
  margin: 0 10px;
}

.Btns {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Btns div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Btns div:first-child {
  width: 100px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 20px;

  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.Btns div:last-child {
  width: 100px;
  height: 30px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 20px;

  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #ffffff;
  margin-left: 20px;
}
</style>
