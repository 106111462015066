const chat = {
  state() {
    return {
      collabopopup: false,
      reportpopup: false,
      collaboAccept: false,
      collaboEnd: false,
      collaboEnd2: false,
      collaboStop: false,
      collaboStop2: false,
      payback: false,
      review: false,
      cowork_id: "",
      reportUser: "",
      collaboData: {},
      paybackData: {},
      reviewData: {},
    };
  },
  getters: {},
  mutations: {
    collaboBtn(state, payload) {
      state.collabopopup = !state.collabopopup;
      state.collaboData = payload;
    },
    reportBtn(state, payload) {
      state.reportpopup = !state.reportpopup;
      state.reportUser = payload;
    },
    collaboAccept(state, payload) {
      state.collaboAccept = !state.collaboAccept;
      state.cowork_id = payload;
    },
    collaboEnd(state, payload) {
      state.collaboEnd = !state.collaboEnd;
      state.cowork_id = payload;
    },
    collaboEnd2(state, payload) {
      state.collaboEnd2 = !state.collaboEnd2;
      state.cowork_id = payload;
    },
    collaboStop(state, payload) {
      state.collaboStop = !state.collaboStop;
      state.cowork_id = payload;
    },
    collaboStop2(state, payload) {
      state.collaboStop2 = !state.collaboStop2;
      state.cowork_id = payload;
    },
    payback(state, payload) {
      state.payback = !state.payback;
      state.collaboData = payload.members_info;
      state.paybackData = payload.item;
    },
    review(state, payload) {
      state.review = !state.review;
      state.reviewData = payload;
    },
  },
  actions: {},
};

export default chat;
