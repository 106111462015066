import axios from "axios";
import { router } from "@/router/index.js";

const userAccount = {
  namespaced: true,
  state: {
    userData: {
      loginId: "",
      password: "",
      nickname: "",
      email: "",
      gender: "",
      isConsentedUseOfMarketingInformation: false,
      identityLookupId: "",
      isGenderDisclosed: true,
    },
    fieldData: {
      main_field: "",
      main_role: "",
      field: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
      interests: {
        music: [],
        video: [],
        photography: [],
        design: [],
        specialist: [],
      },
    },
  },
  getters: {
    userData() {
      return state.userData;
    },
  },
  mutations: {
    SET_MARKETING(state, payload) {
      state.userData.isConsentedUseOfMarketingInformation = payload.marketing;
      state.userData.identityLookupId = payload.imp_id;
    },
    SET_USERINFO(state, payload) {
      state.userData.loginId = payload.userID;
      state.userData.password = payload.userPW;
      state.userData.nickname = payload.nickname;
      state.userData.email = payload.email;
      state.userData.gender = payload.gender;
    },
  },
  actions: {
    doAccount({ commit }, payload) {
      commit("SET_USERINFO", payload);
    },
  },
};

export default userAccount;
