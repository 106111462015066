export default [
  {
    path: "/chat/:id",
    name: "chat",
    component: () => import("@/views/chating/chatView.vue"),
  },
  {
    path: "/chat2",
    name: "chat2",
    component: () => import("@/views/chating/chat2View.vue"),
  },
];
