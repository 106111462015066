const PFDataSet = {
  state() {
    return {
      preview_data_all: [],
      preview_data_follow: [],
      followView: false,
      followingView: false,
      othfollowView: false,
      othfollowingView: false,
      me_chk: false,
    };
  },
  getters: {},
  mutations: {
    set_me_chk(state, payload) {
      state.me_chk = payload;
    },
    followViewToggle(state) {
      if (state.followingView == true) {
        state.followingView = false;
        state.followView = !state.followView;
      } else {
        state.followView = !state.followView;
      }
    },
    othfollowViewToggle(state) {
      if (state.othfollowingView == true) {
        state.othfollowingView = false;
        state.othfollowView = !state.othfollowView;
      } else {
        state.othfollowView = !state.othfollowView;
      }
    },
    followingViewToggle(state) {
      if (state.followView == true) {
        state.followView = false;
        state.followingView = !state.followingView;
      } else {
        state.followingView = !state.followingView;
      }
    },
    othfollowingViewToggle(state) {
      if (state.othfollowView == true) {
        state.othfollowView = false;
        state.othfollowingView = !state.othfollowingView;
      } else {
        state.othfollowingView = !state.othfollowingView;
      }
    },

    set_preview_data(state, payload) {
      // let keyword = payload.feed_keyword[0].split(",");
      let PVData = {
        feed_id: payload.feed_id,
        thumbnail: payload.thumbnail_file,
        profile_img: payload.feeder_profile_img,
        nickName: payload.feeder_nickname,
        main_field: payload.feeder_main_field,
        main_role: payload.feeder_main_role,
        userID: payload.feederID,
        feed_title: payload.feed_title,
        feed_keyword: payload.feed_keyword[0].split(","),
        feed_field: payload.feed_field,
      };
      state.preview_data_all.push({ PVData });
    },
    del_preview_data(state) {
      state.preview_data_all = [];
    },
    del_preview_data_follow(state) {
      state.preview_data_follow = [];
    },
    set_preview_data_follow(state, payload) {
      let PVData = {
        feed_id: payload.feed_id,
        thumbnail: payload.thumbnail_file,
        profile_img: payload.feeder_profile_img,
        nickName: payload.feeder_nickname,
        main_field: payload.feeder_main_field,
        main_role: payload.feeder_main_role,
        userID: payload.feederID,
        feed_title: payload.feed_title,
      };
      state.preview_data_follow.push({ PVData });
    },
  },
  actions: {},
};

export default PFDataSet;
