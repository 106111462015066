<template>
  <div id="nav">
    <div class="nav-map">
      <router-link to="/" class="nav-item nav-title">
        <img src="../img/MainLogo.jpg" />
        <span>RUBATO</span></router-link>
      <div class="nav-item" @click="goFeed" style="cursor: pointer">
        PORTFOLIO
      </div>
      <div class="nav-item" @click="goSearching" style="cursor: pointer">
        SEARCHING
      </div>
      <!-- <router-link
        to="/atmatchingview/pushartist"
        class="nav-item"
        >SEARCHING</router-link
      > -->
      <!-- <div class="nav-item noneMvp">COMMUNITY</div> -->
      <router-link to="/community/mainpage/1" class="nav-item">COMMUNITY</router-link>
    </div>

    <div class="nav-map2 nav-map2Btn" v-if="isLogin !== true">
      <router-link to="/login" class="loginBtn">로그인</router-link>
      <router-link to="/consent" class="accountBtn">회원가입</router-link>
    </div>
    <div class="nav-map2" v-if="isLogin == true">
      <div class="uploadBtn" @click="uploadBtn">
        <i class="far fa-plus"></i><span>업로드</span>
      </div>
      <div class="message">
        <img src="@/img/nav/message.jpg" alt="" @click="messageBtn" v-if="message_check == true" />
        <img src="@/img/nav/nomessage.jpg" alt="" @click="messageBtn" v-if="message_check == false" />
      </div>
      <div class="allam" @click="allamBtn" style="display: none">
        <img src="@/img/nav/allam.jpg" alt="" v-if="allam_check == true" />
        <img src="@/img/nav/noallam.jpg" alt="" v-if="allam_check == false" />
      </div>
      <div class="profile" @click="profileBtn">
        <img src="@/img/nav/humanIcon.jpg" alt="" />
      </div>
      <transition name="fade">
        <div class="profile-sidebar" v-if="user">
          <div class="userSection">
            <div class="userImg">
              <img :src="userData.profileImg" alt="" @click="myPortFolio" />
            </div>
            <div class="user">
              <h4>{{ userData.nickname }}</h4>
              <span v-if="userData.main_field !== ''">{{ userData.main_field }} / {{ userData.main_role }}</span>
            </div>
          </div>
          <div class="setupPage">
            <span @click="setting">설정</span>
            <span @click="myPortFolio">포트폴리오 관리</span>
          </div>
          <div class="setupPage2">
            <span @click="logout">로그아웃</span>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="allam-sidebar" v-if="allam">
          <h2>알림</h2>
          <div class="userBox">
            sdf
            <!-- <div
              v-for="(allam, index) in userStore.userAllam"
              v-bind:key="index"
            >
              <div class="allam-users">
                <div class="allam-users__img">
                  <img src="@/img/IUimg.jpg" alt="" />
                </div>
                <div>
                  <span>{{ allam.name }}님의 협업제안</span>
                  <p>{{ allam.text }}</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  computed: {
    userStore() {
      return this.$store.state.userStore;
    },
    userData() {
      return this.$store.state.navStore.navData;
    },

    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  created() { },
  mounted() {
    let token = this.$cookie.get("access_token");
    let sessionToken = JSON.parse(sessionStorage.getItem("token"));
    if (sessionToken !== null || token !== null) {
      this.isLogin = true;
    } else if (sessionToken == null || token == null) {
      this.isLogin = false;
    }
    if (this.isLogin == true) {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        this.userID = sessionToken.userID;
        this.$axios
          .get(this.API_URL + "/accounts/profiles", {
            params: { userID: sessionToken.userID },
            headers: { Authorization: `Bearer ${sessionToken.access_token}` },
          })
          .then((res) => {
            this.$store.commit(
              "set_userID",
              res.data.profile_information.userID
            );
            this.userData.nickname = res.data.profile_information.nickname;
            this.userData.main_field = res.data.profile_information.main_field;
            this.userData.main_role = res.data.profile_information.main_role;
          })
          .catch((err) => {
            console.log(err);
          });
        this.$axios
          .get(this.API_URL + "/accounts/profile-img", {
            params: { userID: sessionToken.userID },
            headers: { Authorization: `Bearer ${sessionToken.access_token}` },
          })
          .then((res) => {
            this.userData.profileImg = res.data.profile_img;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let userID = this.$cookie.get("userID");
        this.userID = userID;
        this.$axios
          .get(this.API_URL + "/accounts/profiles", {
            params: { userID: userID },
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            this.userData.nickname = res.data.profile_information.nickname;
            this.userData.main_field = res.data.profile_information.main_field;
            this.userData.main_role = res.data.profile_information.main_role;
            this.$store.commit(
              "set_userID",
              res.data.profile_information.userID
            );
          })
          .catch((err) => {
            console.log(err);
          });
        this.$axios
          .get(this.API_URL + "/accounts/profile-img", {
            params: { userID: userID },
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            this.userData.profileImg = res.data.profile_img;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // this.$socket.emit("alarm", { userID: this.userID });
    }
    // this.$socket.on("AlarmInfo", (data) => {
    //   if (data.status == 200) {
    //     console.log(data);
    //     this.message_check = data.newMessage;
    //     if (data.alarmInfo.length == 0) {
    //       this.allam_check = false;
    //     } else {
    //     }
    //   } else {
    //     console.log(data);
    //   }
    // });
    // emit: alarm, on : AlarmInfo
  },
  data() {
    return {
      user: false,
      allam: false,
      isLogin: false,
      lastRoom: "",
      userID: "",
      message_check: false,
      allam_check: false,
      alarmInfo: [],
    };
  },
  methods: {
    goFeed() {
      this.$store.commit("set_scroll", 0);
      this.$router.push({ path: "/portfolio/userfeedall" });
    },
    profileBtn() {
      if (this.user == false) {
        this.user = true;
        this.allam = false;
        this.message = false;
      } else if (this.user == true) {
        this.user = false;
      }
    },
    allamBtn() {
      if (this.allam == false) {
        this.allam = true;
        this.user = false;
        this.message = false;
      } else if (this.allam == true) {
        this.allam = false;
      }
    },
    messageBtn() {
      this.$socket.emit("join", { userID: this.userData.userID });
      this.$socket.on("JoinRoom", (data) => {
        if (Object.keys(data.ChatInfo).length == 0) {
          this.lastRoom = null;
        } else {
          this.lastRoom = data.ChatInfo[0].room_id;
        }
        this.$socket.off("JoinRoom");
      });
    },
    myPortFolio() {
      this.$router.push({
        path: "/portfoliodetail/" + this.userData.userID + "/PFWork",
      });
      this.user = false;
    },
    uploadBtn() {
      this.$router.push({ path: "/pfuploadView" });
    },
    setting() {
      this.$router.push({ path: "/usersetting/profileset" });
    },
    goSearching() {
      this.$router.push({ path: "/atmatchingview/pushartist" });
    },

    async logout() {

      const confirmLogout = confirm( "로그아웃 하시겠습니까?" );
      if(!confirmLogout){ return }
      else{
        // 소켓 연결 끊어졌는지 확인 후 끊어진 경우 재연결
        if (!this.isSocketConnected()) {
          this.$socket.connect();
          // alert("소켓 연결이 끊어졌습니다. 새로고침 후 다시 시도해주세요.");
          // return;
        }

        try {
          console.log(this.$socket);
          // 서버에 로그아웃 요청
          // 비동기 함수로 소켓 이벤트를 보내고 응답을 기다리는 방식으로 구현
          const data = await this.emitAndWait(this.$socket, "serverDisconnect", { userID: this.userData.userID }, "SocketDisconnect");
          if (data.status == 200) {
            this.$cookie.delete("userID");
            this.$cookie.delete("access_token");
            this.$cookie.delete("refresh_token");
            sessionStorage.clear();
            localStorage.clear();
            this.$router.replace({ path: "/" });
            // 소켓 연결 끊고, 재연결
            this.$socket.disconnect();
            this.$socket.connect();
          } else {
            console.log(data);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    },

    // 소켓 연결 상태 확인
    isSocketConnected() {
      return this.$socket && this.$socket.connected;
    },

    // 비동기 함수로 소켓 이벤트를 보내고 응답을 기다리는 함수
    emitAndWait(socket, emitEvent, emitData, onEvent) {
      return new Promise((resolve, reject) => {
        function eventHandler(responseData) {
          resolve(responseData);
          socket.off(onEvent, eventHandler);
        }

        socket.on(onEvent, eventHandler);
        socket.emit(emitEvent, emitData);

        setTimeout(() => {
          reject(new Error('Timeout waiting for event'));
          socket.off(onEvent, eventHandler);
        }, 5000); // 5초 타임아웃 (필요에 따라 조정 가능)
      });
    },
  },
  watch: {
    lastRoom() {
      // 화면에 추가된 후 동작하도록
      this.$nextTick(() => {
        if (this.lastRoom == null) {
          this.$router.push({
            name: "chat2",
          });
        } else {
          this.$router.push({
            path: "/chat/" + this.lastRoom,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

#nav {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  z-index: 5;
}

.nav-map,
.nav-map2 {
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 1;
}

.nav-map2Btn {
  padding-right: 20px;
}

.nav-title img {
  width: 60px;
  height: 60px;
  margin-left: 30px;
  margin-right: 10px;
}

.nav-title {
  display: flex;
  align-items: center;
}

.nav-title span {
  font: normal normal normal 28px/41px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #191919;
  margin-right: 10px;
  margin-top: -6px;
  font-weight: bold;
}

.nav-item {
  margin-left: 20px;
  font: normal normal normal 18px/22px Roboto;
  letter-spacing: -0.45px;
  color: #444444;
  text-decoration: none; /* 링크의 밑줄 제거 */
  color: inherit;
}

.nav-map2 {
  height: 60px;
  justify-content: end;
  margin-right: 15px;
  background-color: white;
}

.loginBtn,
.accountBtn {
  width: 90px;
  height: 30px;
  text-decoration: none; /* 링크의 밑줄 제거 */
  box-sizing: border-box;
  font-size: 16px;
}

.loginBtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;
  padding: 4px;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #8ca0ef;
  text-align: center;
  color: #8ca0ef;
  margin-right: 10px;
  margin-top: 0;
}

.accountBtn {
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  font-size: 16px;
  padding: 4px;
  text-align: center;
}

.uploadBtn {
  width: 108px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 15px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #8ca0ef;
  padding: 1px;
  text-align: center;
  box-sizing: border-box;
  margin-right: 30px;
  cursor: pointer;
}

.uploadBtn i {
  margin-right: 5px;
}

.profile,
.allam,
.message {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.profile img,
.allam img,
.message img {
  width: 30px;
  height: 30px;
}

.profile-sidebar {
  width: 300px;
  height: 980px;
  background: #fefefe 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  position: fixed;
  top: 60px;
  right: 0;
  padding: 0 30px;
  box-sizing: border-box;
}

.userSection {
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}

.userImg {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  cursor: pointer;
}

.userImg img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.user h4 {
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: -0.5px;
  color: #191919;
  font-weight: 600;

  margin-bottom: 10px;
}

.user span {
  font: normal normal normal 14px/25px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
}

.setupPage {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.setupPage span,
.setupPage2 span {
  margin-top: 10px;
  font: normal normal normal 16px/25px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  font-weight: 500;
  cursor: pointer;
}

.setupPage2 {
  margin-top: 10px;
}

.allam-sidebar,
.message-sidebar {
  width: 350px;
  height: 410px;
  background: #fefefe 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  position: fixed;
  top: 70px;
  right: 10px;
  padding: 0 30px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.userBox {
  margin-top: 70px;
}

.allam-sidebar h2,
.message-sidebar h2 {
  background: #fefefe;
  font: normal normal normal 20px/25px Noto Sans CJK KR;
  letter-spacing: -0.5px;
  color: #191919;
  font-weight: 600;
  padding: 20px 0;
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;

  position: fixed;
  top: 70px;
  width: 270px;
}

.allam-users,
.message-users {
  height: 74px;
  border-bottom: 1px solid #dbdbdb;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.allam-users__img,
.message-users__img {
  width: 24px;
  height: 24px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
  float: left;
}

.allam-users__img img,
.message-users__img img {
  width: 24px;
  height: 24px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.allam-users span,
.message-users span {
  font: normal normal normal 16px/25px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}

.allam-users p,
.message-users p {
  font: normal normal normal 14px/25px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noneMvp {
  color: #dbdbdb;
}
</style>
