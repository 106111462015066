import PFUploadPopup from "@/components/myPortfolio/PFUploadPopup";
import PFretouch_pop from "@/components/myPortfolio/PFretouch_pop";

//다른사람꺼
import otherWorks from "@/components/otherPF/otherWorks";
import otherProfile from "@/components/otherPF/otherProfile";

import UserfeedAll from "../../components/myPortfolio/userfeedAll.vue";
import userfeedFollow from "../../components/myPortfolio/userfeedFollow";

// 변경
import PFwork from "../../components/portfolio/PFwork.vue";
import PFrefer from "../../components/portfolio/PFrefer.vue";
import PFprofile from "../../components/portfolio/PFprofile.vue";

export default [
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import("../../views/portfolio/PortfolioView.vue"),
    children: [
      {
        path: "userfeedall",
        name: "userfeedall",
        component: UserfeedAll,
      },
      {
        path: "userfeedfollow",
        name: "userfeedfollow",
        component: userfeedFollow,
      },
    ],
  },

  {
    path: "/portfoliodetail/:id",
    name: "portfoliodetail",
    component: () => import("../../views/portfolio/portfolioDetail.vue"),
    children: [
      {
        path: "PFwork",
        name: "PFwork",
        component: PFwork,
      },
      {
        path: "PFrefer",
        name: "PFrefer",
        component: PFrefer,
      },
      {
        path: "PFprofile",
        name: "PFprofile",
        component: PFprofile,
      },
    ],
  },
  {
    path: "/otherpfview/:id",
    name: "otherpfview",
    component: () => import("../../views/portfolio/otherPFView.vue"),
    children: [
      {
        path: "otherWorks",
        name: "otherWorks",
        component: otherWorks,
      },

      {
        path: "otherProfile",
        name: "otherProfile",
        component: otherProfile,
      },
    ],
  },
  {
    path: "/pfuploadView",
    name: "pfuploadView",
    component: () => import("../../views/portfolio/PFUploadView.vue"),
    children: [
      {
        path: "uploadpopup",
        name: "uploadpopup",
        component: PFUploadPopup,
      },
    ],
  },
  {
    path: "/pfretouch/:id",
    name: "pfretouch",
    component: () => import("../../views/portfolio/PFretouch.vue"),
    children: [
      {
        path: "PFretouch_pop",
        name: "PFretouch_pop",
        component: PFretouch_pop,
      },
    ],
  },
];
