<template>
  <div id="userInfoCheck">
    <h2>회원 정보 수정</h2>
    <p>본인 인증을 위해<br />비밀번호를 입력해주세요.</p>
    <div class="inputBox">
      <h4>비밀번호</h4>
      <input
        type="password"
        placeholder="비밀번호를 입력하세요."
        v-model="password"
      />
      <span class="errMessage" v-if="passWordErr"
        >비밀번호 형식이 맞지 않습니다.</span
      >
    </div>
    <div class="goNext" @click="goNext">다음</div>
  </div>
</template>

<script>
export default {
  name: "userInfoCheck",
  data() {
    return {
      password: "",
      passWordErr: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userSetup.userInfo;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  methods: {
    goNext() {
      let loginUg = localStorage.getItem("loginUg");
      if (loginUg == "false") {
        let token = JSON.parse(sessionStorage.getItem("token"));
        this.$axios
          .get(this.API_URL + "/accounts/user", {
            params: { userID: token.userID, userPW: this.password },
            headers: { Authorization: `Bearer ${token.access_token}` },
          })
          .then((res) => {
            if (res.data.status == 400) {
              alert(res.data.msg);
            } else if (res.data.status == 200) {
              this.userInfo.userID = res.data.result.userID;
              this.userInfo.nickname = res.data.result.nickname;
              this.userInfo.name = res.data.result.name;
              this.userInfo.birth = res.data.result.birth;
              this.userInfo.email = res.data.result.email;
              this.userInfo.phone = res.data.result.phone;
              this.userInfo.gender = res.data.result.gender;
              this.userInfo.gender_disclose = res.data.result.gender_disclose;
              this.$router.push({ name: "userinfoset" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (loginUg == "true") {
        let token = this.$cookie.get("access_token");
        let userID = this.$cookie.get("userID");

        this.$axios
          .get(this.API_URL + "/accounts/user", {
            params: { userID: userID, userPW: this.password },
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.status == 400) {
              alert(res.data.msg);
            } else if (res.data.status == 200) {
              this.userInfo.userID = res.data.result.userID;
              this.userInfo.nickname = res.data.result.nickname;
              this.userInfo.name = res.data.result.name;
              this.userInfo.birth = res.data.result.birth;
              this.userInfo.email = res.data.result.email;
              this.userInfo.phone = res.data.result.phone;
              this.userInfo.gender = res.data.result.gender;
              this.$router.push({ name: "userinfoset" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#userInfoCheck {
  width: 500px;
  min-height: 403px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 96px 68px;
  text-align: center;
  padding: 0 58px;
}
#userInfoCheck h2 {
  font: normal normal bold 28px/40px Noto Sans CJK KR;
  letter-spacing: -0.7px;
  color: #8ca0ef;
  margin: 40px 0;
}

#userInfoCheck p {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  margin-bottom: 40px;
}
.inputBox {
  text-align: left;
}
.inputBox h4 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  margin-bottom: 5px;
}
.inputBox input {
  outline: none;
  border: none;
  border-bottom: 1px solid #707070;
  width: 384px;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #191919;
}
.inputBox input::placeholder {
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: -0.4px;
  color: #dbdbdb;
}
.errMessage {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #cf1f1f;
}
.goNext {
  width: 180px;
  height: 40px;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  border-radius: 20px;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #ffffff;
  padding-top: 8px;
  cursor: pointer;
  margin: 35px auto;
}
</style>
