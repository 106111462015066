<template>
  <div id="write-pg">
    <div class="main-navbox">
      <h2>자유게시판</h2>
      <div class="main-navboxItem">
        <span @click="noticePage">공지사항</span>
        <span id="main-navboxItem_check" @click="freePage">자유게시판</span>
        <span @click="InquirePage">고객문의</span>
      </div>
      <div class="mycontentsBtn" @click="goMyContents">내 게시글 관리</div>
    </div>
    <div class="wrap">
      <input
        class="titleInput"
        type="text"
        v-model="title"
        placeholder="제목"
      />
      <div class="writerInfo">
        <span>작성자: &nbsp;&nbsp;{{ userData.nickname }}</span>
        <span>작성일: &nbsp;&nbsp;{{ todayText }}</span>
      </div>
      <div class="addImgBtn">
        <input
          type="file"
          id="input"
          @change="putFile"
          ref="files"
          style="display: none"
          accept="image/*"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g
            id="그룹_20775"
            data-name="그룹 20775"
            transform="translate(-531 -225.173)"
          >
            <g
              id="사각형_25717"
              data-name="사각형 25717"
              transform="translate(531 225.173)"
              fill="#fff"
              stroke="#707070"
              stroke-width="1.3"
            >
              <rect width="18" height="18" rx="1" stroke="none" />
              <rect
                x="0.65"
                y="0.65"
                width="16.7"
                height="16.7"
                rx="0.35"
                fill="none"
              />
            </g>
            <path
              id="패스_8270"
              data-name="패스 8270"
              d="M-10194.292,10467.357l3.325-5.793,4.934,4.858,4.287-6.917,3.118,4.859"
              transform="translate(10726.609 -10228.954)"
              fill="none"
              stroke="#707070"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.3"
            />
            <g
              id="타원_246"
              data-name="타원 246"
              transform="translate(537.79 228.303)"
              fill="#fff"
              stroke="#707070"
              stroke-width="1.3"
            >
              <circle cx="2.34" cy="2.34" r="2.34" stroke="none" />
              <circle cx="2.34" cy="2.34" r="1.69" fill="none" />
            </g>
          </g>
        </svg>
        <label for="input" style="cursor: pointer">사진첨부</label>
      </div>
      <div class="imgBox-wrap" v-if="imgBoxWrap == true">
        <div class="imgBox" v-for="(file, index) in files" :key="index">
          <img :src="file.preview" alt="" v-if="file.type == 'image'" />
          <!-- <video
            :src="file.preview"
            controls
            preload="none"
            v-else-if="file.type == 'video'"
            width="610px"
          ></video>
          <audio
            :src="file.preview"
            controls
            preload="none"
            v-else-if="file.type == 'audio'"
          ></audio> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            @click="delContentsBtn(index)"
          >
            <g
              id="그룹_20694"
              data-name="그룹 20694"
              transform="translate(-1545 -845)"
            >
              <g
                id="그룹_20288"
                data-name="그룹 20288"
                transform="translate(1353 640)"
              >
                <g
                  id="타원_155"
                  data-name="타원 155"
                  transform="translate(192 205)"
                  fill="#8ca0ef"
                  stroke="#8ca0ef"
                  stroke-width="1"
                >
                  <circle cx="15" cy="15" r="15" stroke="none" />
                  <circle cx="15" cy="15" r="14.5" fill="none" />
                </g>
              </g>
              <rect
                id="사각형_24934"
                data-name="사각형 24934"
                width="30"
                height="30"
                transform="translate(1545 845)"
                fill="none"
              />
              <line
                id="_선_81"
                x1="12"
                y2="12"
                transform="translate(1554 854)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="2"
              />
              <line
                id="_선_82"
                x1="12"
                y1="12"
                transform="translate(1554 854)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </div>
      <textarea v-model="board_contents" class="commentwriteBox"></textarea>
      <div class="btns">
        <div @click="backBtn">취소</div>
        <div @click="save">게시</div>
      </div>
    </div>
    <div class="firePostBox">
      <h2>인기 게시글</h2>
      <div class="firePost" v-for="item in popularPost" :key="item.id">
        <p @click="goFreeDetail(item.id)">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let today = new Date();
    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    let date = today.getDate();
    this.todayText = year + "." + month + "." + date;
  },
  computed: {
    userData() {
      return this.$store.state.navStore.navData;
    },
    popularPost() {
      return this.$store.state.community.popularPost;
    },
    API_URL() {
      return this.$store.state.API_URL;
    },
  },
  data() {
    return {
      imgBoxWrap: false,
      title: "",
      todayText: "",
      board_contents: "",
      files: [],
      filesPreview: [],
      uploadImageIndex: 0,
    };
  },
  methods: {
    goFreeDetail(postNum) {
      this.$store.commit("set_noticeDel");
      this.$store.commit("set_freeDetailCMDel");
      this.$router.push({
        path: "/community/freeDetail/" + postNum,
      });
    },
    noticePage() {
      this.$router.push({ path: "/community/mainpage/1" });
    },
    InquirePage() {
      this.$router.push({ path: "/community/Inquirepage/1" });
    },
    freePage() {
      this.$router.push({ path: "/community/freepage/1" });
    },
    goMyContents() {
      this.$router.push({ path: "/community/myCMPage/1" });
    },
    putFile() {
      this.imgBoxWrap = true;
      let file = this.$refs.files.files[0];

      if (file > 20 * 1024 * 10) {
        alert("사진 사이즈가 20mb를 넘습니다.");
      } else if (this.files.length > 4) {
        alert("사진은 최대 5개까지 올릴수 있습니다.");
      } else if (file.type.indexOf("image") >= 0) {
        this.files = [
          ...this.files,
          //이미지 업로드
          {
            file: file,
            type: "image",
            preview: URL.createObjectURL(file),
          },
        ];
      } else if (file.type.indexOf("video") >= 0) {
        this.files = [
          ...this.files,
          //이미지 업로드
          {
            file: file,
            type: "video",
            preview: URL.createObjectURL(file),
          },
        ];
      } else if (file.type.indexOf("audio") >= 0) {
        this.files = [
          ...this.files,
          //이미지 업로드
          {
            file: file,
            type: "audio",
            preview: URL.createObjectURL(file),
          },
        ];
      }
    },
    delContentsBtn(index) {
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.imgBoxWrap = false;
      }
    },
    backBtn() {
      this.$router.go(-1);
    },
    save() {
      if (this.title == "") {
        alert("제목을 작성해주세요.");
      } else if (this.board_contents == "") {
        alert("글을 작성해주세요.");
      } else {
        const form = new FormData();
        const loginUg = localStorage.getItem("loginUg");
        if (loginUg == "false") {
          const token = JSON.parse(sessionStorage.getItem("token"));
          form.append("userID", token.userID);
          form.append("title", this.title);
          form.append("board_contents", this.board_contents);
          for (let i = 0; i < this.files.length; i++) {
            form.append("images", this.files[i].file);
          }
          this.$axios
            // .post(this.API_URL + "/community/free-board", form, {
            .post(this.API_URL + "/community/announce-board", form, {
              headers: {
                Authorization: `Bearer ${token.access_token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.state.community.freeDetail.postID =
                  res.data.board_information.id;
                this.$store.commit("set_freeDetail", res.data);
                this.$router.push({ path: "/community/freepage/1" });
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (loginUg == "true") {
          const token = this.$cookie.get("access_token");
          const userID = this.$cookie.get("userID");
          form.append("userID", userID);
          form.append("title", this.title);
          form.append("board_contents", this.board_contents);
          // form.append("top_fix", "Y");

          for (let i = 0; i < this.files.length; i++) {
            form.append("images", this.files[i].file);
          }
          this.$axios
            .post(this.API_URL + "/community/free-board", form, {
              // .post(this.API_URL + "/community/announce-board", form, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$store.state.community.freeDetail.postID =
                  res.data.board_information.id;
                this.$store.commit("set_freeDetail", res.data);
                this.$router.push({ path: "/community/freepage/1" });
              } else {
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#write-pg {
  margin-bottom: 100px;
  width: 1200px;
  display: flex;
  margin: 0 auto;
}
.main-navbox {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.main-navboxItem {
  width: 180px;
  height: 156px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.main-navboxItem span {
  margin-bottom: 12px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  cursor: pointer;
}
.main-navboxItem span:last-child {
  margin-bottom: 0;
}
#main-navboxItem_check {
  color: #8ca0ef;
}
.main-navbox h2 {
  font: normal normal bold 24px/35px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #191919;
  padding: 40px 0;
  padding-left: 30px;
}
.mycontentsBtn {
  width: 180px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8ca0ef;
  border-radius: 20px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #8ca0ef;

  text-align: center;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
.wrap {
  width: 690px;
  min-height: 550px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-top: 116px;
  padding: 30px;
}
.titleInput {
  width: 630px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #191919;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
.titleInput::placeholder {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
}
.writerInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
}
.writerInfo span {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
}
.addImgBtn {
  width: 80px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addImgBtn label {
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #707070;
  margin-left: 6px;
}
.imgBox-wrap {
  width: 630px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  margin-bottom: 20px;
  padding: 10px;
  margin-top: 10px;
}
.imgBox {
  text-align: center;
  position: relative;
}

.imgBox img {
  max-width: 610px;
}
.imgBox svg {
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}
.commentwriteBox {
  width: 630px;
  height: 300px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  resize: none;
  outline: none;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #191919;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.btns div {
  width: 90px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 15px;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btns div:last-child {
  border: none;
  background: #8ca0ef 0% 0% no-repeat padding-box;
  color: #ffffff;
  margin-left: 20px;
}
.firePostBox {
  width: 282px;
  height: 346px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 116px;
  padding: 20px;
}
.firePostBox h2 {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.4px;
  color: #191919;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost {
  border-bottom: 0.5px solid #d2d2d2;
}
.firePost p {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.35px;
  color: #707070;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 0;
  cursor: pointer;
}
</style>
