const uploadData = {
  state() {
    return {
      files: [],
      feed_title: "",
      feed_range: "all",
      link_url: [],
      text: "",
      feed_field: "",
      feed_keyword: [],
      thumbnail: "",
    };
  },
  getters: {},
  mutations: {
    set_thumbnail(state, payload) {
      state.thumbnail = payload;
    },
    SET_UPLOADFILES(state, payload) {
      state.files.push({
        file: payload,
      });
    },
    set_uploadUrlText(state, payload) {
      state.link_url = payload.link_url;
      state.text = payload.text;
    },
    set_upload_title(state, payload) {
      state.feed_title = payload;
    },
    del_upload_title(state) {
      state.feed_title = "";
    },
    set_field(state, payload) {
      console.log(payload);
      state.feed_field = payload.feed_field;
      let keyword = payload.feed_keyword[0].split(",");
      state.feed_keyword = keyword;
    },
  },
  actions: {},
};

export default uploadData;
