<template>
  <v-app id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <footer-bar v-if="$route.name !== 'mobile'"></footer-bar>
  </v-app>
</template>

<script>
import footerBar from "./components/footerBar.vue";

export default {
  name: "appView",
  components: { footerBar },

  create() {},
  mounted() {
    window.addEventListener("beforeunload", this.unLoadEvent);
    // let loginUg = localStorage.getItem("loginUg");
    // if (loginUg == "false") {
    //   let token = JSON.parse(sessionStorage.getItem("token"));
    //   this.$cookie.set("access_token", token.access_token, 1);
    //   this.$cookie.set("userID", token.userID, 1);
    //   let cookie_token = this.$cookie.get("access_token");
    //   let cookie_userID = this.$cookie.get("access_token");
    //   sessionStorage.setItem(
    //     "token",
    //     JSON.stringify({
    //       userID: cookie_userID,
    //       access_token: cookie_token,
    //     })
    //   );
    // }
  },
  beforeUnmount() {
    // let loginUg = localStorage.getItem("loginUg");
    // if (loginUg == "false") {
    //   this.$cookie.delete("userID");
    //   this.$cookie.delete("access_token");
    // }
  },
  data() {
    return {};
  },
  computed: {
    feed_scroll() {
      return this.$store.state.scroll;
    },
  },
  methods: {
    unLoadEvent: function (event) {
      // let loginUg = localStorage.getItem("loginUg");
      // if (loginUg == "false") {
      //   this.$cookie.delete("userID");
      //   this.$cookie.delete("access_token");
      // }
    },
  },
  watch: {
    $route(to, from) {
      document.getElementsByTagName("body")[0].style.overflow = "scroll";

      // if (to.name == "userfeedall") {
      //   console.log(to);
      //   console.log(this.feed_scroll);
      // }
    },
  },
};
</script>

<style>
body {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-family: "Noto Sans KR", sans-serif;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div {
  box-sizing: border-box;
}

input {
  outline: none;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
