const community = {
  state() {
    return {
      isLogin: false,
      popularPost: [],

      freeDetail: {
        postID: "",
        board_information: [],
        comment_information: [],
        comment_last_page: "",
        current_page: "",
        comment: {
          last_page: [],
          last_pageData: [],
          nowPage: "",
          pageCount: "",
        },
      },
      noticeDetail: {
        postID: "",
        board_information: [],
      },
      inquireDetail: {
        postID: "",
        answer_information: [],
        question_information: [],
      },
      myCMDetail: {
        postID: "",
        board_information: [],
        comment_information: [],
        comments_last_page: "",
        comment: {
          last_page: [],
          last_pageData: [],
          nowPage: "",
          pageCount: "",
        },
      },
      noticeNTData: [],
      noticeCMData: [],
      freeNTData: [],
      freeCMData: [],
      freeCMPaging: {
        last_page: [],
        last_pageData: [],
        pageCount: 0,
        nowPage: "",
        lastBackPageNum: "",
        searchValue: "0",
        searchText: "",
      },
      noticeCMPaging: {
        last_page: [],
        last_pageData: [],
        pageCount: 0,
        nowPage: "",
        lastBackPageNum: "",
        searchValue: "0",
        searchText: "",
      },
      inquirePageData: [],
      myCMData: [],
      myNTData: [],
    };
  },
  getters: {},
  mutations: {
    set_noticeCM(state, payload) {
      state.noticeCMData = payload;
    },
    set_noticeNT(state, payload) {
      state.noticeNTData = payload;
    },
    set_freeCM(state, payload) {
      state.freeCMData = payload;
    },
    set_freeNT(state, payload) {
      state.freeNTData = payload;
    },
    set_inquirePageData(state, payload) {
      state.inquirePageData = payload;
    },
    set_noticeDetail(state, payload) {
      state.noticeDetail.board_information = payload.board_information;
      state.noticeDetail.postID = payload.board_information.id;
    },
    set_freeDetail(state, payload) {
      state.freeDetail.comment.last_page = [];
      state.freeDetail.comment.last_pageData = [];
      state.freeDetail.board_information = [];
      state.freeDetail.comment_information = [];
      state.freeDetail.board_information = payload.board_information;
      state.freeDetail.postID = payload.board_information.id;
      // state.freeDetail.current_page = payload.current_page;
      state.freeDetail.comment_information = payload.comment_information;
      state.freeDetail.comment_last_page = payload.comment_last_page;
      state.freeDetail.comment.nowPage = payload.comment_last_page;
      let i;
      for (i = 0; i < state.freeDetail.comment_last_page; i++) {
        state.freeDetail.comment.last_page.push(i);
      }

      for (i = 0; i < state.freeDetail.comment.last_page.length; i += 10) {
        state.freeDetail.comment.last_pageData.push(
          state.freeDetail.comment.last_page.slice(i, i + 10)
        );
      }
      let count = state.freeDetail.comment.last_pageData.length - 1;
      state.freeDetail.comment.pageCount = count;
    },

    set_freeDetailComment(state, payload) {
      state.freeDetail.comment.last_page = [];
      state.freeDetail.comment.last_pageData = [];
      state.freeDetail.comment_information = payload.comments_info;
      state.freeDetail.comments_last_page = payload.comments_last_page;
      state.freeDetail.comment.nowPage = payload.current_page;
      let i;
      for (i = 0; i < state.freeDetail.comment_last_page; i++) {
        state.freeDetail.comment.last_page.push(i);
      }

      for (i = 0; i < state.freeDetail.comment.last_page.length; i += 10) {
        state.freeDetail.comment.last_pageData.push(
          state.freeDetail.comment.last_page.slice(i, i + 10)
        );
      }
    },
    set_myCMDetail(state, payload) {
      state.myCMDetail.comment.last_page = [];
      state.myCMDetail.comment.last_pageData = [];
      state.myCMDetail.board_information = [];
      state.myCMDetail.comment_information = [];
      state.myCMDetail.board_information = payload.board_information;
      state.myCMDetail.postID = payload.board_information.id;
      state.myCMDetail.comment_information = payload.comment_information;
      state.myCMDetail.comment_last_page = payload.comment_last_page;
      state.myCMDetail.comment.nowPage = payload.comment_last_page;
      let i;
      for (i = 0; i < state.myCMDetail.comment_last_page; i++) {
        state.myCMDetail.comment.last_page.push(i);
      }

      for (i = 0; i < state.myCMDetail.comment.last_page.length; i += 10) {
        state.myCMDetail.comment.last_pageData.push(
          state.myCMDetail.comment.last_page.slice(i, i + 10)
        );
      }
      let count = state.myCMDetail.comment.last_pageData.length - 1;
      state.myCMDetail.comment.pageCount = count;
    },
    set_myCMDetailComment(state, payload) {
      state.myCMDetail.comment.last_page = [];
      state.myCMDetail.comment.last_pageData = [];
      state.myCMDetail.comment_information = payload.comments_info;
      state.myCMDetail.comments_last_page = payload.comments_last_page;
      state.myCMDetail.comment.nowPage = payload.current_page;
      let i;
      for (i = 0; i < state.myCMDetail.comment_last_page; i++) {
        state.myCMDetail.comment.last_page.push(i);
      }

      for (i = 0; i < state.myCMDetail.comment.last_page.length; i += 10) {
        state.myCMDetail.comment.last_pageData.push(
          state.myCMDetail.comment.last_page.slice(i, i + 10)
        );
      }
    },
    set_freeDetailCMDel(state) {
      state.freeCMPaging.last_page = [];
      state.freeCMPaging.last_pageData = [];
      state.freeCMPaging.pageCount = [];
      state.freeCMPaging.searchText = [];
    },
    set_noticeDetailCMDel(state) {
      state.noticeCMPaging.last_page = [];
      state.noticeCMPaging.last_pageData = [];
      state.noticeCMPaging.pageCount = [];
      state.noticeCMPaging.searchText = [];
    },
    set_inquireDetail(state, payload) {
      state.inquireDetail.answer_information = payload.answer_information;
      state.inquireDetail.question_information = payload.question_information;
      state.inquireDetail.postID = payload.question_information.id;
    },
    set_myCM(state, payload) {
      state.myCMData = payload;
    },
    set_myNT(state, payload) {
      state.myNTData = payload;
    },

    set_freeDel(state) {
      state.freeDetail.postID = "";
      state.freeDetail.comment.last_page = [];
      state.freeDetail.comment.last_pageData = [];
      state.freeDetail.board_information = [];
      state.freeDetail.comment_information = [];
    },
    set_myCMDel(state) {
      state.myCMDetail.postID = "";
      state.myCMDetail.comment.last_page = [];
      state.myCMDetail.comment.last_pageData = [];
      state.myCMDetail.board_information = [];
      state.myCMDetail.comment_information = [];
    },
    set_noticeDel(state) {
      state.noticeDetail.postID = "";
      state.noticeDetail.board_information = [];
    },

    set_inquirePageDataDel(state) {
      state.inquireDetail.postID = "";
      state.inquireDetail.answer_information = [];
      state.inquireDetail.question_information = [];
    },
    set_popularPost(state, payload) {
      state.popularPost = payload;
    },
  },
  actions: {},
};

export default community;
